import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { doctorDetails } from '../../store/doctor/actions';
import { debounce } from 'lodash';
const JitsiMeet = () => {
  const { appointmentid } = useParams();
  const [roomDetails, setRoomDetails] = useState(null);
  const [jwtToken, setJwtToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [jitsiApi, setJitsiApi] = useState(null); // Track the Jitsi API instance
  const storedUserRole = localStorage.getItem("userRole");
  const { doctorDetailsResults } = useSelector((state) => state.DoctorReducer);
  const doctorId = localStorage.getItem('doctorId');
  const baseURL = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const meetingid = localStorage.getItem('meetingId');
  const meetingStartTime = localStorage.getItem('startTime');
  console.log("meetingStartTime",meetingStartTime)
  const navigate = useNavigate()
  useEffect(() => {
    if (doctorId) {
      dispatch(doctorDetails(doctorId));
    }
  }, [doctorId, dispatch]);
  useEffect(() => {
    const currentPagePath = window.location.pathname;
    localStorage.setItem("currentPageUrl", currentPagePath);

    console.log("Current Page URL", currentPagePath);

    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      navigate('/login');
    }
  }, [navigate]);
  useEffect(() => {
    // Save the origin of the current page (videoconference)
    localStorage.setItem("origin", "videoconference");
  
    // The rest of your code logic remains the same...
  }, []);
  useEffect(() => {
    const fetchRoomDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseURL}/appointment/${appointmentid}/`);
        if (response.data && response.data.room_name) {
          setRoomDetails(response.data.room_name);
        } else {
          console.error('Room name not found in response');
        }
      } catch (error) {
        console.error("Error fetching room details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRoomDetails();
  }, [appointmentid]);
// const appointment = localStorage.getItem("patientData")
const appointment = JSON.parse(localStorage.getItem("patientData")) || {};

  const username = localStorage.getItem("firstName");
  const userEmail = localStorage.getItem("userEmail");
  const Image = localStorage.getItem("Image");
  const RoomName = `vpaas-magic-cookie-ae16a235bbbc4d6ead0e00b8a0ba947a/${roomDetails}`;
  const updateEndTime = debounce(async () => {
    const currentTime = new Date();
    const formattedEndTime = formatTime(currentTime);
  
    if (storedUserRole === 'doctor') {
      const payload = {
        start_time: meetingStartTime,
        end_time: formattedEndTime,
      };
  
      try {
        await axios.put(
          `${baseURL}/meetings/${appointmentid}/${meetingid}/update-end-time/`,
          payload
        );
        console.log('Meeting end time updated');
      } catch (error) {
        console.error('Error updating end time:', error);
      }
    }
  }, 500); // Adjust the debounce delay (e.g., 500ms)
  useEffect(() => {
    const generateToken = async () => {
      if (!roomDetails || !doctorDetailsResults || jwtToken) return;

      setLoading(true);
      try {
        const userDetails = {
          user_name: username,
          user_email: userEmail,
          avatar_url: Image,
          is_moderator: storedUserRole === "doctor",
          room_name: roomDetails,
        };

        const accessToken = localStorage.getItem('accessToken');

        const response = await axios.post(`${baseURL}/video/generate-token/`, userDetails, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const cleanedToken = response.data.token.slice(2, -1);
        setJwtToken(cleanedToken);
      } catch (error) {
        console.error("Error generating token:", error);
      } finally {
        setLoading(false);
      }
    };

    generateToken();
  }, [roomDetails, doctorDetailsResults, jwtToken]);
  const formatTime = (isoString) => {
    const date = new Date(isoString);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };
  useEffect(() => {
    if (roomDetails && jwtToken && !jitsiApi) {
      const script = document.createElement('script');
      script.src = 'https://8x8.vc/vpaas-magic-cookie-ae16a235bbbc4d6ead0e00b8a0ba947a/external_api.js';
      script.async = true;
      document.body.appendChild(script);
  
      script.onload = () => {
        const api = new window.JitsiMeetExternalAPI('8x8.vc', {
          roomName: RoomName,
          parentNode: document.querySelector('#jaas-container'),
          jwt: jwtToken,
        });
  
        api.on('videoConferenceLeft', () => {
          console.log('User left the conference');
          updateEndTime();
          localStorage.removeItem("currentPageUrl");
          // const formattedStartTime = formatTime(meetingStartTime); // Format start time
          // const currentTime = new Date();
          // const formattedEndTime = formatTime(currentTime);
          // // Check if the user role is 'doctor'
          // if (storedUserRole === 'doctor') {
          //   const payload = {
          //     start_time: meetingStartTime, // Formatted start time
          //     end_time: formattedEndTime,
          //   };
            
            
          //   axios.put(
          //     `${baseURL}/meetings/${appointmentid}/${meetingid}/update-end-time/`,
          //     payload
          //   )
          //   .then(response => {
          //     console.log('Meeting end time updated:', response.data);
          //   })
          //   .catch(error => {
          //     console.error('Error updating end time:', error);
          //   });
          // }
  
          // Navigate based on user role
          if (storedUserRole === 'doctor') {
            const userId = localStorage.getItem('userId');
            navigate('/patient-details', { state: { appointment } });

            // navigate('/patient-details');
            // state ; {appointment}
          } else {
            const userId = localStorage.getItem('userId');
            navigate(`/patient-profile/${userId}`);
          }
        });
  
        setJitsiApi(api); // Save the API instance to prevent duplicates
      };
  
      return () => {
        if (jitsiApi) {
          jitsiApi.dispose(); // Cleanup the Jitsi instance
        }
        document.body.removeChild(script); // Remove the script from DOM
      };
    }
  }, [roomDetails, jwtToken, jitsiApi, RoomName, storedUserRole, appointmentid, meetingid, navigate]);
  
  // useEffect(() => {
  //   if (roomDetails && jwtToken && !jitsiApi) {
  //     const script = document.createElement('script');
  //     script.src = 'https://8x8.vc/vpaas-magic-cookie-ae16a235bbbc4d6ead0e00b8a0ba947a/external_api.js';
  //     script.async = true;
  //     document.body.appendChild(script);

  //     script.onload = () => {
  //       const api = new window.JitsiMeetExternalAPI('8x8.vc', {
  //         roomName: RoomName,
  //         parentNode: document.querySelector('#jaas-container'),
  //         jwt: jwtToken,
  //       });
  //       api.on('videoConferenceLeft', () => {
  //         console.log('User left the conference');

  //         const formattedStartTime = formatTime(meetingStartTime); // Format start_time
          
  //         const currentTime = new Date();
  //         const formattedEndTime = formatTime(currentTime);

  //         // Prepare the payload
         
  //         const payload = {
  //           start_time: formattedStartTime, // Formatted start time
  //           end_time: formattedEndTime,
  //         };

  //         // Call the API to update the end time
  //         axios.put(
  //           `${baseURL}/meetings/${appointmentid}/${meetingid}/update-end-time/`,
  //           payload
  //         )
  //         .then(response => {
  //           console.log('Meeting end time updated:', response.data);
  //         })
  //         .catch(error => {
  //           console.error('Error updating end time:', error);
  //         });

  //         // Navigate based on user role
  //         if (storedUserRole === 'doctor') {
  //           const userId = localStorage.getItem('userId');
  //           navigate('/patient-details');
  //         } else {
  //           const userId = localStorage.getItem('userId');
  //           navigate(`/patient-profile/${userId}`);
  //         }
  //       });
        
  //       // api.on('videoConferenceLeft', () => {
  //       //   console.log('User left the conference');
  //       //   if (storedUserRole === 'doctor') {
  //       //     navigate('/patient-details');
  //       //   } else {
  //       //     const userId = localStorage.getItem('userId');
  //       //     navigate(`/patient-profile/${userId}`);
  //       //   }
  //       // });
  //       setJitsiApi(api); // Save the API instance to prevent duplicates
  //     };
    

  //     return () => {
  //       if (jitsiApi) {
  //         jitsiApi.dispose(); // Cleanup the Jitsi instance
  //       }
  //       document.body.removeChild(script); // Remove the script from DOM
  //     };
  //   }
  // }, [roomDetails, jwtToken, jitsiApi, RoomName]);

  return (
    <div id="jaas-container" style={{ height: '100vh' }}>
      {loading && <div>Loading...</div>}
    </div>
  );
};

export default JitsiMeet;
