import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import {
  Tooltip,
  Table,
  OverlayTrigger,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import dayjs from "dayjs";
import {
  FaFilePdf,
  FaEdit,
  FaFileExcel,
  FaPlus,
  FaMapMarkerAlt,
} from "react-icons/fa";
import { IoMdMail, IoIosCall } from "react-icons/io";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import AdminFooter from "../../components/dashboard/adminFooter";
import ScrollTop from "../../components/scrollTop";
import SkeletonLoader from ".././dashboard/SkeltonLoader";
import { getUserData, ShowError, ShowSucess } from "../../_helper/helper";
import Paging from "../../components/CommonComponents/Paging";
import Header from "../../components/CommonComponents/Header";
import { Button, Modal } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { post, get } from "../../_helper/api_helper";
import dr1 from "../../assets/images/doctors/01.jpg";
import { loadingPanel } from "../../components/CommonComponents/CommonFunction";
import { Typography } from "@mui/material";
export default function Index() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [labTestsList, setLabTestsList] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [labCenterOptions, setLabCenterOptions] = useState([]);

  const [showPDFModal, setShowPDFModal] = useState(false);
  const [dataItem, setDataItem] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [testNameList, setTestNameList] = useState([]);
  const ref = useRef();
  const { id } = useParams();

  const [filterStatus, setFilterStatus] = useState(query.get("status"));
  const currentDate = new Date().toISOString().split("T")[0];
  const [filterDate, setFilterDate] = useState(
    query.get("date") || currentDate
  );

  const showBackButton = location.key !== "default";
  const [searchQuery, setSearchQuery] = useState("");

  const fetchLabTestList = async () => {
    const token = localStorage.getItem("accessToken");

    try {
      setLoading(true);
      let url;

      url = `${process.env.REACT_APP_API_URL}/lab-tests`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setLabTestsList(data);
      setTotalItems(data.length);

      setDisplayedData(
        data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
      );
    } catch (error) {
      setError(error.message);
      console.error("Error fetching appointment data:", error);
    } finally {
      setLoading(false);
    }
  };
  const fileInputRef = useRef(null);
  const pdfFileInputRef = useRef(null);

  const handleFileChange = async (event, type) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    if (type === "excel") {
      formData.append("file", file);
    } else if (type === "pdf") {
      formData.append("pdf_file", file);
    } else {
      return;
    }

    try {
      const response = await post(
        `${
          type === "excel"
            ? "/upload-lab-tests/"
            : type === "pdf"
            ? "/upload-lab-test-pdf/"
            : null
        }`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("File uploaded successfully:", response.data);
      ShowSucess("Upload Successfully!");
      fetchLabTestList();
    } catch (error) {
      console.error("Error uploading file:", error);
      ShowError("Upload failed. Please try again.");
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click(); // Trigger file input
  };
  const handlePdfButtonClick = () => {
    pdfFileInputRef.current.click(); // Trigger file input
  };
  useEffect(() => {
    fetchLabTestList();
  }, [id]);

  useEffect(() => {
    let filteredData = labTestsList;
    if (searchQuery) {
      filteredData = filteredData.filter(
        (item) =>
          item?.patient_name
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          item?.health_record_number
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
      );
    }

    setTotalItems(filteredData.length);
    setDisplayedData(
      filteredData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      )
    );
  }, [
    currentPage,
    labTestsList,
    itemsPerPage,
    filterStatus,
    filterDate,
    searchQuery,
  ]);
  const getTestNameList = async () => {
    try {
      setLoading(true);
      let url;
      url = `${process.env.REACT_APP_API_URL}/test_name/display`;
      const response = await get(url);
      setTestNameList(response);
    } catch (error) {
      setTestNameList([]);
      console.error("Error fetching appointment data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getTestNameList();
  }, []);
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB"); // "en-GB" gives the format "dd/mm/yyyy"
  };

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const dateFromLocation = params.get("date");

    if (dateFromLocation) {
      setFilterDate(dateFromLocation);
    } else {
      // const today = new Date().toISOString().split("T")[0];
      // setFilterDate(today);
      return;
    }
  }, [location.search]); // Add location.search to dependencies

  const handleDownload = async (e) => {
    e.preventDefault();
    setLoading(true);
    const input = ref.current;

    input.style.maxHeight = "none";
    input.style.overflowY = "visible";

    html2canvas(input, {
      useCORS: true,
      allowTaint: false,
      scale: 2, // Increase the scale for higher quality and better spacing
      windowWidth: document.body.scrollWidth, // Ensures the full width is captured
      windowHeight: document.body.scrollHeight,
      onclone: (clonedDoc) => {
        clonedDoc.querySelectorAll("*").forEach((el) => {
          el.style.margin = "5px !important"; // Force margins
          el.style.padding = "10px !important";
          el.style.lineHeight = "1.6 !important";
        });
      },
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save("TestReport.pdf");

      input.style.maxHeight = "800px";
      input.style.overflowY = "auto";
      setLoading(false);
    });
  };

  const getLabCenterOptions = async () => {
    try {
      setLoading(true);
      let url;
      url = `${process.env.REACT_APP_API_URL}/lab/display/`;
      const response = await get(url);
      setLabCenterOptions([response]);
    } catch (error) {
      setLabCenterOptions([]);
      console.error("Error fetching labCentersOptions data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getLabCenterOptions();
  }, []);
  const {
    watermark,
    name,
    country,
    city,
    address,
    logo,
    email,
    contact_number,
  } = labCenterOptions[0] || {};
  const {
    patient_name,
    patient_email,
    patient_contact,
    test_date,
    test_details,
    age,
    gender,
    sample_collected_on,
    sample_received_on,
    sample_generated_on,
  } = dataItem || {};
  const P = ({ children, className }) => {
    return (
      <p className={className} style={{ marginBottom: "1px" }}>
        {children}
      </p>
    );
  };
  const TH = ({ children, className }) => {
    return (
      <th className={className} style={{ padding: "10px 0px" }}>
        {children}
      </th>
    );
  };
  const TD = ({ children, className }) => {
    return (
      <td className={className} style={{ padding: "3px 10px" }}>
        {children}
      </td>
    );
  };
  return (
    <>
      <Navbar
        navDark={true}
        manuClass="navigation-menu nav-left"
        containerClass="container"
      />
      <section className="bg-dashboard">
        <div className="container-fluid">
          <div className="row">
            <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
            <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <Header
                title="Lab Test Report" // Replace with the title you want
                onBackClick={() => navigate(-1)}
                showBackButton={showBackButton}
              />
              <Button
                variant="primary"
                onClick={() =>
                  navigate("/lab-test-report/new", {
                    state: {
                      testNameList: testNameList,
                      labCenterOptions: labCenterOptions,
                    },
                  })
                }
                style={{ marginTop: "20px" }}
              >
                <FaPlus /> Add Test Report
              </Button>
              <input
                type="file"
                accept=".xls,.xlsx"
                ref={fileInputRef}
                onChange={(e) => handleFileChange(e, "excel")} // Auto-upload on file selection
                style={{ display: "none" }}
              />
              <Button
                variant="success"
                onClick={handleButtonClick}
                style={{ marginLeft: "8px", marginTop: "20px" }}
              >
                <FaFileExcel /> Add Report by Excel
              </Button>
              <input
                type="file"
                accept=".pdf" // Accept only PDF files
                ref={pdfFileInputRef}
                onChange={(e) => handleFileChange(e, "pdf")} // Auto-upload on file selection
                style={{ display: "none" }}
              />
              <Button
                variant="info"
                onClick={handlePdfButtonClick}
                style={{ marginLeft: "8px", marginTop: "20px" }}
              >
                <FaFilePdf /> Add Report by PDF
              </Button>

              {/* <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`tooltip-excel-${item?.id}`}>
                                        Upload Excel
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      variant="success"
                                      onClick={handleButtonClick}
                                      className="btn-icon btn-pills btn-soft-success my-1 mx-2"
                                    >
                                      <FaFileExcel />
                                    </Button>
                                  </OverlayTrigger> */}
              <div className="row mt-3">
                <div className="col-md-12 d-flex flex-column">
                  {/* First row: Date filter and Search input */}
                  <div className="d-flex flex-wrap align-items-center">
                    <input
                      type="text"
                      className="form-control mb-2 mb-md-0"
                      placeholder="Search by Name or Health record no."
                      value={searchQuery}
                      onChange={handleSearchChange}
                      style={{ flex: 1, maxWidth: "100%" }} // Make input take full width on smaller screens
                    />
                  </div>

                  {/* Second row: Buttons */}

                  <hr style={{ margin: "0", borderColor: "#ccc" }} />
                </div>
              </div>

              <div className="row">
                <div className="col-12 mt-4">
                  {loading ? (
                    <SkeletonLoader />
                  ) : error ? (
                    <div className="text-center text-danger">{error}</div>
                  ) : (
                    <div className="table-responsive bg-white shadow rounded">
                      <table className="table mb-0 table-center">
                        <thead>
                          <tr>
                            <th
                              className="border-bottom p-3"
                              style={{ minWidth: "50px" }}
                            >
                              #
                            </th>
                            <th className="border-bottom p-3">Action</th>

                            <th
                              className="border-bottom p-3"
                              style={{ minWidth: "180px" }}
                            >
                              Patient Name
                            </th>
                            <th className="border-bottom p-3">Patient Email</th>
                            {/* <th className="border-bottom p-3">Gender</th> */}
                            <th className="border-bottom p-3">
                              Health Record No.
                            </th>
                            <th
                              className="border-bottom p-3"
                              style={{ minWidth: "150px" }}
                            >
                              Test Date
                            </th>
                            {/* <th className="border-bottom p-3">Time</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {displayedData.length > 0 ? (
                            displayedData.map((item, index) => (
                              <tr key={item?.id}>
                                <th className="p-3">
                                  {(currentPage - 1) * itemsPerPage + index + 1}
                                </th>
                                <td>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`tooltip-edit-${item?.id}`}>
                                        Edit Details
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      variant="primary"
                                      className="btn-icon btn-pills btn-soft-primary my-1 mx-2"
                                      onClick={() =>
                                        navigate(
                                          `/lab-test-report/${item?.id}`,
                                          {
                                            state: {
                                              item: item,
                                              testNameList: testNameList,
                                              labCenterOptions:
                                                labCenterOptions,
                                            },
                                          }
                                        )
                                      }
                                    >
                                      <FaEdit />
                                    </Button>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`tooltip-pdf-${item?.id}`}>
                                        Download Pdf
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      variant="info"
                                      className="btn-icon btn-pills btn-soft-info my-1 mx-2"
                                      onClick={() => {
                                        setDataItem(item);
                                        setShowPDFModal(true);
                                      }}
                                    >
                                      <FaFilePdf />
                                    </Button>
                                  </OverlayTrigger>

                                  {/* <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip
                                        id={`tooltip-download-${item?.id}`}
                                      >
                                        Download Pdf
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      variant="success"
                                      onClick={(e) => handleDownload(e)}
                                      className="btn-icon btn-pills btn-soft-success my-1 mx-2"
                                    >
                                      <FaFileExcel />
                                    </Button>
                                  </OverlayTrigger> */}
                                </td>

                                <td
                                  className="p-3"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {item?.patient_name}
                                </td>

                                <td className="p-3" title={item?.patient_email}>
                                  {item?.patient_email}
                                </td>
                                <td
                                  className="p-3"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {item?.health_record_number}
                                  {/* {formatDate(item?.appointment_date)}{" "}
                                  {formatTime(item?.appointment_time)} */}
                                </td>

                                <td className="p-3">
                                  {formatDate(item?.test_date)}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="10" className="text-center">
                                No Record found !
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
              <div className="row text-center">
                <div className="col-12 mt-4">
                  <div className="d-md-flex align-items-center text-center justify-content-between">
                    <span className="text-muted me-3">
                      Showing {(currentPage - 1) * itemsPerPage + 1} -{" "}
                      {Math.min(currentPage * itemsPerPage, totalItems)} out of{" "}
                      {totalItems}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Paging
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </section>
      <AdminFooter />

      <Modal
        show={showPDFModal}
        onHide={() => setShowPDFModal(false)}
        size="lg"
        centered
        dialogClassName="modal-fullwidth"
      >
        {loading && loadingPanel()}
        <Modal.Header closeButton>
          <Modal.Title className="h4">
            Tests Report Details
            {testNameList && testNameList.length > 0 && (
              <a
                href="#"
                className="text-primary fw-bold"
                style={{
                  textDecoration: "none",
                  cursor: "pointer",
                  marginLeft: "20px",
                  fontSize: "22px",
                }}
                onClick={handleDownload}
              >
                Download PDF
              </a>
            )}
          </Modal.Title>
        </Modal.Header>
        <div
          ref={ref}
          style={{
            maxHeight: "850px",
            overflowY: "auto",
          }}
        >
          <Modal.Body
            style={{
              position: "relative",
              backgroundColor: "#f7f7f7",
            }}
          >
            {/* Watermark */}

            <div style={{ position: "relative", zIndex: 2 }}>
              {/* Header Section */}
              <div
                style={{
                  backgroundColor: "#228B22",
                  color: "white",
                  padding: "10px 20px",
                  borderRadius: "5px 5px 0 0",
                }}
              >
                <Row xs={12} md={12}>
                  <Col md={9}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "10px",
                      }}
                    >
                      {" "}
                      <Typography
                        style={{
                          fontFamily: "cursive",
                          fontSize: "30px",
                          marginRight: "10px",
                        }}
                      >
                        {name}
                      </Typography>
                      <img
                        src={`${process.env.REACT_APP_API_URL}${logo}`} // Replace with actual logo path
                        className="rounded-circle shadow-md avatar avatar-md-md"
                        alt="logo"
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <P>
                      <FaMapMarkerAlt />: {address},{city}
                    </P>
                    <P>
                      <IoMdMail />: {email}
                    </P>
                    <P>
                      <IoIosCall />: {contact_number}
                    </P>
                  </Col>
                </Row>
              </div>

              {/* Patient Information */}
              <Container className="mt-3">
                <Row>
                  <Col md={7}>
                    {patient_name && (
                      <div className="detail-row">
                        <span className="label">Patient Name</span>:{" "}
                        <span className="value">{patient_name}</span>
                      </div>
                    )}
                    {age && gender && (
                      <div className="detail-row">
                        <span className="label">Age/Gender</span>:{" "}
                        <span className="value">
                          {age}Y / {gender}
                        </span>
                      </div>
                    )}
                    {patient_contact && (
                      <div className="detail-row">
                        <span className="label">Contact No</span>:{" "}
                        <span className="value">{patient_contact}</span>
                      </div>
                    )}
                    {patient_email && (
                      <div className="detail-row">
                        <span className="label">Email</span>:{" "}
                        <span className="value">{patient_email}</span>
                      </div>
                    )}
                  </Col>

                  <Col md={5}>
                    {test_date && (
                      <div className="detail-row">
                        <span className="label">Test Date</span>:{" "}
                        <span className="value">
                          {dayjs(test_date).format("MM/DD/YYYY")}
                        </span>
                      </div>
                    )}
                    {sample_collected_on && (
                      <div className="detail-row">
                        <span className="label">Sample Collected On</span>:{" "}
                        <span className="value">
                          {dayjs(sample_collected_on).format("MM/DD/YYYY")}
                        </span>
                      </div>
                    )}
                    {sample_received_on && (
                      <div className="detail-row">
                        <span className="label">Sample Received On</span>:{" "}
                        <span className="value">
                          {dayjs(sample_received_on).format("MM/DD/YYYY")}
                        </span>
                      </div>
                    )}
                    {sample_generated_on && (
                      <div className="detail-row">
                        <span className="label">Sample Generated On</span>:{" "}
                        <span className="value">
                          {dayjs(sample_generated_on).format("MM/DD/YYYY")}
                        </span>
                      </div>
                    )}
                  </Col>
                </Row>
              </Container>

              <hr />
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    top: "60%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1,
                    opacity: 0.1,
                    pointerEvents: "none",
                    width: "30%",
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_API_URL}${watermark}`} // Replace with the actual watermark image
                    alt="Watermark"
                    style={{ width: "100%", height: "50%" }}
                  />
                </div>
                {/* Table Section */}
                <table style={{ width: "100%", marginLeft: "10px" }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          fontSize: "20px",
                          width: "30%",
                          padding: "8px",
                        }}
                      >
                        Test Name
                      </th>
                      <th
                        style={{
                          fontSize: "20px",
                          width: "20%",
                          padding: "8px",
                        }}
                      >
                        Value
                      </th>
                      <th
                        style={{
                          fontSize: "20px",
                          width: "25%",
                          padding: "8px",
                        }}
                      >
                        Unit
                      </th>
                      <th
                        style={{
                          fontSize: "20px",
                          width: "25%",
                          padding: "8px",
                        }}
                      >
                        Bio. Ref Interval
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {testNameList &&
                      testNameList.map((d) => {
                        const matchingDetail = dataItem?.test_details?.find(
                          (item) => Number(item?.test_name) === d?.id
                        );
                        const parameters = testNameList?.find(
                          (d) => d?.id === Number(matchingDetail?.test_name)
                        )?.parameters;

                        return matchingDetail ? (
                          <React.Fragment key={d?.id}>
                            <TH>
                              <TD>{d?.test_name}</TD>
                            </TH>
                            {parameters &&
                              parameters?.length > 0 &&
                              parameters?.map((param) => (
                                <tr>
                                  <TD>{param?.parameter_name}</TD>
                                  <TD>{param?.value}</TD>
                                  <TD>{param?.units}</TD>
                                  <TD>{param?.normal_range}</TD>
                                </tr>
                              ))}
                          </React.Fragment>
                        ) : null;
                      })}
                  </tbody>
                </table>

                {/* Interpretation Section */}
                <hr />

                <Container>
                  <h5>REMARKS:</h5>
                  {test_details &&
                    test_details?.length > 0 &&
                    test_details?.map(
                      (d, index) =>
                        d?.remarks && (
                          <p>
                            <b>{index + 1} .</b>
                            {d?.remarks}
                          </p>
                        )
                    )}
                </Container>
              </div>

              {/* Footer */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Container>
              <Row>
                <Col md={12} className="text-end">
                  <P>Dr. Sakshi Agarwal</P>
                  <P>MBBS, MD, Healthians Labs</P>
                </Col>
              </Row>
            </Container>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}
