// import React, { useEffect, useState } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import Modal from "react-bootstrap/Modal";
// import { Button, Form, Row, Col } from "react-bootstrap";
// import axios from "axios"; // Import axios
// import { getUserData } from "../../CommonComponents/CommonFunction";
// import Navbar from "../../navbar";
// import Sidebar from "../../sidebar";
// import Header from "../../CommonComponents/Header";
// import ScrollTop from "../../scrollTop";
// import AdminFooter from "../adminFooter";
// import { ShowError, ShowSucess } from "../../../_helper/helper";
// import { FaEdit } from "react-icons/fa";

// export default function HealthProvider() {
//   const { role, assigned_doctor_id, doctor_id } = getUserData();
//   const doctorId = role === "staff" ? assigned_doctor_id : doctor_id;
//   const token = localStorage.getItem("accessToken");
//   const [loading, setLoading] = useState(false);
//   const [healthProviders, setHealthProviders] = useState([]); // Set default to empty array
//   const [show, setShow] = useState(false); // Modal visibility
//   const [isUpdating, setIsUpdating] = useState(false); // To differentiate between create and update
//   const [formData, setFormData] = useState({
//     health_provider: "",
//     name: "",
//     address: "",
//     city: "",
//     state: "",
//     postal_code: "",
//     country: "",
//     contact_number: "",
//     email: "",
//   });
//   const [error, setError] = useState(null); // Error state
//   const location = useLocation();
//   const navigate = useNavigate();

//   const showBackButton = location.key !== "default";

//   useEffect(() => {
//     const fetchHealthProviders = async () => {
//       try {
//         setLoading(true);
//         const response = await axios.get(
//           `${process.env.REACT_APP_API_URL}/lab/display/`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//         const providers = Array.isArray(response.data)
//           ? response.data
//           : [response.data]; // Ensure it's always an array
//         console.log("Fetched Providers:", providers); // Log the data for debugging
//         setHealthProviders(providers); // Update the state with the providers
//         setLoading(false);
//       } catch (error) {
//         setLoading(false);
//         setError("There was an error! Please try again.");
//         console.error(error);
//       }
//     };
//     fetchHealthProviders();
//   }, [token]); // Add `token` as a dependency to trigger when it changes

//   // Handle form input change
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };
//   const handleContactNumberChange = (e) => {
//     const { value } = e.target;
//     // Allow only numbers and limit to 10 digits
//     if (/^\d{0,10}$/.test(value)) {
//       setFormData((prevData) => ({
//         ...prevData,
//         contact_number: value,
//       }));
//     }
//   };
//   // Handle form submission
//   const handleSubmit = (e) => {
//     e.preventDefault();

//     if (Object.values(formData).some((field) => field === "")) {
//       ShowError("All fields are required!");
//       return;
//     }

//     setLoading(true);

//     const payload = {
//       health_provider: formData.health_provider,
//       name: formData.name,
//       address: formData.address,
//       city: formData.city,
//       state: formData.state,
//       postal_code: formData.postal_code,
//       country: formData.country,
//       contact_number: formData.contact_number,
//       email: formData.email,
//     };

//     const apiUrl = isUpdating
//       ? `${process.env.REACT_APP_API_URL}/lab/update/`
//       : `${process.env.REACT_APP_API_URL}/lab/register/`;

//     const method = isUpdating ? "put" : "post";

//     axios[method](apiUrl, payload, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//     })
//       .then((response) => {
//         setLoading(false);
//         ShowSucess(
//           isUpdating ? "Health Provider Updated!" : "Health Provider Added!"
//         );
//         setShow(false); // Close the modal
//         setFormData({
//           health_provider: "",
//           name: "",
//           address: "",
//           city: "",
//           state: "",
//           postal_code: "",
//           country: "",
//           contact_number: "",
//           email: "",
//         });
//       })
//       .catch((error) => {
//         setLoading(false);
//         ShowError("There was an error! Please try again.");
//         console.error(error);
//       });
//   };

//   // Handle modal show for creating new health provider
//   const handleModalShow = () => {
//     setShow(true);
//     setIsUpdating(false); // Set to false for "Add" action
//     setFormData({
//       health_provider: "",
//       name: "",
//       address: "",
//       city: "",
//       state: "",
//       postal_code: "",
//       country: "",
//       contact_number: "",
//       email: "",
//     });
//   };

//   // Handle modal show for editing a health provider
//   const handleEditProvider = (provider) => {
//     setFormData({
//       health_provider: provider.health_provider,
//       name: provider.name,
//       address: provider.address,
//       city: provider.city,
//       state: provider.state,
//       postal_code: provider.postal_code,
//       country: provider.country,
//       contact_number: provider.contact_number,
//       email: provider.email,
//     });
//     setIsUpdating(true); // Set to true for "Update" action
//     setShow(true); // Show the modal
//   };

//   // Close modal
//   const handleModalClose = () => {
//     setShow(false);
//   };

//   return (
//     <>
//       <Navbar
//         navDark={true}
//         manuClass="navigation-menu nav-left"
//         containerClass="container"
//       />
//       <section className="bg-dashboard">
//         <div className="container-fluid">
//           <div className="row">
//             <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
//             <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
//               <Header
//                 title="Health Provider"
//                 onBackClick={() => navigate(-1)}
//                 showBackButton={showBackButton}
//               />
//               <Button
//                 variant="primary"
//                 onClick={handleModalShow}
//                 style={{ marginTop: "20px" }}
//               >
//                 Add Health Provider
//               </Button>
//               <div className="mt-4">
//                 <h5>Health Providers List</h5>
//                 <table className="table">
//                   <thead>
//                     <tr>
//                       <th>Name</th>
//                       <th>City</th>
//                       <th>Contact</th>
//                       <th>Email</th>
//                       <th>Actions</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {healthProviders.length > 0 ? (
//                       healthProviders.map((provider) => (
//                         <tr key={provider.id}>
//                           <td>{provider.name}</td>
//                           <td>{provider.city}</td>
//                           <td>{provider.contact_number}</td>
//                           <td>{provider.email}</td>
//                           <td>
//                             <Button
//                               variant="primary"
//                               onClick={() => handleEditProvider(provider)}
//                               className="btn-icon btn-pills btn-soft-primary my-1"
//                             >
//                               <FaEdit /> {/* Use FaEdit here */}
//                             </Button>

//                           </td>
//                         </tr>
//                       ))
//                     ) : (
//                       <tr>
//                         <td colSpan="4">No health providers found.</td>
//                       </tr>
//                     )}
//                   </tbody>
//                 </table>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* Modal for Health Provider Form */}
//       <Modal
//         show={show}
//         onHide={handleModalClose}
//         size="lg"
//         backdrop="static"
//         keyboard={false}
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>
//             {isUpdating ? "Edit Health Provider" : "Add Health Provider"}
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form onSubmit={handleSubmit}>
//             <Form.Group controlId="health_provider">
//               <Form.Label>Health Provider</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="health_provider"
//                 value={formData.health_provider}
//                 onChange={handleInputChange}
//                 required
//               />
//             </Form.Group>

//             <Row>
//               <Col sm={6}>
//                 <Form.Group controlId="name">
//                   <Form.Label>Name</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="name"
//                     value={formData.name}
//                     onChange={handleInputChange}
//                     required
//                   />
//                 </Form.Group>
//               </Col>

//               <Col sm={6}>
//                 <Form.Group controlId="address">
//                   <Form.Label>Address</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="address"
//                     value={formData.address}
//                     onChange={handleInputChange}
//                     required
//                   />
//                 </Form.Group>
//               </Col>
//             </Row>

//             <Row>
//               <Col sm={6}>
//                 <Form.Group controlId="city">
//                   <Form.Label>City</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="city"
//                     value={formData.city}
//                     onChange={handleInputChange}
//                     required
//                   />
//                 </Form.Group>
//               </Col>

//               <Col sm={6}>
//                 <Form.Group controlId="state">
//                   <Form.Label>State</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="state"
//                     value={formData.state}
//                     onChange={handleInputChange}
//                     required
//                   />
//                 </Form.Group>
//               </Col>
//             </Row>

//             <Row>
//               <Col sm={6}>
//                 <Form.Group controlId="postal_code">
//                   <Form.Label>Postal Code</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="postal_code"
//                     value={formData.postal_code}
//                     onChange={handleInputChange}
//                     required
//                   />
//                 </Form.Group>
//               </Col>

//               <Col sm={6}>
//                 <Form.Group controlId="country">
//                   <Form.Label>Country</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="country"
//                     value={formData.country}
//                     onChange={handleInputChange}
//                     required
//                   />
//                 </Form.Group>
//               </Col>
//             </Row>

//             <Row>
//               <Col sm={6}>
//                 <Form.Group controlId="contact_number">
//                   <Form.Label>Contact Number</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="contact_number"
//                     value={formData.contact_number}
//                     onChange={handleContactNumberChange}
//                     required
//                   />
//                 </Form.Group>
//               </Col>

//               <Col sm={6}>
//                 <Form.Group controlId="email">
//                   <Form.Label>Email</Form.Label>
//                   <Form.Control
//                     type="email"
//                     name="email"
//                     value={formData.email}
//                     onChange={handleInputChange}
//                     required
//                   />
//                 </Form.Group>
//               </Col>
//             </Row>
//             <Button variant="primary" type="submit" disabled={loading}>
//               {loading
//                 ? "Saving..."
//                 : isUpdating
//                 ? "Update Health Provider"
//                 : "Add Health Provider"}
//             </Button>
//           </Form>
//         </Modal.Body>
//       </Modal>

//       <ScrollTop />
//       <AdminFooter />
//     </>
//   );
// }

import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Button, Form, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios"; // Import axios
import { getUserData } from "../../CommonComponents/CommonFunction";
import Navbar from "../../navbar";
import Sidebar from "../../sidebar";
import Header from "../../CommonComponents/Header";
import ScrollTop from "../../scrollTop";
import AdminFooter from "../adminFooter";
import { ShowError, ShowSucess } from "../../../_helper/helper";
import { FaEdit } from "react-icons/fa";

export default function HealthProvider() {
  const { role, assigned_doctor_id, doctor_id } = getUserData();
  const doctorId = role === "staff" ? assigned_doctor_id : doctor_id;
  const token = localStorage.getItem("accessToken");
  const [loading, setLoading] = useState(false);
  const [healthProviders, setHealthProviders] = useState([]); // Set default to empty array
  console.log("healthProviders", healthProviders);
  const apiUrl = process.env.REACT_APP_API_URL;

  const [show, setShow] = useState(false); // Modal visibility
  const [isUpdating, setIsUpdating] = useState(false); // To differentiate between create and update
  const [formData, setFormData] = useState({
    health_provider: "",
    name: "",
    address: "",
    city: "",
    state: "",
    postal_code: "",
    country: "",
    contact_number: "",
    email: "",
    logo: null, // Added state for logo image
    watermark: null, // Added state for watermark image
  });
  const [error, setError] = useState(null); // Error state
  const [logoPreview, setLogoPreview] = useState(null); // Preview for logo
  const [watermarkPreview, setWatermarkPreview] = useState(null); // Preview for watermark
  const location = useLocation();
  const navigate = useNavigate();

  const showBackButton = location.key !== "default";
  const fetchHealthProviders = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/lab/display/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const providers = Array.isArray(response.data)
        ? response.data
        : [response.data]; // Ensure it's always an array
      console.log("Fetched Providers:", providers); // Log the data for debugging
      setHealthProviders(providers); // Update the state with the providers
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError("There was an error! Please try again.");
      console.error(error);
    }
  };
  useEffect(() => {

    fetchHealthProviders();
  }, [token]); // Add `token` as a dependency to trigger when it changes

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (e, field) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        [field]: file,
      }));
      const reader = new FileReader();
      reader.onloadend = () => {
        if (field === "logo") setLogoPreview(reader.result);
        if (field === "watermark") setWatermarkPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleContactNumberChange = (e) => {
    const { value } = e.target;
    // Allow only numbers and limit to 10 digits
    if (/^\d{0,10}$/.test(value)) {
      setFormData((prevData) => ({
        ...prevData,
        contact_number: value,
      }));
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (Object.values(formData).some((field) => field === "" && field !== null)) {
      ShowError("All fields are required!");
      return;
    }
  
    setLoading(true);
  
    // Prepare form data with FormData API to handle file uploads
    const payload = new FormData();
    payload.append("health_provider", formData.health_provider);
    payload.append("name", formData.name);
    payload.append("address", formData.address);
    payload.append("city", formData.city);
    payload.append("state", formData.state);
    payload.append("postal_code", formData.postal_code);
    payload.append("country", formData.country);
    payload.append("contact_number", formData.contact_number);
    payload.append("email", formData.email);
  
    // Append logo and watermark to payload only if they are updated or exist
    if (formData.logo && typeof formData.logo !== "string") {
      payload.append("logo", formData.logo);
    } else if (formData.logo) {
      payload.append("existing_logo", formData.logo); // Send existing logo path
    }
  
    if (formData.watermark && typeof formData.watermark !== "string") {
      payload.append("watermark", formData.watermark);
    } else if (formData.watermark) {
      payload.append("existing_watermark", formData.watermark); // Send existing watermark path
    }
  
    const apiUrl = isUpdating
      ? `${process.env.REACT_APP_API_URL}/lab/update/`
      : `${process.env.REACT_APP_API_URL}/lab/register/`;
  
    const method = isUpdating ? "put" : "post";
  
    // Submit the data using FormData
    axios[method](apiUrl, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setLoading(false);
        ShowSucess(
          isUpdating ? "Health Provider Updated!" : "Health Provider Added!"
        );
        setShow(false); // Close the modal
        fetchHealthProviders()
        setFormData({
          health_provider: "",
          name: "",
          address: "",
          city: "",
          state: "",
          postal_code: "",
          country: "",
          contact_number: "",
          email: "",
          logo: null,
          watermark: null,
        });
        setLogoPreview(null);
        setWatermarkPreview(null);
      })
      .catch((error) => {
        setLoading(false);
        ShowError("There was an error! Please try again.");
        console.error(error);
      });
  };

  const handleModalShow = () => {
    setShow(true);
    setIsUpdating(false); // Set to false for "Add" action
    setFormData({
      health_provider: "",
      name: "",
      address: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
      contact_number: "",
      email: "",
      logo: null,
      watermark: null,
    });
    setLogoPreview(null);
    setWatermarkPreview(null);
  };

  // Handle modal show for editing a health provider
  const handleEditProvider = (provider) => {
    setFormData({
      health_provider: provider.health_provider,
      name: provider.name,
      address: provider.address,
      city: provider.city,
      state: provider.state,
      postal_code: provider.postal_code,
      country: provider.country,
      contact_number: provider.contact_number,
      email: provider.email,
      logo: provider.logo || null,
      watermark: provider.watermark || null,
    });
    setIsUpdating(true); // Set to true for "Update" action
    setShow(true); // Show the modal

    // If there's an existing logo or watermark, set previews
    if (provider.logo) {
      setLogoPreview(`${process.env.REACT_APP_API_URL}${provider.logo}`);
    } else {
      setLogoPreview(null); // No preview if no logo
    }

    if (provider.watermark) {
      setWatermarkPreview(
        `${process.env.REACT_APP_API_URL}${provider.watermark}`
      );
    } else {
      setWatermarkPreview(null); // No preview if no watermark
    }
  };
  const handleModalClose = () => {
    setShow(false);
  };

  return (
    <>
      <Navbar
        navDark={true}
        manuClass="navigation-menu nav-left"
        containerClass="container"
      />
      <section className="bg-dashboard">
        <div className="container-fluid">
          <div className="row">
            <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
            <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <Header
                title="Health Provider"
                onBackClick={() => navigate(-1)}
                showBackButton={showBackButton}
              />
              <Button
                variant="primary"
                onClick={handleModalShow}
                style={{ marginTop: "20px" }}
              >
                Add Health Provider
              </Button>
              <div className="mt-4">
                <h5>Health Providers List</h5>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>City</th>
                        <th>Contact</th>
                        <th>Email</th>
                        <th>Logo</th>
                        <th>Watermark</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {healthProviders.length > 0 ? (
                        healthProviders.map((provider) => (
                          <tr key={provider.id}>
                            <td>{provider.name}</td>
                            <td>{provider.city}</td>
                            <td>{provider.contact_number}</td>
                            <td>{provider.email}</td>
                            <td>
                              {provider.logo && (
                                <img
                                  src={`${process.env.REACT_APP_API_URL}${provider.logo}`}
                                  alt="Logo"
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "50%",
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {provider.watermark && (
                                <img
                                  src={`${process.env.REACT_APP_API_URL}${provider.watermark}`}
                                  alt="Watermark"
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "50%",
                                  }}
                                />
                              )}
                            </td>
                            <td>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-edit-${provider.id}`}>
                                    Edit Details
                                  </Tooltip>
                                }
                              >
                              <Button
                                variant="primary"
                                onClick={() => handleEditProvider(provider)}
                                className="btn-icon btn-pills btn-soft-primary my-1"
                              >
                                <FaEdit />
                              </Button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7">No health providers found.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Modal for Health Provider Form */}
      <Modal
        show={show}
        onHide={handleModalClose}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isUpdating ? "Edit Health Provider" : "Add Health Provider"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="health_provider">
              <Form.Label>Health Provider</Form.Label>
              <Form.Control
                type="text"
                name="health_provider"
                value={formData.health_provider}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            {/* Both image fields in one line */}
            <Form.Group controlId="logo" className="d-flex align-items-center">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  flexWrap: "wrap",
                }}
              >
                <div>
                  <Form.Label>Logo (Optional)</Form.Label>
                  <Form.Control
                    type="file"
                    name="logo"
                    onChange={(e) => handleImageChange(e, "logo")}
                  />
                  {logoPreview && (
                    <img
                      src={logoPreview}
                      alt="Logo Preview"
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        marginTop: "10px",
                      }}
                    />
                  )}
                </div>
                <div>
                  <Form.Label>Watermark (Optional)</Form.Label>
                  <Form.Control
                    type="file"
                    name="watermark"
                    onChange={(e) => handleImageChange(e, "watermark")}
                  />
                  {watermarkPreview && (
                    <img
                      src={watermarkPreview}
                      alt="Watermark Preview"
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        marginTop: "10px",
                      }}
                    />
                  )}
                </div>
              </div>
            </Form.Group>

            {/* Rest of the form */}
            <Row>
              <Col sm={6}>
                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId="address">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Form.Group controlId="city">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId="state">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Additional fields */}
            <Row>
              <Col sm={6}>
                <Form.Group controlId="postal_code">
                  <Form.Label>Postal Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="postal_code"
                    value={formData.postal_code}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>

              <Col sm={6}>
                <Form.Group controlId="country">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    type="text"
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Form.Group controlId="contact_number">
                  <Form.Label>Contact Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="contact_number"
                    value={formData.contact_number}
                    onChange={handleContactNumberChange}
                    required
                  />
                </Form.Group>
              </Col>

              <Col sm={6}>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button variant="primary" type="submit" disabled={loading}>
              {loading
                ? "Saving..."
                : isUpdating
                ? "Update Health Provider"
                : "Add Health Provider"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <ScrollTop />
      <AdminFooter />
    </>
  );
}
