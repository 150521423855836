// import React, { useState, useEffect } from "react";
// import Chart from "react-apexcharts";
// import axios from "axios";
// import SkeletonLoader from "../../pages/dashboard/SkeltonLoader";
// import { getDoctorId } from "../../_helper/helper";
// import { get } from "../../api_helper";
// import { getUserData } from "../CommonComponents/CommonFunction";
// export default function Charts() {
//   const [appointmentData, setAppointmentData] = useState([]);
//   console.log("appointmentData",appointmentData)
//   const [loading, setLoading] = useState(true);
//   // const {accessToken, role, assigned_doctor_id, doctor_id } = getUserData();
//   const token = localStorage.getItem("accessToken"); 
//   const doctorId = getDoctorId()
// console.log("doctoriiidd",token)
//   const fetchData = async () => {
//     try {
//       const response = await get(
//         `${process.env.REACT_APP_API_URL}/doctor/appointments/${doctorId}`
//       );
//       console.log("Fetched Data:", response); 
//       setAppointmentData(response ||[]);
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching appointment data:", error);
//       setLoading(false);
//     }
//   };
//   useEffect(() => {
//     if (doctorId !== null && token) {
//       fetchData();
//     }
//   }, [doctorId, token]);

//   const getStatusData = () => {
//     const statusCounts = appointmentData.reduce((acc, appointment) => {
//       acc[appointment.status] = (acc[appointment.status] || 0) + 1;
//       return acc;
//     }, {});

//     const totalAppointments = appointmentData.length;
//     const statuses = [
//       "confirmed",
//       "rescheduled",
//       "rejected",
//       "pending",
//       "completed",
//       "cancelled",
//     ];
//     const series = statuses.map((status) => statusCounts[status] || 0);
//     const percentages =
//       totalAppointments > 0
//         ? series.map((count) => ((count / totalAppointments) * 100).toFixed(1))
//         : statuses.map(() => "0.0");
//     return { statuses, series, percentages };
//   };

//   const getDateStatusData = (status) => {
//     const filteredData = appointmentData.filter(
//       (appointment) => appointment.status === status
//     );
//     const dataByDate = filteredData.reduce((acc, appointment) => {
//       const date = appointment.appointment_date;
//       acc[date] = (acc[date] || 0) + 1;
//       return acc;
//     }, {});

//     const dates = Object.keys(dataByDate);
//     const values = Object.values(dataByDate);

//     return { dates, values };
//   };

//   const createPieChartOptions = () => {
//     const statusData = getStatusData();
//     return {
//       series: statusData.series,
//       chart: { type: "pie", height: 350 },
//       labels: statusData.statuses.map(
//         (status, index) =>
//           `${status.charAt(0).toUpperCase() + status.slice(1)} (${
//             statusData.percentages[index]
//           }%)`
//       ),
//       colors: [
//         "#28a745",
//         "#f7b731",
//         "#dc3545",
//         "#007bff",
//         "#6f42c1",
//         "#6c757d",
//       ], // Green, Orange, Red, Blue, Purple, Gray
//       legend: { position: "bottom" },
//       responsive: [
//         {
//           breakpoint: 480,
//           options: {
//             chart: { width: "100%" },
//             legend: { position: "bottom" },
//           },
//         },
//       ],
//     };
//   };

//   const createAreaChartOptions = (data, color, status, percentage) => ({
//     series: [{ data: data.values }],
//     chart: {
//       type: "area",
//       height: 90,
//       width: "100%",
//       sparkline: { enabled: true },
//     },
//     stroke: { curve: "smooth", width: 3 },
//     colors: [color],
//     fill: {
//       type: "gradient",
//       gradient: {
//         shadeIntensity: 1,
//         inverseColors: false,
//         opacityFrom: 0.45,
//         opacityTo: 0.05,
//         stops: [0, 100],
//       },
//     },
//     tooltip: {
//       fixed: { enabled: false },
//       x: { show: false },
//       y: { title: { formatter: (seriesName) => "" } },
//       marker: { show: false },
//     },
//     xaxis: {
//       categories: data.dates,
//       labels: {
//         show: true,
//         rotate: -45,
//       },
//     },
//   });

//   const statuses = getStatusData().statuses;
//   const percentages = getStatusData().percentages;
//   const colors = [
//     "#28a745",
//     "#f7b731",
//     "#dc3545",
//     "#007bff",
//     "#6f42c1",
//     "#6c757d",
//   ]; // Green, Orange, Red, Blue, Purple, Gray

//   return (
//     <div className="container">
//       {loading ? (
//         <SkeletonLoader />
//       ) : (
//         <>
//           <div className="row">
//             {/* First Row: Area Charts */}
//             {statuses.slice(0, 3).map((status, index) => {
//               const data = getDateStatusData(status);
//               return (
//                 <div className="col-xl-4 col-lg-6 mt-4" key={status}>
//                   <div className="card shadow border-0 p-4">
//                     <div className="d-flex justify-content-between mb-3">
//                       <h6 className="align-items-center mb-0">
//                         {status.charAt(0).toUpperCase() + status.slice(1)} (
//                         {percentages[index]}%)
//                       </h6>
//                       <p className="mb-0 text-muted">
//                         {data.values.reduce((a, b) => a + b, 0)}+ Week
//                       </p>
//                     </div>
//                     <Chart
//                       options={createAreaChartOptions(
//                         data,
//                         colors[index],
//                         status,
//                         percentages[index]
//                       )}
//                       series={[{ data: data.values }]}
//                       type="area"
//                       width="100%"
//                       height={90}
//                     />
//                   </div>
//                 </div>
//               );
//             })}
//           </div>

//           <div className="row">
//             {/* Second Row: Remaining Area Charts and Pie Chart */}
//             {statuses.slice(3).map((status, index) => {
//               const data = getDateStatusData(status);
//               return (
//                 <div className="col-xl-4 col-lg-6 mt-4" key={status}>
//                   <div className="card shadow border-0 p-4">
//                     <div className="d-flex justify-content-between mb-3">
//                       <h6 className="align-items-center mb-0">
//                         {status.charAt(0).toUpperCase() + status.slice(1)} (
//                         {percentages[statuses.indexOf(status)]}%)
//                       </h6>
//                       <p className="mb-0 text-muted">
//                         {data.values.reduce((a, b) => a + b, 0)}+ Week
//                       </p>
//                     </div>
//                     <Chart
//                       options={createAreaChartOptions(
//                         data,
//                         colors[statuses.indexOf(status)],
//                         status,
//                         percentages[index]
//                       )}
//                       series={[{ data: data.values }]}
//                       type="area"
//                       width="100%"
//                       height={90}
//                     />
//                   </div>
//                 </div>
//               );
//             })}

//             <div className="col-lg-12 mt-4">
//               <div className="card shadow border-0 p-4">
//                 <h6 className="mb-4">Appointment Status Distribution</h6>
//                 <Chart
//                   options={createPieChartOptions()}
//                   series={getStatusData().series}
//                   type="pie"
//                   width="100%"
//                   height={350}
//                 />
//               </div>
//             </div>
//           </div>
//         </>
//       )}
//     </div>
//   );
// }



// import React, { useState, useEffect } from "react";
// import Chart from "react-apexcharts";
// import axios from "axios";
// import SkeletonLoader from "../../pages/dashboard/SkeltonLoader";
// import { getDoctorId } from "../../_helper/helper";
// import { get } from "../../api_helper";
// import { getUserData } from "../CommonComponents/CommonFunction";
// import moment from "moment"; // Import moment.js to help with date filtering

// export default function Charts() {
//   const [appointmentData, setAppointmentData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [filter, setFilter] = useState({ month: "", year: "", week: "" }); // For month, year, and week
//   const token = localStorage.getItem("accessToken");
//   const doctorId = getDoctorId();

//   // Function to fetch appointment data based on filter
//   const fetchData = async () => {
//     try {
//       const response = await get(
//         `${process.env.REACT_APP_API_URL}/doctor/appointments/${doctorId}`
//       );
//       console.log("Fetched Data:", response);
//       setAppointmentData(response || []);
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching appointment data:", error);
//       setLoading(false);
//     }
//   };

//   // UseEffect to fetch data when component mounts
//   useEffect(() => {
//     if (doctorId !== null && token) {
//       fetchData();
//     }
//   }, [doctorId, token]);

//   // Filter function based on selected month, year, or week
//   const filterAppointments = () => {
//     return appointmentData.filter((appointment) => {
//       const appointmentDate = moment(appointment.appointment_date);
//       let isValid = true;

//       if (filter.year && appointmentDate.year() !== parseInt(filter.year)) {
//         isValid = false;
//       }

//       if (filter.month && appointmentDate.month() + 1 !== parseInt(filter.month)) {
//         isValid = false;
//       }

//       if (filter.week && appointmentDate.isoWeek() !== parseInt(filter.week)) {
//         isValid = false;
//       }

//       return isValid;
//     });
//   };
//   const createAreaChartOptions= (data, color, status, percentage) => {
//     return {
//       chart: {
//         type: "area",
//         height: 90,
//         sparkline: {
//           enabled: true,
//         },
//       },
//       colors: [color],
//       fill: {
//         opacity: 1,
//       },
//       stroke: {
//         width: 2,
//         curve: "smooth",
//       },
//       tooltip: {
//         shared: true,
//         y: {
//           formatter: function (val) {
//             return `${val} appointments`;
//           },
//         },
//       },
//       xaxis: {
//         categories: data.dates,
//         title: {
//           text: "Week",
//         },
//       },
//       yaxis: {
//         title: {
//           text: "Number of Appointments",
//         },
//       },
//       title: {
//         text: `${status} (${percentage}%)`,
//         align: "left",
//         style: {
//           fontSize: "14px",
//           fontWeight: "bold",
//         },
//       },
//     };
//   };
//   // Function to calculate status data based on filtered appointments
//   const getStatusData = () => {
//     const filteredAppointments = filterAppointments();
//     const statusCounts = filteredAppointments.reduce((acc, appointment) => {
//       acc[appointment.status] = (acc[appointment.status] || 0) + 1;
//       return acc;
//     }, {});

//     const totalAppointments = filteredAppointments.length;
//     // In the Charts component:

// // Ensure to add the createAreaChartOptions function above or inside the component


//     const statuses = [
//       "confirmed",
//       "rescheduled",
//       "rejected",
//       "pending",
//       "completed",
//       "cancelled",
//     ];
//     const series = statuses.map((status) => statusCounts[status] || 0);
//     const percentages =
//       totalAppointments > 0
//         ? series.map((count) => ((count / totalAppointments) * 100).toFixed(1))
//         : statuses.map(() => "0.0");
//     return { statuses, series, percentages };
//   };

//   // Function to generate data for specific status over dates
//   const getDateStatusData = (status) => {
//     const filteredData = filterAppointments().filter(
//       (appointment) => appointment.status === status
//     );
//     const dataByDate = filteredData.reduce((acc, appointment) => {
//       const date = appointment.appointment_date;
//       acc[date] = (acc[date] || 0) + 1;
//       return acc;
//     }, {});

//     const dates = Object.keys(dataByDate);
//     const values = Object.values(dataByDate);

//     return { dates, values };
//   };

//   // Function to create pie chart options
//   const createPieChartOptions = () => {
//     const statusData = getStatusData();
//     return {
//       series: statusData.series,
//       chart: { type: "pie", height: 350 },
//       labels: statusData.statuses.map(
//         (status, index) =>
//           `${status.charAt(0).toUpperCase() + status.slice(1)} (${
//             statusData.percentages[index]
//           }%)`
//       ),
//       colors: [
//         "#28a745",
//         "#f7b731",
//         "#dc3545",
//         "#007bff",
//         "#6f42c1",
//         "#6c757d",
//       ], // Green, Orange, Red, Blue, Purple, Gray
//       legend: { position: "bottom" },
//       responsive: [
//         {
//           breakpoint: 480,
//           options: {
//             chart: { width: "100%" },
//             legend: { position: "bottom" },
//           },
//         },
//       ],
//     };
//   };

//   // Handle filter change
//   const handleFilterChange = (event) => {
//     const { name, value } = event.target;
//     setFilter((prevFilter) => ({
//       ...prevFilter,
//       [name]: value,
//     }));
//   };

//   const statuses = getStatusData().statuses;
//   const percentages = getStatusData().percentages;
//   const colors = [
//     "#28a745",
//     "#f7b731",
//     "#dc3545",
//     "#007bff",
//     "#6f42c1",
//     "#6c757d",
//   ]; // Green, Orange, Red, Blue, Purple, Gray

//   return (
//     <div className="container">
//       {loading ? (
//         <SkeletonLoader />
//       ) : (
//         <>
//           <div className="row">
//             {/* Date Filter Section */}
//             <div className="col-12 mt-4">
//               <div className="card shadow border-0 p-4">
//                 <h6 className="mb-4">Filter Appointments</h6>
//                 <div className="d-flex justify-content-between">
//                   <select
//                     name="month"
//                     value={filter.month}
//                     onChange={handleFilterChange}
//                     className="form-control"
//                   >
//                     <option value="">Select Month</option>
//                     {[...Array(12).keys()].map((month) => (
//                       <option key={month} value={month + 1}>
//                         {moment().month(month).format("MMMM")}
//                       </option>
//                     ))}
//                   </select>
//                   <select
//                     name="year"
//                     value={filter.year}
//                     onChange={handleFilterChange}
//                     className="form-control"
//                   >
//                     <option value="">Select Year</option>
//                     {["2023", "2024", "2025"].map((year) => (
//                       <option key={year} value={year}>
//                         {year}
//                       </option>
//                     ))}
//                   </select>
//                   <select
//                     name="week"
//                     value={filter.week}
//                     onChange={handleFilterChange}
//                     className="form-control"
//                   >
//                     <option value="">Select Week</option>
//                     {[...Array(52).keys()].map((week) => (
//                       <option key={week + 1} value={week + 1}>
//                         Week {week + 1}
//                       </option>
//                     ))}
//                   </select>
//                 </div>
//               </div>
//             </div>
//           </div>

//           {/* Render Charts based on filtered data */}
//           <div className="row">
//             {/* First Row: Area Charts */}
//             {statuses.slice(0, 3).map((status, index) => {
//               const data = getDateStatusData(status);
//               return (
//                 <div className="col-xl-4 col-lg-6 mt-4" key={status}>
//                   <div className="card shadow border-0 p-4">
//                     <div className="d-flex justify-content-between mb-3">
//                       <h6 className="align-items-center mb-0">
//                         {status.charAt(0).toUpperCase() + status.slice(1)} (
//                         {percentages[index]}%)
//                       </h6>
//                       <p className="mb-0 text-muted">
//                         {data.values.reduce((a, b) => a + b, 0)}+ Week
//                       </p>
//                     </div>
//                     <Chart
//                       options={createAreaChartOptions(
//                         data,
//                         colors[index],
//                         status,
//                         percentages[index]
//                       )}
//                       series={[{ data: data.values }]}
//                       type="area"
//                       width="100%"
//                       height={90}
//                     />
//                   </div>
//                 </div>
//               );
//             })}
//           </div>

//           <div className="row">
//             {/* Second Row: Remaining Area Charts and Pie Chart */}
//             {statuses.slice(3).map((status, index) => {
//               const data = getDateStatusData(status);
//               return (
//                 <div className="col-xl-4 col-lg-6 mt-4" key={status}>
//                   <div className="card shadow border-0 p-4">
//                     <div className="d-flex justify-content-between mb-3">
//                       <h6 className="align-items-center mb-0">
//                         {status.charAt(0).toUpperCase() + status.slice(1)} (
//                         {percentages[statuses.indexOf(status)]}%)
//                       </h6>
//                       <p className="mb-0 text-muted">
//                         {data.values.reduce((a, b) => a + b, 0)}+ Week
//                       </p>
//                     </div>
//                     <Chart
//                       options={createAreaChartOptions(
//                         data,
//                         colors[statuses.indexOf(status)],
//                         status,
//                         percentages[index]
//                       )}
//                       series={[{ data: data.values }]}
//                       type="area"
//                       width="100%"
//                       height={90}
//                     />
//                   </div>
//                 </div>
//               );
//             })}

//             <div className="col-lg-12 mt-4">
//               <div className="card shadow border-0 p-4">
//                 <h6 className="mb-4">Appointment Status Distribution</h6>
//                 <Chart
//                   options={createPieChartOptions()}
//                   series={getStatusData().series}
//                   type="pie"
//                   width="100%"
//                   height={350}
//                 />
//               </div>
//             </div>
//           </div>
//         </>
//       )}
//     </div>
//   );
// }



// import React, { useState, useEffect } from "react";
// import Chart from "react-apexcharts";
// import axios from "axios";
// import SkeletonLoader from "../../pages/dashboard/SkeltonLoader";
// import { getDoctorId } from "../../_helper/helper";
// import { get } from "../../api_helper";
// import moment from "moment"; // Import moment.js to help with date filtering

// export default function Charts() {
//   const [appointmentData, setAppointmentData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [filter, setFilter] = useState({ month: "", year: "", week: "", quarter: "" });
//   const [years, setYears] = useState([]);
//   const token = localStorage.getItem("accessToken");
//   const doctorId = getDoctorId();

//   // Function to fetch appointment data based on filter
//   const fetchData = async () => {
//     try {
//       const response = await get(
//         `${process.env.REACT_APP_API_URL}/doctor/appointments/${doctorId}`
//       );
//       console.log("Fetched Data:", response);
//       setAppointmentData(response || []);
//       setLoading(false);
//       extractYears(response);  // Extract years from fetched data
//     } catch (error) {
//       console.error("Error fetching appointment data:", error);
//       setLoading(false);
//     }
//   };

//   // Function to extract unique years from the fetched appointment data
//   const extractYears = (data) => {
//     const uniqueYears = Array.from(new Set(data.map(appointment => moment(appointment.appointment_date).year())));
//     setYears(uniqueYears.sort((a, b) => b - a));  // Sort years descending
//   };

//   // UseEffect to fetch data when component mounts
//   useEffect(() => {
//     if (doctorId !== null && token) {
//       fetchData();
//     }
//   }, [doctorId, token]);

//   // Filter function based on selected month, year, week, or quarter
//   const filterAppointments = () => {
//     return appointmentData.filter((appointment) => {
//       const appointmentDate = moment(appointment.appointment_date);
//       let isValid = true;

//       if (filter.year && appointmentDate.year() !== parseInt(filter.year)) {
//         isValid = false;
//       }

//       if (filter.month && appointmentDate.month() + 1 !== parseInt(filter.month)) {
//         isValid = false;
//       }

//       if (filter.week && appointmentDate.isoWeek() !== parseInt(filter.week)) {
//         isValid = false;
//       }

//       if (filter.quarter) {
//         const quarterStartMonth = (parseInt(filter.quarter) - 1) * 3;
//         const quarterEndMonth = quarterStartMonth + 2;
//         if (
//           appointmentDate.month() < quarterStartMonth ||
//           appointmentDate.month() > quarterEndMonth
//         ) {
//           isValid = false;
//         }
//       }

//       return isValid;
//     });
//   };

//   // Function to calculate status data based on filtered appointments
//   const getStatusData = () => {
//     const filteredAppointments = filterAppointments();
//     const statusCounts = filteredAppointments.reduce((acc, appointment) => {
//       acc[appointment.status] = (acc[appointment.status] || 0) + 1;
//       return acc;
//     }, {});

//     const totalAppointments = filteredAppointments.length;
//     const statuses = [
//       "confirmed",
//       "rescheduled",
//       "rejected",
//       "completed",
//       "cancelled",
//     ];
//     const series = statuses.map((status) => statusCounts[status] || 0);
//     const percentages =
//       totalAppointments > 0
//         ? series.map((count) => ((count / totalAppointments) * 100).toFixed(1))
//         : statuses.map(() => "0.0");
//     return { statuses, series, percentages };
//   };

//   // Function to generate data for specific status over dates
//   const getDateStatusData = (status) => {
//     const filteredData = filterAppointments().filter(
//       (appointment) => appointment.status === status
//     );
//     const dataByDate = filteredData.reduce((acc, appointment) => {
//       const date = appointment.appointment_date;
//       acc[date] = (acc[date] || 0) + 1;
//       return acc;
//     }, {});

//     const dates = Object.keys(dataByDate);
//     const values = Object.values(dataByDate);

//     return { dates, values };
//   };

//   // Function to create area chart options
//   const createAreaChartOptions = (data, color, status, percentage) => {
//     return {
//       chart: {
//         type: "area",
//         height: 90,
//         sparkline: {
//           enabled: true,
//         },
//       },
//       colors: [color],
//       fill: {
//         opacity: 1,
//       },
//       stroke: {
//         width: 2,
//         curve: "smooth",
//       },
//       tooltip: {
//         shared: true,
//         y: {
//           formatter: function (val) {
//             return `${val} appointments`;
//           },
//         },
//       },
//       xaxis: {
//         categories: data.dates,
//         title: {
//           text: "Week",
//         },
//       },
//       yaxis: {
//         title: {
//           text: "Number of Appointments",
//         },
//       },
//       title: {
//         text: `${status} (${percentage}%)`,
//         align: "left",
//         style: {
//           fontSize: "14px",
//           fontWeight: "bold",
//         },
//       },
//     };
//   };

//   // Function to create pie chart options
//   const createPieChartOptions = () => {
//     const statusData = getStatusData();
//     return {
//       series: statusData.series,
//       chart: { type: "pie", height: 350 },
//       labels: statusData.statuses.map(
//         (status, index) =>
//           `${status.charAt(0).toUpperCase() + status.slice(1)} (${
//             statusData.percentages[index]
//           }%)`
//       ),
//       colors: [
//         "#28a745",
//         "#f7b731",
//         "#dc3545",
//         // "#007bff",
//         "#6f42c1",
//         "#6c757d",
//       ], // Green, Orange, Red, Blue, Purple, Gray
//       legend: { position: "bottom" },
//       responsive: [
//         {
//           breakpoint: 480,
//           options: {
//             chart: { width: "100%" },
//             legend: { position: "bottom" },
//           },
//         },
//       ],
//     };
//   };

//   // Handle filter change
//   const handleFilterChange = (event) => {
//     const { name, value } = event.target;
//     setFilter((prevFilter) => ({
//       ...prevFilter,
//       [name]: value,
//     }));
//   };

//   const statuses = getStatusData().statuses;
//   const percentages = getStatusData().percentages;
//   const colors = [
//     "#28a745",
//     "#f7b731",
//     "#dc3545",
//     // "#007bff",
//     "#6f42c1",
//     "#6c757d",
//   ]; // Green, Orange, Red, Blue, Purple, Gray

//   return (
//     <div className="container">
//       {loading ? (
//         <SkeletonLoader />
//       ) : (
//         <>
//           <div className="row">
//             {/* Date Filter Section */}
//             <div className="col-12 mt-4">
//               <div className="card shadow border-0 p-4">
//                 <h6 className="mb-4">Filter Appointments</h6>
//                 <div className="d-flex justify-content-between">
//                   <select
//                     name="quarter"
//                     value={filter.quarter}
//                     onChange={handleFilterChange}
//                     className="form-control"
//                   >
//                     <option value="">Select Quarter</option>
//                     {[1, 2, 3, 4].map((quarter) => (
//                       <option key={quarter} value={quarter}>
//                         Q{quarter}
//                       </option>
//                     ))}
//                   </select>
//                   <select
//                     name="year"
//                     value={filter.year}
//                     onChange={handleFilterChange}
//                     className="form-control"
//                   >
//                     <option value="">Select Year</option>
//                     {years.map((year) => (
//                       <option key={year} value={year}>
//                         {year}
//                       </option>
//                     ))}
//                   </select>
//                   <select
//                     name="month"
//                     value={filter.month}
//                     onChange={handleFilterChange}
//                     className="form-control"
//                   >
//                     <option value="">Select Month</option>
//                     {[...Array(12).keys()].map((month) => (
//                       <option key={month} value={month + 1}>
//                         {moment().month(month).format("MMMM")}
//                       </option>
//                     ))}
//                   </select>
//                 </div>
//               </div>
//             </div>
//           </div>

//           {/* Render Charts based on filtered data */}
//           <div className="row">
//             {/* First Row: Area Charts */}
//             {statuses.slice(0, 3).map((status, index) => {
//               const data = getDateStatusData(status);
//               return (
//                 <div className="col-xl-4 col-lg-6 mt-4" key={status}>
//                   <div className="card shadow border-0 p-4">
//                     <div className="d-flex justify-content-between mb-3">
//                       <h6 className="align-items-center mb-0">
//                         {status.charAt(0).toUpperCase() + status.slice(1)} (
//                         {percentages[index]}%)
//                       </h6>
//                       <p className="mb-0 text-muted">
//                         {data.values.reduce((a, b) => a + b, 0)}+ Week
//                       </p>
//                     </div>
//                     <Chart
//                       options={createAreaChartOptions(
//                         data,
//                         colors[index],
//                         status,
//                         percentages[index]
//                       )}
//                       series={[{ data: data.values }]}
//                       type="area"
//                       width="100%"
//                       height={90}
//                     />
//                   </div>
//                 </div>
//               );
//             })}
//           </div>

//           <div className="row">
//             {/* Second Row: Remaining Area Charts and Pie Chart */}
//             {statuses.slice(3).map((status, index) => {
//               const data = getDateStatusData(status);
//               return (
//                 <div className="col-xl-4 col-lg-6 mt-4" key={status}>
//                   <div className="card shadow border-0 p-4">
//                     <div className="d-flex justify-content-between mb-3">
//                       <h6 className="align-items-center mb-0">
//                         {status.charAt(0).toUpperCase() + status.slice(1)} (
//                         {percentages[statuses.indexOf(status)]}%)
//                       </h6>
//                       <p className="mb-0 text-muted">
//                         {data.values.reduce((a, b) => a + b, 0)}+ Week
//                       </p>
//                     </div>
//                     <Chart
//                       options={createAreaChartOptions(
//                         data,
//                         colors[statuses.indexOf(status)],
//                         status,
//                         percentages[index]
//                       )}
//                       series={[{ data: data.values }]}
//                       type="area"
//                       width="100%"
//                       height={90}
//                     />
//                   </div>
//                 </div>
//               );
//             })}

//             <div className="col-lg-12 mt-4">
//               <div className="card shadow border-0 p-4">
//                 <h6 className="mb-4">Appointment Status Distribution</h6>
//                 <Chart
//                   options={createPieChartOptions()}
//                   series={getStatusData().series}
//                   type="pie"
//                   width="100%"
//                   height={350}
//                 />
//               </div>
//             </div>
//           </div>
//         </>
//       )}
//     </div>
//   );
// }


// import React, { useState, useEffect } from "react";
// import Chart from "react-apexcharts";
// import axios from "axios";
// import SkeletonLoader from "../../pages/dashboard/SkeltonLoader";
// import { getDoctorId } from "../../_helper/helper";
// import { get } from "../../api_helper";
// import moment from "moment"; // Import moment.js to help with date filtering

// export default function Charts() {
//   const [appointmentData, setAppointmentData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [filter, setFilter] = useState({ type: "", quarter: "", year: "", month: "" });
//   const [years, setYears] = useState([]);
//   const token = localStorage.getItem("accessToken");
//   const doctorId = getDoctorId();

//   // Function to fetch appointment data based on filter
//   const fetchData = async () => {
//     try {
//       const response = await get(
//         `${process.env.REACT_APP_API_URL}/doctor/appointments/${doctorId}`
//       );
//       console.log("Fetched Data:", response);
//       setAppointmentData(response || []);
//       setLoading(false);
//       extractYears(response);  // Extract years from fetched data
//     } catch (error) {
//       console.error("Error fetching appointment data:", error);
//       setLoading(false);
//     }
//   };

//   // Function to extract unique years from the fetched appointment data
//   const extractYears = (data) => {
//     const uniqueYears = Array.from(new Set(data.map(appointment => moment(appointment.appointment_date).year())));
//     setYears(uniqueYears.sort((a, b) => b - a));  // Sort years descending
//   };

//   // UseEffect to fetch data when component mounts
//   useEffect(() => {
//     if (doctorId !== null && token) {
//       fetchData();
//     }
//   }, [doctorId, token]);

//   // Filter function based on selected month, year, week, or quarter
//   const filterAppointments = () => {
//     return appointmentData.filter((appointment) => {
//       const appointmentDate = moment(appointment.appointment_date);
//       let isValid = true;

//       if (filter.year && appointmentDate.year() !== parseInt(filter.year)) {
//         isValid = false;
//       }

//       if (filter.month && appointmentDate.month() + 1 !== parseInt(filter.month)) {
//         isValid = false;
//       }

//       if (filter.quarter) {
//         const quarterStartMonth = (parseInt(filter.quarter) - 1) * 3;
//         const quarterEndMonth = quarterStartMonth + 2;
//         if (
//           appointmentDate.month() < quarterStartMonth ||
//           appointmentDate.month() > quarterEndMonth
//         ) {
//           isValid = false;
//         }
//       }

//       return isValid;
//     });
//   };

//   // Function to calculate status data based on filtered appointments
//   const getStatusData = () => {
//     const filteredAppointments = filterAppointments();
//     const statusCounts = filteredAppointments.reduce((acc, appointment) => {
//       acc[appointment.status] = (acc[appointment.status] || 0) + 1;
//       return acc;
//     }, {});

//     const totalAppointments = filteredAppointments.length;
//     const statuses = [
//       "confirmed",
//       "rescheduled",
//       "rejected",
//       "completed",
//       "cancelled",
//     ];
//     const series = statuses.map((status) => statusCounts[status] || 0);
//     const percentages =
//       totalAppointments > 0
//         ? series.map((count) => ((count / totalAppointments) * 100).toFixed(1))
//         : statuses.map(() => "0.0");
//     return { statuses, series, percentages };
//   };

//   // Function to generate data for specific status over dates
//   const getDateStatusData = (status) => {
//     const filteredData = filterAppointments().filter(
//       (appointment) => appointment.status === status
//     );
//     const dataByDate = filteredData.reduce((acc, appointment) => {
//       const date = appointment.appointment_date;
//       acc[date] = (acc[date] || 0) + 1;
//       return acc;
//     }, {});

//     const dates = Object.keys(dataByDate);
//     const values = Object.values(dataByDate);

//     return { dates, values };
//   };

//   // Function to create area chart options
//   const createAreaChartOptions = (data, color, status, percentage) => {
//     return {
//       chart: {
//         type: "area",
//         height: 90,
//         sparkline: {
//           enabled: true,
//         },
//       },
//       colors: [color],
//       fill: {
//         opacity: 1,
//       },
//       stroke: {
//         width: 2,
//         curve: "smooth",
//       },
//       tooltip: {
//         shared: true,
//         y: {
//           formatter: function (val) {
//             return `${val} appointments`;
//           },
//         },
//       },
//       xaxis: {
//         categories: data.dates,
//         title: {
//           text: "Week",
//         },
//       },
//       yaxis: {
//         title: {
//           text: "Number of Appointments",
//         },
//       },
//       title: {
//         text: `${status} (${percentage}%)`,
//         align: "left",
//         style: {
//           fontSize: "14px",
//           fontWeight: "bold",
//         },
//       },
//     };
//   };

//   // Function to create pie chart options
//   const createPieChartOptions = () => {
//     const statusData = getStatusData();
//     return {
//       series: statusData.series,
//       chart: { type: "pie", height: 350 },
//       labels: statusData.statuses.map(
//         (status, index) =>
//           `${status.charAt(0).toUpperCase() + status.slice(1)} (${
//             statusData.percentages[index]
//           }%)`
//       ),
//       colors: [
//         "#28a745",
//         "#f7b731",
//         "#dc3545",
//         "#007bff",
//         "#6f42c1",
//         "#6c757d",
//       ], // Green, Orange, Red, Blue, Purple, Gray
//       legend: { position: "bottom" },
//       responsive: [
//         {
//           breakpoint: 480,
//           options: {
//             chart: { width: "100%" },
//             legend: { position: "bottom" },
//           },
//         },
//       ],
//     };
//   };

//   // Handle filter change
//   const handleFilterChange = (event) => {
//     const { name, value } = event.target;
//     setFilter((prevFilter) => ({
//       ...prevFilter,
//       [name]: value,
//     }));
//   };

//   const statuses = getStatusData().statuses;
//   const percentages = getStatusData().percentages;
//   const colors = [
//     "#28a745",
//     "#f7b731",
//     "#dc3545",
//     "#007bff",
//     "#6f42c1",
//     "#6c757d",
//   ]; // Green, Orange, Red, Blue, Purple, Gray

//   return (
//     <div className="container">
//       {loading ? (
//         <SkeletonLoader />
//       ) : (
//         <>
//           <div className="row">
//             {/* Date Filter Section */}
//             <div className="col-12 mt-4">
//               <div className="card shadow border-0 p-4">
//                 <h6 className="mb-4">Filter Appointments</h6>
//                 <div className="d-flex justify-content-between">
//                   <select
//                     name="type"
//                     value={filter.type}
//                     onChange={handleFilterChange}
//                     className="form-control"
//                   >
//                     <option value="">Select Filter</option>
//                     <option value="quarter">Quarterly</option>
//                     <option value="year">Yearly</option>
//                     <option value="month">Monthly</option>
//                   </select>

//                   {filter.type === "quarter" && (
//                     <select
//                       name="quarter"
//                       value={filter.quarter}
//                       onChange={handleFilterChange}
//                       className="form-control"
//                     >
//                       <option value="">Select Quarter</option>
//                       {[1, 2, 3, 4].map((quarter) => (
//                         <option key={quarter} value={quarter}>
//                           Q{quarter}
//                         </option>
//                       ))}
//                     </select>
//                   )}

//                   {filter.type === "year" && (
//                     <select
//                       name="year"
//                       value={filter.year}
//                       onChange={handleFilterChange}
//                       className="form-control"
//                     >
//                       <option value="">Select Year</option>
//                       {years.map((year) => (
//                         <option key={year} value={year}>
//                           {year}
//                         </option>
//                       ))}
//                     </select>
//                   )}

//                   {filter.type === "month" && (
//                     <select
//                       name="month"
//                       value={filter.month}
//                       onChange={handleFilterChange}
//                       className="form-control"
//                     >
//                       <option value="">Select Month</option>
//                       {[...Array(12).keys()].map((month) => (
//                         <option key={month} value={month + 1}>
//                           {moment().month(month).format("MMMM")}
//                         </option>
//                       ))}
//                     </select>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>

//           {/* Render Charts based on filtered data */}
//           <div className="row">
//             {/* First Row: Area Charts */}
//             {statuses.slice(0, 3).map((status, index) => {
//               const data = getDateStatusData(status);
//               return (
//                 <div className="col-xl-4 col-lg-6 mt-4" key={status}>
//                   <div className="card shadow border-0 p-4">
//                     <div className="d-flex justify-content-between mb-3">
//                       <h6 className="align-items-center mb-0">
//                         {status.charAt(0).toUpperCase() + status.slice(1)} (
//                         {percentages[index]}%)
//                       </h6>
//                       <p className="mb-0 text-muted">
//                         {data.values.reduce((a, b) => a + b, 0)}+ Week
//                       </p>
//                     </div>
//                     <Chart
//                       options={createAreaChartOptions(
//                         data,
//                         colors[index],
//                         status,
//                         percentages[index]
//                       )}
//                       series={[{ data: data.values }]}

//                       type="area"
//                       width="100%"
//                       height={90}
//                     />
//                   </div>
//                 </div>
//               );
//             })}
//           </div>

//           <div className="row">
//             {/* Second Row: Remaining Area Charts and Pie Chart */}
//             {statuses.slice(3).map((status, index) => {
//               const data = getDateStatusData(status);
//               return (
//                 <div className="col-xl-4 col-lg-6 mt-4" key={status}>
//                   <div className="card shadow border-0 p-4">
//                     <div className="d-flex justify-content-between mb-3">
//                       <h6 className="align-items-center mb-0">
//                         {status.charAt(0).toUpperCase() + status.slice(1)} (
//                         {percentages[statuses.indexOf(status)]}%)
//                       </h6>
//                       <p className="mb-0 text-muted">
//                         {data.values.reduce((a, b) => a + b, 0)}+ Week
//                       </p>
//                     </div>
//                     <Chart
//                       options={createAreaChartOptions(
//                         data,
//                         colors[statuses.indexOf(status)],
//                         status,
//                         percentages[index]
//                       )}
//                       series={[{ data: data.values }]}
//                       type="area"
//                       width="100%"
//                       height={90}
//                     />
//                   </div>
//                 </div>
//               );
//             })}

//             <div className="col-lg-12 mt-4">
//               <div className="card shadow border-0 p-4">
//                 <h6 className="mb-4">Appointment Status Distribution</h6>
//                 <Chart
//                   options={createPieChartOptions()}
//                   series={getStatusData().series}
//                   type="pie"
//                   width="100%"
//                   height={350}
//                 />
//               </div>
//             </div>
//           </div>
//         </>
//       )}
//     </div>
//   );
// }


import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import SkeletonLoader from "../../pages/dashboard/SkeltonLoader";
import { getDoctorId } from "../../_helper/helper";
import { get } from "../../api_helper";
import moment from "moment"; // Import moment.js to help with date filtering

export default function Charts() {
  const [appointmentData, setAppointmentData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({ type: "", quarter: "", year: "", month: "" });
  const [years, setYears] = useState([]);
  const token = localStorage.getItem("accessToken");
  const doctorId = getDoctorId();

  // Function to fetch appointment data based on filter
  const fetchData = async () => {
    try {
      const response = await get(
        `${process.env.REACT_APP_API_URL}/doctor/appointments/${doctorId}`
      );
      console.log("Fetched Data:", response);
      setAppointmentData(response || []);
      setLoading(false);
      extractYears(response);  // Extract years from fetched data
    } catch (error) {
      console.error("Error fetching appointment data:", error);
      setLoading(false);
    }
  };

  // Function to extract unique years from the fetched appointment data
  const extractYears = (data) => {
    const uniqueYears = Array.from(new Set(data.map(appointment => moment(appointment.appointment_date).year())));
    setYears(uniqueYears.sort((a, b) => b - a));  // Sort years descending
  };

  // UseEffect to fetch data when component mounts
  useEffect(() => {
    if (doctorId !== null && token) {
      fetchData();
    }
  }, [doctorId, token]);

  // Filter function based on selected month, year, week, or quarter
  const filterAppointments = () => {
    return appointmentData.filter((appointment) => {
      const appointmentDate = moment(appointment.appointment_date);
      let isValid = true;

      // Yearly filter: Check if appointment year matches selected year
      if (filter.year && appointmentDate.year() !== parseInt(filter.year)) {
        isValid = false;
      }

      // Monthly filter: Check if appointment month matches selected month
      if (filter.month && appointmentDate.month() + 1 !== parseInt(filter.month)) {
        isValid = false;
      }

      // Quarterly filter: Check if appointment falls within selected quarter
      if (filter.quarter) {
        const quarterStartMonth = (parseInt(filter.quarter) - 1) * 3;
        const quarterEndMonth = quarterStartMonth + 2;
        if (
          appointmentDate.month() < quarterStartMonth ||
          appointmentDate.month() > quarterEndMonth
        ) {
          isValid = false;
        }
      }

      return isValid;
    });
  };

  // Function to calculate status data based on filtered appointments
  const getStatusData = () => {
    const filteredAppointments = filterAppointments();
    const statusCounts = filteredAppointments.reduce((acc, appointment) => {
      acc[appointment.status] = (acc[appointment.status] || 0) + 1;
      return acc;
    }, {});

    const totalAppointments = filteredAppointments.length;
    const statuses = [
      "confirmed",
      "rescheduled",
      "rejected",
      "completed",
      "cancelled",
    ];
    const series = statuses.map((status) => statusCounts[status] || 0);
    const percentages =
      totalAppointments > 0
        ? series.map((count) => ((count / totalAppointments) * 100).toFixed(1))
        : statuses.map(() => "0.0");
    return { statuses, series, percentages };
  };

  // Function to generate data for specific status over dates
  const getDateStatusData = (status) => {
    const filteredData = filterAppointments().filter(
      (appointment) => appointment.status === status
    );
    const dataByDate = filteredData.reduce((acc, appointment) => {
      const date = appointment.appointment_date;
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {});

    const dates = Object.keys(dataByDate);
    const values = Object.values(dataByDate);

    return { dates, values };
  };

  // Function to create area chart options
  const createAreaChartOptions = (data, color, status, percentage) => {
    return {
      chart: {
        type: "area",
        height: 90,
        sparkline: {
          enabled: true,
        },
      },
      colors: [color],
      fill: {
        opacity: 1,
      },
      stroke: {
        width: 2,
        curve: "smooth",
      },
      tooltip: {
        shared: true,
        y: {
          formatter: function (val) {
            return `${val} appointments`;
          },
        },
      },
      xaxis: {
        categories: data.dates,
        title: {
          text: "Week",
        },
      },
      // yaxis: {
      //   title: {
      //     text: "Number of Appointments",
      //   },
      // },
      // title: {
      //   text: `${status} (${percentage}%)`,
      //   align: "left",
      //   style: {
      //     fontSize: "14px",
      //     fontWeight: "bold",
      //   },
      // },
    };
  };

  // Function to create pie chart options
  const createPieChartOptions = () => {
    const statusData = getStatusData();
    return {
      series: statusData.series,
      chart: { type: "pie", height: 350 },
      labels: statusData.statuses.map(
        (status, index) =>
          `${status.charAt(0).toUpperCase() + status.slice(1)} (${
            statusData.percentages[index]
          }%)`
      ),
      colors: [
        "#28a745",
        "#f7b731",
        "#dc3545",
        // "#007bff",
        "#6f42c1",
        "#6c757d",
      ], // Green, Orange, Red, Blue, Purple, Gray
      legend: { position: "bottom" },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: { width: "100%" },
            legend: { position: "bottom" },
          },
        },
      ],
    };
  };

  // Handle filter change
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    // Reset filters if switching between "Quarter" and "Year" type
    if (name === "type") {
      setFilter((prevFilter) => ({
        ...prevFilter,
        [name]: value,
        quarter: "", // Reset quarter
        month: "",   // Reset month
        year: ""     // Reset year if switching
      }));
    } else {
      setFilter((prevFilter) => ({
        ...prevFilter,
        [name]: value,
      }));
    }
  };

  const statuses = getStatusData().statuses;
  const percentages = getStatusData().percentages;
  const colors = [
    "#28a745",
    "#f7b731",
    "#dc3545",
    "#007bff",
    "#6f42c1",
    "#6c757d",
  ]; // Green, Orange, Red, Blue, Purple, Gray

  return (
    <div className="container">
      {loading ? (
        <SkeletonLoader />
      ) : (
        <>
          <div className="row">
            {/* Date Filter Section */}
            <div className="col-12 mt-4">
              <div className="card shadow border-0 p-4">
                <h6 className="mb-4">Filter Appointments</h6>
                <div className="d-flex justify-content-between">
                  <select
                    name="type"
                    value={filter.type}
                    onChange={handleFilterChange}
                    className="form-control"
                  >
                    <option value="">Select Filter Type</option>
                    <option value="quarter">Quarter</option>
                    <option value="year">Year</option>
                    <option value="month">Month</option>
                  </select>

                  {filter.type === "quarter" && (
                    <select
                      name="quarter"
                      value={filter.quarter}
                      onChange={handleFilterChange}
                      className="form-control"
                    >
                      <option value="">Select Quarter</option>
                      {[1, 2, 3, 4].map((quarter) => (
                        <option key={quarter} value={quarter}>
                          Q{quarter}
                        </option>
                      ))}
                    </select>
                  )}

                  {filter.type === "year" && (
                    <select
                      name="year"
                      value={filter.year}
                      onChange={handleFilterChange}
                      className="form-control"
                    >
                      <option value="">Select Year</option>
                      {years.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  )}

                  {filter.type === "month" && (
                    <select
                      name="month"
                      value={filter.month}
                      onChange={handleFilterChange}
                      className="form-control"
                    >
                      <option value="">Select Month</option>
                      {Array.from({ length: 12 }).map((_, month) => (
                        <option key={month} value={month + 1}>
                          {moment().month(month).format("MMMM")}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Render Charts based on filtered data */}
          <div className="row">
            {/* First Row: Area Charts */}
            {statuses.slice(0, 3).map((status, index) => {
              const data = getDateStatusData(status);
              return (
                <div className="col-xl-4 col-lg-6 mt-4" key={status}>
                  <div className="card shadow border-0 p-4">
                    <div className="d-flex justify-content-between mb-3">
                      <h6 className="align-items-center mb-0">
                        {status.charAt(0).toUpperCase() + status.slice(1)} (
                        {percentages[index]}%)
                      </h6>
                      <p className="mb-0 text-muted">
                        {data.values.reduce((a, b) => a + b, 0)}+ Week
                      </p>
                    </div>
                    <Chart
                      options={createAreaChartOptions(
                        data,
                        colors[index],
                        status,
                        percentages[index]
                      )}
                      series={[{ data: data.values }]}
                      type="area"
                      width="100%"
                      height={90}
                    />
                  </div>
                </div>
              );
            })}
          </div>

          <div className="row">
            {/* Second Row: Remaining Area Charts and Pie Chart */}
            {statuses.slice(3).map((status, index) => {
              const data = getDateStatusData(status);
              return (
                <div className="col-xl-4 col-lg-6 mt-4" key={status}>
                  <div className="card shadow border-0 p-4">
                    <div className="d-flex justify-content-between mb-3">
                      <h6 className="align-items-center mb-0">
                        {status.charAt(0).toUpperCase() + status.slice(1)} (
                        {percentages[statuses.indexOf(status)]}%)
                      </h6>
                      <p className="mb-0 text-muted">
                        {data.values.reduce((a, b) => a + b, 0)}+ Week
                      </p>
                    </div>
                    <Chart
                      options={createAreaChartOptions(
                        data,
                        colors[statuses.indexOf(status)],
                        status,
                        // percentages[index]
                        percentages[statuses.indexOf(status)]
                      )}
                      series={[{ data: data.values }]}
                      type="area"
                      width="100%"
                      height={90}
                    />
                  </div>
                </div>
              );
            })}

            <div className="col-lg-12 mt-4">
              <div className="card shadow border-0 p-4">
                <h6 className="mb-4">Appointment Status Distribution</h6>
                <Chart
                  options={createPieChartOptions()}
                  series={getStatusData().series}
                  type="pie"
                  width="100%"
                  height={350}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
