// import React, { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import Modal from 'react-bootstrap/Modal';
// import { Button, Form, Table } from 'react-bootstrap';
// import {Tooltip, OverlayTrigger } from 'react-bootstrap';

// import { FaTrashAlt, FaEdit } from 'react-icons/fa';
// import Navbar from "../../components/navbar";
// import Sidebar from "../../components/sidebar";
// import AdminFooter from "../../components/dashboard/adminFooter";
// import ScrollTop from "../../components/scrollTop";
// import { toast, ToastContainer } from 'react-toastify'; // Import Toastify
// import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles
// import Header from "../../components/CommonComponents/Header";

// export default function Cliniclist () {
//     const [show, setShow] = useState(false);
//     const [showConfirm, setShowConfirm] = useState(false);
//     const [clinicToDelete, setClinicToDelete] = useState(null);
//     const [clinicDetails, setClinicDetails] = useState({
//         name: '',
//         address: '',
//         contact_number: '',
//         email: ''
//     });
//     const [clinics, setClinics] = useState([]);
//     const [isUpdating, setIsUpdating] = useState(false); // Track if we are in update mode
//     const [currentClinicId, setCurrentClinicId] = useState(null); // ID of the clinic being updated
//     const doctorId = localStorage.getItem("doctorId"); // Get doctor ID
//     const token = localStorage.getItem('accessToken');
//     const [loading, setLoading] = useState(false);
//     const navigate = useNavigate();

//     const fetchClinics = async () => {
//         setLoading(true);
//         try {
//             const response = await fetch(`${process.env.REACT_APP_API_URL}/clinics/${doctorId}`, {
//                 method: 'GET',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${token}`
//                 }
//             });
//             if (!response.ok) {
//                 throw new Error('Network response was not ok');
//             }
//             const result = await response.json();
//             setClinics(result.data);
//         } catch (error) {
//             console.error('Error fetching clinics:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchClinics();
//     }, [token]);

//     const openClinicModal = (clinic = null) => {
//         if (clinic) {
//             setClinicDetails({
//                 name: clinic.name,
//                 address: clinic.address,
//                 contact_number: clinic.contact_number,
//                 email: clinic.email
//             });
//             setCurrentClinicId(clinic.id);
//             setIsUpdating(true);
//         } else {
//             setClinicDetails({
//                 name: '',
//                 address: '',
//                 contact_number: '',
//                 email: ''
//             });
//             setCurrentClinicId(null);
//             setIsUpdating(false);
//         }
//         setShow(true);
//     };

//     const handleClinicChange = (e) => {
//         const { name, value } = e.target;
//         setClinicDetails(prevDetails => ({ ...prevDetails, [name]: value }));
//     };

//     const handleClinicSubmit = async () => {
//         setLoading(true);
//         try {
//             const url = isUpdating
//                 ? `${process.env.REACT_APP_API_URL}/clinics/${doctorId}/${currentClinicId}/`
//                 : `${process.env.REACT_APP_API_URL}/clinics/`;
//             const method = isUpdating ? 'PUT' : 'POST';

//             const response = await fetch(url, {
//                 method,
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${token}`
//                 },
//                 body: JSON.stringify({
//                     doctor: doctorId,
//                     ...clinicDetails
//                 })
//             });

//             if (!response.ok) {
//                 throw new Error('Network response was not ok');
//             }

//             ShowSucess(`Clinic successfully ${isUpdating ? 'updated' : 'created'}!`);
//             setShow(false);
//             fetchClinics();
//         } catch (error) {
//             console.error(`Error ${isUpdating ? 'updating' : 'creating'} clinic:`, error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleDelete = async () => {
//         setLoading(true);
//         try {
//             const response = await fetch(`${process.env.REACT_APP_API_URL}/clinics/${doctorId}/${clinicToDelete}/`, {
//                 method: 'DELETE',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${token}`
//                 }
//             });
//             if (!response.ok) {
//                 throw new Error('Network response was not ok');
//             }
//             ShowSucess('Clinic successfully deleted!');
//             setShowConfirm(false);
//             fetchClinics();
//         } catch (error) {
//             console.error('Error deleting clinic:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const confirmDelete = (id) => {
//         setClinicToDelete(id);
//         setShowConfirm(true);
//     };

//     return (
//         <>
//             <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container" />
//             {loading && (
//                 <div style={{
//                     position: 'fixed',
//                     top: '50%',
//                     left: '50%',
//                     transform: 'translate(-50%, -50%)',
//                     zIndex: 9999
//                 }}>
//                     <div className="spinner-border" role="status">
//                         <span className="visually-hidden">Loading...</span>
//                     </div>
//                 </div>
//             )}
//             <section className="bg-dashboard">
//                 <div className="container-fluid">
//                     <div className="row">
//                         <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
//                         <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
//                             <Header title="Clinic Management"
//                                 onBackClick={() => navigate(-1)} // Go back to the previous page
//                                 showBackButton={location.key !== 'default'}
//                             />
//                             <Button variant="primary" onClick={() => openClinicModal()} style={{ marginTop: '20px' }}>
//                                 Add Clinic
//                             </Button>
//                             <div className="row">
//                                 <div className="col-12 mt-4 pt-2">
//                                     <div className="table-responsive shadow rounded">
//                                         <Table className="table table-center bg-white mb-0">
//                                             <thead>
//                                                 <tr>
//                                                     <th className="border-bottom p-3">Clinic Name</th>
//                                                     <th className="border-bottom p-3">Address</th>
//                                                     <th className="border-bottom p-3">Contact Number</th>
//                                                     <th className="border-bottom p-3">Email</th>
//                                                     <th className="border-bottom p-3" style={{ minWidth: '150px' }}></th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 {clinics.map(clinic => (
//                                                     <tr key={clinic.id}>
//                                                         <td className="p-3">{clinic.name}</td>
//                                                         <td className="p-3">{clinic.address}</td>
//                                                         <td className="p-3">{clinic.contact_number}</td>
//                                                         <td className="p-3">{clinic.email}</td>
//                                                         <td className="text-end p-3">
//     <OverlayTrigger
//         placement="top"
//         overlay={<Tooltip id={`tooltip-edit-${clinic.id}`}>Edit Clinic</Tooltip>}
//     >
//         <Button
//             variant="primary"
//             onClick={() => openClinicModal(clinic)}
//             className="btn-icon btn-pills btn-soft-primary my-1"
//         >
//             <FaEdit />
//         </Button>
//     </OverlayTrigger>
//     <OverlayTrigger
//         placement="top"
//         overlay={<Tooltip id={`tooltip-delete-${clinic.id}`}>Delete Clinic</Tooltip>}
//     >
//         <Button
//             variant="danger"
//             onClick={() => confirmDelete(clinic.id)}
//             className="btn-icon btn-pills btn-soft-danger my-1"
//         >
//             <FaTrashAlt />
//         </Button>
//     </OverlayTrigger>
// </td>
//                                                         {/* <td className="text-end p-3">
//                                                             <Button variant="primary" onClick={() => openClinicModal(clinic)} className="btn-icon btn-pills btn-soft-primary my-1">
//                                                                 <FaEdit />
//                                                             </Button>
//                                                             <Button variant="danger" onClick={() => confirmDelete(clinic.id)} className="btn-icon btn-pills btn-soft-danger my-1">
//                                                                 <FaTrashAlt />
//                                                             </Button>
//                                                         </td> */}
//                                                     </tr>
//                                                 ))}
//                                             </tbody>
//                                         </Table>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <ToastContainer />
//             </section>

//             <Modal
//                 show={show}
//                 onHide={() => setShow(false)}
//                 centered
//                 size="lg"
//                 backdrop="static"
//                 dialogClassName="modal-90w"
//             >
//                 <Modal.Header closeButton>
//                     <Modal.Title>{isUpdating ? 'Update Clinic' : 'Add Clinic'}</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <Form>
//                         <Form.Group className="mb-3">
//                             <Form.Label>Clinic Name</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 name="name"
//                                 value={clinicDetails.name}
//                                 onChange={handleClinicChange}
//                                 placeholder="Clinic Name"
//                             />
//                         </Form.Group>
//                         <Form.Group className="mb-3">
//                             <Form.Label>Address</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 name="address"
//                                 value={clinicDetails.address}
//                                 onChange={handleClinicChange}
//                                 placeholder="Address"
//                             />
//                         </Form.Group>
//                         <Form.Group className="mb-3">
//                             <Form.Label>Contact Number</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 name="contact_number"
//                                 value={clinicDetails.contact_number}
//                                 onChange={handleClinicChange}
//                                 placeholder="Contact Number"
//                             />
//                         </Form.Group>
//                         <Form.Group className="mb-3">
//                             <Form.Label>Email</Form.Label>
//                             <Form.Control
//                                 type="email"
//                                 name="email"
//                                 value={clinicDetails.email}
//                                 onChange={handleClinicChange}
//                                 placeholder="Email"
//                             />
//                         </Form.Group>
//                     </Form>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={() => setShow(false)}>Close</Button>
//                     <Button variant="primary" onClick={handleClinicSubmit}>
//                         {isUpdating ? 'Update Clinic' : 'Add Clinic'}
//                     </Button>
//                 </Modal.Footer>
//             </Modal>

//             <Modal
//                 show={showConfirm}
//                 onHide={() => setShowConfirm(false)}
//                 centered
//                 backdrop="static"
//             >
//                 <Modal.Header closeButton>
//                     <Modal.Title>Confirm Deletion</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     Are you sure you want to delete this clinic?
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={() => setShowConfirm(false)}>Cancel</Button>
//                     <Button variant="danger" onClick={handleDelete}>Delete</Button>
//                 </Modal.Footer>
//             </Modal>

//             <ScrollTop />
//             <AdminFooter />
//         </>
//     );
// }

// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import Modal from 'react-bootstrap/Modal';
// import { Button, Form, Table } from 'react-bootstrap';
// import { Tooltip, OverlayTrigger } from 'react-bootstrap';
// import { FaTrashAlt, FaEdit } from 'react-icons/fa';
// import Navbar from "../../components/navbar";
// import Sidebar from "../../components/sidebar";
// import AdminFooter from "../../components/dashboard/adminFooter";
// import ScrollTop from "../../components/scrollTop";
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import Header from "../../components/CommonComponents/Header";
// import MyMapContainer from "../dashboard/MyMapContainer";
// // import MyMapContainer from './MyMapContainer'; // Import your map component

// export default function Cliniclist () {
//     const [show, setShow] = useState(false);
//     const [showConfirm, setShowConfirm] = useState(false);
//     const [clinicToDelete, setClinicToDelete] = useState(null);
//     const [clinicDetails, setClinicDetails] = useState({
//         name: '',
//         address: '',
//         contact_number: '',
//         email: '',
//         latitude: '', // Add latitude field
//         longitude: '' // Add longitude field
//     });
//     const [clinics, setClinics] = useState([]);
//     const [isUpdating, setIsUpdating] = useState(false);
//     const [currentClinicId, setCurrentClinicId] = useState(null);
//     const doctorId = localStorage.getItem("doctorId");
//     const token = localStorage.getItem('accessToken');
//     const [loading, setLoading] = useState(false);
//     const navigate = useNavigate();

//     const fetchClinics = async () => {
//         setLoading(true);
//         try {
//             const response = await fetch(`${process.env.REACT_APP_API_URL}/clinics/${doctorId}`, {
//                 method: 'GET',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${token}`
//                 }
//             });
//             if (!response.ok) {
//                 throw new Error('Network response was not ok');
//             }
//             const result = await response.json();
//             setClinics(result.data);
//         } catch (error) {
//             console.error('Error fetching clinics:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchClinics();
//     }, [token]);

//     const openClinicModal = (clinic = null) => {
//         if (clinic) {
//             setClinicDetails({
//                 name: clinic.name,
//                 address: clinic.address,
//                 contact_number: clinic.contact_number,
//                 email: clinic.email,
//                 latitude: clinic.latitude || '', // Include latitude if available
//                 longitude: clinic.longitude || '' // Include longitude if available
//             });
//             setCurrentClinicId(clinic.id);
//             setIsUpdating(true);
//         } else {
//             setClinicDetails({
//                 name: '',
//                 address: '',
//                 contact_number: '',
//                 email: '',
//                 latitude: '',
//                 longitude: ''
//             });
//             setCurrentClinicId(null);
//             setIsUpdating(false);
//         }
//         setShow(true);
//     };

//     const handleClinicChange = (e) => {
//         const { name, value } = e.target;
//         setClinicDetails(prevDetails => ({ ...prevDetails, [name]: value }));
//     };

//     const handleLocationSelect = (location) => {
//         setClinicDetails(prevDetails => ({
//             ...prevDetails,
//             latitude: location.lat,
//             longitude: location.lng
//         }));
//     };

//     const handleClinicSubmit = async () => {
//         setLoading(true);
//         try {
//             const url = isUpdating
//                 ? `${process.env.REACT_APP_API_URL}/clinics/${doctorId}/${currentClinicId}/`
//                 : `${process.env.REACT_APP_API_URL}/clinics/`;
//             const method = isUpdating ? 'PUT' : 'POST';

//             const response = await fetch(url, {
//                 method,
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${token}`
//                 },
//                 body: JSON.stringify({
//                     doctor: doctorId,
//                     ...clinicDetails // Include latitude and longitude in the API request
//                 })
//             });

//             if (!response.ok) {
//                 throw new Error('Network response was not ok');
//             }

//             ShowSucess(`Clinic successfully ${isUpdating ? 'updated' : 'created'}!`);
//             setShow(false);
//             fetchClinics();
//         } catch (error) {
//             console.error(`Error ${isUpdating ? 'updating' : 'creating'} clinic:`, error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleDelete = async () => {
//         setLoading(true);
//         try {
//             const response = await fetch(`${process.env.REACT_APP_API_URL}/clinics/${doctorId}/${clinicToDelete}/`, {
//                 method: 'DELETE',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${token}`
//                 }
//             });
//             if (!response.ok) {
//                 throw new Error('Network response was not ok');
//             }
//             ShowSucess('Clinic successfully deleted!');
//             setShowConfirm(false);
//             fetchClinics();
//         } catch (error) {
//             console.error('Error deleting clinic:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const confirmDelete = (id) => {
//         setClinicToDelete(id);
//         setShowConfirm(true);
//     };

//     return (
//         <>
//             <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container" />
//             {loading && (
//                 <div style={{
//                     position: 'fixed',
//                     top: '50%',
//                     left: '50%',
//                     transform: 'translate(-50%, -50%)',
//                     zIndex: 9999
//                 }}>
//                     <div className="spinner-border" role="status">
//                         <span className="visually-hidden">Loading...</span>
//                     </div>
//                 </div>
//             )}
//             <section className="bg-dashboard">
//                 <div className="container-fluid">
//                     <div className="row">
//                         <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
//                         <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
//                             <Header title="Clinic Management"
//                                 onBackClick={() => navigate(-1)} // Go back to the previous page
//                                 showBackButton={location.key !== 'default'}
//                             />
//                             <Button variant="primary" onClick={() => openClinicModal()} style={{ marginTop: '20px' }}>
//                                 Add Clinic
//                             </Button>
//                             <div className="row">
//                                 <div className="col-12 mt-4 pt-2">
//                                     <div className="table-responsive shadow rounded">
//                                         <Table className="table table-center bg-white mb-0">
//                                             <thead>
//                                                 <tr>
//                                                     <th className="border-bottom p-3">Clinic Name</th>
//                                                     <th className="border-bottom p-3">Address</th>
//                                                     <th className="border-bottom p-3">Contact Number</th>
//                                                     <th className="border-bottom p-3">Email</th>
//                                                     <th className="border-bottom p-3" style={{ minWidth: '150px' }}></th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 {clinics.map(clinic => (
//                                                     <tr key={clinic.id}>
//                                                         <td className="p-3">{clinic.name}</td>
//                                                         <td className="p-3">{clinic.address}</td>
//                                                         <td className="p-3">{clinic.contact_number}</td>
//                                                         <td className="p-3">{clinic.email}</td>
//                                                         <td className="text-end p-3">
//                                                             <OverlayTrigger
//                                                                 placement="top"
//                                                                 overlay={<Tooltip id={`tooltip-edit-${clinic.id}`}>Edit Clinic</Tooltip>}
//                                                             >
//                                                                 <Button
//                                                                     variant="primary"
//                                                                     onClick={() => openClinicModal(clinic)}
//                                                                     className="btn-icon btn-pills btn-soft-primary my-1"
//                                                                 >
//                                                                     <FaEdit />
//                                                                 </Button>
//                                                             </OverlayTrigger>
//                                                             <OverlayTrigger
//                                                                 placement="top"
//                                                                 overlay={<Tooltip id={`tooltip-delete-${clinic.id}`}>Delete Clinic</Tooltip>}
//                                                             >
//                                                                 <Button
//                                                                     variant="danger"
//                                                                     onClick={() => confirmDelete(clinic.id)}
//                                                                     className="btn-icon btn-pills btn-soft-danger my-1"
//                                                                 >
//                                                                     <FaTrashAlt />
//                                                                 </Button>
//                                                             </OverlayTrigger>
//                                                         </td>
//                                                     </tr>
//                                                 ))}
//                                             </tbody>
//                                         </Table>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <ToastContainer />
//             </section>

//             <Modal
//                 show={show}
//                 onHide={() => setShow(false)}
//                 centered
//                 size="lg"
//                 backdrop="static"
//                 dialogClassName="modal-90w"
//             >
//                 <Modal.Header closeButton>
//                     <Modal.Title>{isUpdating ? 'Update Clinic' : 'Add Clinic'}</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <Form>
//                         <Form.Group className="mb-3">
//                             <Form.Label>Clinic Name</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 name="name"
//                                 value={clinicDetails.name}
//                                 onChange={handleClinicChange}
//                             />
//                         </Form.Group>
//                         <Form.Group className="mb-3">
//                             <Form.Label>Address</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 name="address"
//                                 value={clinicDetails.address}
//                                 onChange={handleClinicChange}
//                             />
//                         </Form.Group>
//                         <Form.Group className="mb-3">
//                             <Form.Label>Contact Number</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 name="contact_number"
//                                 value={clinicDetails.contact_number}
//                                 onChange={handleClinicChange}
//                             />
//                         </Form.Group>
//                         <Form.Group className="mb-3">
//                             <Form.Label>Email</Form.Label>
//                             <Form.Control
//                                 type="email"
//                                 name="email"
//                                 value={clinicDetails.email}
//                                 onChange={handleClinicChange}
//                             />
//                         </Form.Group>
//                         <Form.Group className="mb-3">
//                             <Form.Label>Latitude</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 name="latitude"
//                                 value={clinicDetails.latitude}
//                                 onChange={handleClinicChange}
//                                 readOnly
//                             />
//                         </Form.Group>
//                         <Form.Group className="mb-3">
//                             <Form.Label>Longitude</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 name="longitude"
//                                 value={clinicDetails.longitude}
//                                 onChange={handleClinicChange}
//                                 readOnly
//                             />
//                         </Form.Group>

//                         {/* Add map component to allow location selection */}
//                         <MyMapContainer  />
//                     </Form>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={() => setShow(false)}>Close</Button>
//                     <Button variant="primary" onClick={handleClinicSubmit}>
//                         {isUpdating ? 'Update Clinic' : 'Add Clinic'}
//                     </Button>
//                 </Modal.Footer>
//             </Modal>

//             <Modal
//                 show={showConfirm}
//                 onHide={() => setShowConfirm(false)}
//                 centered
//                 backdrop="static"
//             >
//                 <Modal.Header closeButton>
//                     <Modal.Title>Confirm Deletion</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     Are you sure you want to delete this clinic?
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={() => setShowConfirm(false)}>Cancel</Button>
//                     <Button variant="danger" onClick={handleDelete}>Delete</Button>
//                 </Modal.Footer>
//             </Modal>

//             <ScrollTop />
//             <AdminFooter />
//         </>
//     );
// }

// import React, { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import Modal from 'react-bootstrap/Modal';
// import { Button, Form, Table } from 'react-bootstrap';
// import { Tooltip, OverlayTrigger } from 'react-bootstrap';
// import { FaTrashAlt, FaEdit } from 'react-icons/fa';
// import Navbar from "../../components/navbar";
// import Sidebar from "../../components/sidebar";
// import AdminFooter from "../../components/dashboard/adminFooter";
// import ScrollTop from "../../components/scrollTop";
// import { toast, ToastContainer } from 'react-toastify'; // Import Toastify
// import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles
// import Header from "../../components/CommonComponents/Header";
// import MyMapContainer from "../dashboard/MyMapContainer";

// export default function ClinicList() {
//     const [show, setShow] = useState(false);
//     const [showConfirm, setShowConfirm] = useState(false);
//     const [clinicToDelete, setClinicToDelete] = useState(null);
//     const [clinicDetails, setClinicDetails] = useState({
//         name: '',
//         address: '',
//         contact_number: '',
//         email: '',
//         latitude: '',
//         longitude: ''
//     });
//     const [clinics, setClinics] = useState([]);
//     const [isUpdating, setIsUpdating] = useState(false); // Track if we are in update mode
//     const [currentClinicId, setCurrentClinicId] = useState(null); // ID of the clinic being updated
//     const doctorId = localStorage.getItem("doctorId"); // Get doctor ID
//     const token = localStorage.getItem('accessToken');
//     const [loading, setLoading] = useState(false);
//     const navigate = useNavigate();

//     const fetchClinics = async () => {
//         setLoading(true);
//         try {
//             const response = await fetch(`${process.env.REACT_APP_API_URL}/clinics/${doctorId}`, {
//                 method: 'GET',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${token}`
//                 }
//             });
//             if (!response.ok) {
//                 throw new Error('Network response was not ok');
//             }
//             const result = await response.json();
//             setClinics(result.data);
//         } catch (error) {
//             console.error('Error fetching clinics:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchClinics();
//     }, [token]);

//     const openClinicModal = (clinic = null) => {
//         if (clinic) {
//             setClinicDetails({
//                 name: clinic.name,
//                 address: clinic.address,
//                 contact_number: clinic.contact_number,
//                 email: clinic.email,
//                 latitude: clinic.latitude || '',
//                 longitude: clinic.longitude || ''
//             });
//             setCurrentClinicId(clinic.id);
//             setIsUpdating(true);
//         } else {
//             setClinicDetails({
//                 name: '',
//                 address: '',
//                 contact_number: '',
//                 email: '',
//                 latitude: '',
//                 longitude: ''
//             });
//             setCurrentClinicId(null);
//             setIsUpdating(false);
//         }
//         setShow(true);
//     };

//     const handleClinicChange = (e) => {
//         const { name, value } = e.target;
//         setClinicDetails(prevDetails => ({ ...prevDetails, [name]: value }));
//     };

//     const handleClinicSubmit = async () => {
//         setLoading(true);
//         try {
//             const url = isUpdating
//                 ? `${process.env.REACT_APP_API_URL}/clinics/${doctorId}/${currentClinicId}/`
//                 : `${process.env.REACT_APP_API_URL}/clinics/`;
//             const method = isUpdating ? 'PUT' : 'POST';

//             const response = await fetch(url, {
//                 method,
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${token}`
//                 },
//                 body: JSON.stringify({
//                     doctor: doctorId,
//                     ...clinicDetails
//                 })
//             });

//             if (!response.ok) {
//                 throw new Error('Network response was not ok');
//             }

//             ShowSucess(`Clinic successfully ${isUpdating ? 'updated' : 'created'}!`);
//             setShow(false);
//             fetchClinics();
//         } catch (error) {
//             console.error(`Error ${isUpdating ? 'updating' : 'creating'} clinic:`, error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleDelete = async () => {
//         setLoading(true);
//         try {
//             const response = await fetch(`${process.env.REACT_APP_API_URL}/clinics/${doctorId}/${clinicToDelete}/`, {
//                 method: 'DELETE',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${token}`
//                 }
//             });
//             if (!response.ok) {
//                 throw new Error('Network response was not ok');
//             }
//             ShowSucess('Clinic successfully deleted!');
//             setShowConfirm(false);
//             fetchClinics();
//         } catch (error) {
//             console.error('Error deleting clinic:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const confirmDelete = (id) => {
//         setClinicToDelete(id);
//         setShowConfirm(true);
//     };

//     const handleLocationSelect = (location) => {
//         setClinicDetails(prevDetails => ({
//             ...prevDetails,
//             latitude: location.lat.toFixed(6),
//             longitude: location.lng.toFixed(6)
//         }));
//     };

//     return (
//         <>
//             <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container" />
//             {loading && (
//                 <div style={{
//                     position: 'fixed',
//                     top: '50%',
//                     left: '50%',
//                     transform: 'translate(-50%, -50%)',
//                     zIndex: 9999
//                 }}>
//                     <div className="spinner-border" role="status">
//                         <span className="visually-hidden">Loading...</span>
//                     </div>
//                 </div>
//             )}
//             <section className="bg-dashboard">
//                 <div className="container-fluid">
//                     <div className="row">
//                         <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
//                         <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
//                             <Header title="Clinic Management"
//                                 onBackClick={() => navigate(-1)} // Go back to the previous page
//                                 showBackButton={location.key !== 'default'}
//                             />
//                             <Button variant="primary" onClick={() => openClinicModal()} style={{ marginTop: '20px' }}>
//                                 Add Clinic
//                             </Button>
//                             <div className="row">
//                                 <div className="col-12 mt-4 pt-2">
//                                     <div className="table-responsive shadow rounded">
//                                         <Table className="table table-center bg-white mb-0">
//                                             <thead>
//                                                 <tr>
//                                                     <th className="border-bottom p-3">Clinic Name</th>
//                                                     <th className="border-bottom p-3">Address</th>
//                                                     <th className="border-bottom p-3">Contact Number</th>
//                                                     <th className="border-bottom p-3">Email</th>
//                                                     <th className="border-bottom p-3">Latitude</th>
//                                                     <th className="border-bottom p-3">Longitude</th>
//                                                     <th className="border-bottom p-3">Actions</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 {clinics.map((clinic) => (
//                                                     <tr key={clinic.id}>
//                                                         <td>{clinic.name}</td>
//                                                         <td>{clinic.address}</td>
//                                                         <td>{clinic.contact_number}</td>
//                                                         <td>{clinic.email}</td>
//                                                         <td>{clinic.latitude}</td>
//                                                         <td>{clinic.longitude}</td>
//                                                         <td >
//                                                         <OverlayTrigger
//         placement="top"
//         overlay={<Tooltip id={`tooltip-edit-${clinic.id}`}>Edit Clinic</Tooltip>}
//     >
//         <Button
//             variant="primary"
//             onClick={() => openClinicModal(clinic)}
//             className="btn-icon btn-pills btn-soft-primary my-1"
//         >
//             <FaEdit />
//         </Button>
//     </OverlayTrigger>
//                                                             <OverlayTrigger
//         placement="top"
//         overlay={<Tooltip id={`tooltip-delete-${clinic.id}`}>Delete Clinic</Tooltip>}
//     >
//         <Button
//             variant="danger"
//             onClick={() => confirmDelete(clinic.id)}
//             className="btn-icon btn-pills btn-soft-danger my-1"
//         >
//             <FaTrashAlt />
//         </Button>
//     </OverlayTrigger>
//                                                             {/* <OverlayTrigger
//                                                                 placement="top"
//                                                                 overlay={<Tooltip>Delete Clinic</Tooltip>}
//                                                             >
//                                                                 <Button variant="link" onClick={() => confirmDelete(clinic.id)}>
//                                                                     <FaTrashAlt />
//                                                                 </Button>
//                                                             </OverlayTrigger> */}
//                                                         </td>
//                                                     </tr>
//                                                 ))}
//                                             </tbody>
//                                         </Table>
//                                     </div>
//                                 </div>
//                             </div>
//                             <ToastContainer />
//                             <Modal show={show} onHide={() => setShow(false)} centered>
//                                 <Modal.Header closeButton>
//                                     <Modal.Title>{isUpdating ? 'Update Clinic' : 'Add Clinic'}</Modal.Title>
//                                 </Modal.Header>
//                                 <Modal.Body>
//                                     <Form>
//                                         <Form.Group controlId="formClinicName">
//                                             <Form.Label>Clinic Name</Form.Label>
//                                             <Form.Control
//                                                 type="text"
//                                                 name="name"
//                                                 value={clinicDetails.name}
//                                                 onChange={handleClinicChange}
//                                                 placeholder="Enter clinic name"
//                                             />
//                                         </Form.Group>
//                                         <Form.Group controlId="formAddress">
//                                             <Form.Label>Address</Form.Label>
//                                             <Form.Control
//                                                 type="text"
//                                                 name="address"
//                                                 value={clinicDetails.address}
//                                                 onChange={handleClinicChange}
//                                                 placeholder="Enter address"
//                                             />
//                                         </Form.Group>
//                                         <Form.Group controlId="formContactNumber">
//                                             <Form.Label>Contact Number</Form.Label>
//                                             <Form.Control
//                                                 type="text"
//                                                 name="contact_number"
//                                                 value={clinicDetails.contact_number}
//                                                 onChange={handleClinicChange}
//                                                 placeholder="Enter contact number"
//                                             />
//                                         </Form.Group>
//                                         <Form.Group controlId="formEmail">
//                                             <Form.Label>Email</Form.Label>
//                                             <Form.Control
//                                                 type="email"
//                                                 name="email"
//                                                 value={clinicDetails.email}
//                                                 onChange={handleClinicChange}
//                                                 placeholder="Enter email"
//                                             />
//                                         </Form.Group>
//                                         <Form.Group controlId="formLatitude">
//                                             <Form.Label>Latitude</Form.Label>
//                                             <Form.Control
//                                                 type="text"
//                                                 name="latitude"
//                                                 value={clinicDetails.latitude}
//                                                 onChange={handleClinicChange}
//                                                 placeholder="Latitude"
//                                                 disabled
//                                             />
//                                         </Form.Group>
//                                         <Form.Group controlId="formLongitude">
//                                             <Form.Label>Longitude</Form.Label>
//                                             <Form.Control
//                                                 type="text"
//                                                 name="longitude"
//                                                 value={clinicDetails.longitude}
//                                                 onChange={handleClinicChange}
//                                                 placeholder="Longitude"
//                                                 disabled
//                                             />
//                                         </Form.Group>
//                                         <Form.Group className="mt-3">
//                                             <MyMapContainer onLocationSelect={handleLocationSelect} />
//                                         </Form.Group>
//                                     </Form>
//                                 </Modal.Body>
//                                 <Modal.Footer>
//                                     <Button variant="secondary" onClick={() => setShow(false)}>
//                                         Close
//                                     </Button>
//                                     <Button variant="primary" onClick={handleClinicSubmit}>
//                                         {isUpdating ? 'Update Clinic' : 'Add Clinic'}
//                                     </Button>
//                                 </Modal.Footer>
//                             </Modal>
//                             <Modal show={showConfirm} onHide={() => setShowConfirm(false)} centered>
//                                 <Modal.Header closeButton>
//                                     <Modal.Title>Confirm Delete</Modal.Title>
//                                 </Modal.Header>
//                                 <Modal.Body>
//                                     Are you sure you want to delete this clinic?
//                                 </Modal.Body>
//                                 <Modal.Footer>
//                                     <Button variant="secondary" onClick={() => setShowConfirm(false)}>
//                                         Cancel
//                                     </Button>
//                                     <Button variant="danger" onClick={handleDelete}>
//                                         Delete
//                                     </Button>
//                                 </Modal.Footer>
//                             </Modal>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//             <AdminFooter />
//             <ScrollTop />
//         </>
//     );
// }

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Button, Form, Table } from "react-bootstrap";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { FaTrashAlt, FaEdit } from "react-icons/fa";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import AdminFooter from "../../components/dashboard/adminFooter";
import ScrollTop from "../../components/scrollTop";
import { getDoctorId } from "../../_helper/helper";
import { ShowSucess, ShowError } from "../../_helper/helper";
import Header from "../../components/CommonComponents/Header";
import MyMapContainer from "../dashboard/MyMapContainer";
import MapContainerClinic from "../dashboard/MapContainerClinic";

export default function ClinicList() {
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [clinicToDelete, setClinicToDelete] = useState(null);
  const [clinicDetails, setClinicDetails] = useState({
    name: "",
    address: "",
    contact_number: "",
    email: "",
    latitude: "",
    longitude: "",
  });
  const [clinics, setClinics] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false); // Track if we are in update mode
  const [currentClinicId, setCurrentClinicId] = useState(null); // ID of the clinic being updated
  const doctorId = getDoctorId();
  const token = localStorage.getItem("accessToken");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({
    name: "",
    address: "",
    contact_number: "",
    email: "",
  });
  const validateForm = () => {
    const newErrors = {};

    // Check for empty fields
    if (!clinicDetails.name) newErrors.name = "Clinic name is required.";
    if (!clinicDetails.address) newErrors.address = "Address is required.";
    if (!clinicDetails.contact_number) {
      newErrors.contact_number = "Contact number is required.";
    } else if (!/^\d+$/.test(clinicDetails.contact_number)) {
      newErrors.contact_number = "Contact number must be numeric.";
    }
    if (!clinicDetails.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(clinicDetails.email)) {
      newErrors.email = "Email format is invalid.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if there are no errors
  };

  const fetchClinics = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/clinics/${doctorId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setClinics(result.data);
    } catch (error) {
      console.error("Error fetching clinics:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClinics();
  }, [token]);

  const openClinicModal = (clinic = null) => {
    if (clinic) {
      setClinicDetails({
        name: clinic.name,
        address: clinic.address,
        contact_number: clinic.contact_number,
        email: clinic.email,
        latitude: clinic.latitude || "",
        longitude: clinic.longitude || "",
      });
      setCurrentClinicId(clinic.id);
      setIsUpdating(true);
    } else {
      setClinicDetails({
        name: "",
        address: "",
        contact_number: "",
        email: "",
        latitude: "",
        longitude: "",
      });
      setCurrentClinicId(null);
      setIsUpdating(false);
    }
    setShow(true);
  };

  // const handleClinicChange = (e) => {
  //   const { name, value } = e.target;
  //   setClinicDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  // };

  const handleClinicChange = (e) => {
    const { name, value } = e.target;

    // Clear the specific error if the user starts typing
    if (value) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }

    setClinicDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handleClinicSubmit = async () => {
    if (!validateForm()) {
      return; // Stop submission if validation fails
    }
    setLoading(true);
    setErrors({ name: "", address: "", contact_number: "", email: "" }); // Reset errors

    let hasError = false;

    // Validation
    if (!clinicDetails.name) {
      setErrors((prev) => ({ ...prev, name: "Clinic name is required." }));
      hasError = true;
    }
    if (!clinicDetails.address) {
      setErrors((prev) => ({ ...prev, address: "Address is required." }));
      hasError = true;
    }
    if (!clinicDetails.contact_number) {
      setErrors((prev) => ({
        ...prev,
        contact_number: "Contact number is required.",
      }));
      hasError = true;
    }
    if (!clinicDetails.email) {
      setErrors((prev) => ({ ...prev, email: "Email is required." }));
      hasError = true;
    }

    if (hasError) {
      setLoading(false);
      return; // Stop the submission if there's an error
    }

    try {
      const url = isUpdating
        ? `${process.env.REACT_APP_API_URL}/clinics/${doctorId}/${currentClinicId}/`
        : `${process.env.REACT_APP_API_URL}/clinics/`;
      const method = isUpdating ? "PUT" : "POST";

      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          doctor: doctorId,
          ...clinicDetails,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      ShowSucess(`Clinic successfully ${isUpdating ? "updated" : "created"}!`);
      setShow(false);
      fetchClinics();
    } catch (error) {
      console.error(
        `Error ${isUpdating ? "updating" : "creating"} clinic:`,
        error
      );
    } finally {
      setLoading(false);
    }
  };

  // const handleClinicSubmit = async () => {
  //   setLoading(true);
  //   try {
  //     const url = isUpdating
  //       ? `${process.env.REACT_APP_API_URL}/clinics/${doctorId}/${currentClinicId}/`
  //       : `${process.env.REACT_APP_API_URL}/clinics/`;
  //     const method = isUpdating ? "PUT" : "POST";

  //     const response = await fetch(url, {
  //       method,
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify({
  //         doctor: doctorId,
  //         ...clinicDetails,
  //       }),
  //     });

  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }

  //     ShowSucess(
  //       `Clinic successfully ${isUpdating ? "updated" : "created"}!`
  //     );
  //     setShow(false);
  //     fetchClinics();
  //   } catch (error) {
  //     console.error(
  //       `Error ${isUpdating ? "updating" : "creating"} clinic:`,
  //       error
  //     );
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/clinics/${doctorId}/${clinicToDelete}/`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      ShowSucess("Clinic successfully deleted!");
      setShowConfirm(false);
      fetchClinics();
    } catch (error) {
      console.error("Error deleting clinic:", error);
    } finally {
      setLoading(false);
    }
  };

  const confirmDelete = (id) => {
    setClinicToDelete(id);
    setShowConfirm(true);
  };

  const handleLocationSelect = (location) => {
    setClinicDetails((prevDetails) => ({
      ...prevDetails,
      latitude: location.lat.toFixed(6),
      longitude: location.lng.toFixed(6),
    }));
  };

  return (
    <>
      <Navbar
        navDark={true}
        manuClass="navigation-menu nav-left"
        containerClass="container"
      />
      {loading && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
          }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <section className="bg-dashboard">
        <div className="container-fluid">
          <div className="row">
            <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
            <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <Header
                title="Clinic List"
                onBackClick={() => navigate(-1)} // Go back to the previous page
                showBackButton={location.key !== "default"}
              />
              <Button
                variant="primary"
                onClick={() => openClinicModal()}
                style={{ marginTop: "20px" }}
              >
                Add Clinic
              </Button>
              <div className="row">
                <div className="col-12 mt-4 pt-2">
                  <div className="table-responsive shadow rounded">
                    <Table className="table table-center bg-white mb-0">
                      <thead>
                        <tr>
                          <th className="border-bottom p-3">Clinic Name</th>
                          <th className="border-bottom p-3">Address</th>
                          <th className="border-bottom p-3">Contact Number</th>
                          <th className="border-bottom p-3">Email</th>
                          <th className="border-bottom p-3">Latitude</th>
                          <th className="border-bottom p-3">Longitude</th>
                          <th className="border-bottom p-3">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {clinics.map((clinic) => (
                          <tr key={clinic.id}>
                            <td>{clinic.name}</td>
                            <td>{clinic.address}</td>
                            <td>{clinic.contact_number}</td>
                            <td>{clinic.email}</td>
                            <td>{clinic.latitude}</td>
                            <td>{clinic.longitude}</td>
                            <td>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-edit-${clinic.id}`}>
                                    Edit Clinic
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant="primary"
                                  onClick={() => openClinicModal(clinic)}
                                  className="btn-icon btn-pills btn-soft-primary my-1"
                                >
                                  <FaEdit />
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-delete-${clinic.id}`}>
                                    Delete Clinic
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant="danger"
                                  onClick={() => confirmDelete(clinic.id)}
                                  className="btn-icon btn-pills btn-soft-danger my-1"
                                >
                                  <FaTrashAlt />
                                </Button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
              <Modal
                show={show}
                onHide={() => {
                  setShow(false);
                  setErrors({
                    name: "",
                    address: "",
                    contact_number: "",
                    email: "",
                  }); // Clear errors
                }}
                centered
                backdrop="static" // Prevents closing the modal when clicking outside
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    {isUpdating ? "Update Clinic" : "Add Clinic"}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group controlId="formClinicName">
                          <Form.Label>Clinic Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={clinicDetails.name}
                            onChange={handleClinicChange}
                            placeholder="Enter clinic name"
                            isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group controlId="formClinicAddress">
                          <Form.Label>Address</Form.Label>
                          <Form.Control
                            type="text"
                            name="address"
                            value={clinicDetails.address}
                            onChange={handleClinicChange}
                            placeholder="Enter address"
                            isInvalid={!!errors.address}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.address}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <Form.Group controlId="formContactNumber">
                          <Form.Label>Contact Number</Form.Label>
                          <Form.Control
                            type="text"
                            name="contact_number"
                            value={clinicDetails.contact_number}
                            onChange={handleClinicChange}
                            placeholder="Enter contact number"
                            isInvalid={!!errors.contact_number}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.contact_number}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group controlId="formEmail">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            value={clinicDetails.email}
                            onChange={handleClinicChange}
                            placeholder="Enter email"
                            isInvalid={!!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <Form.Group controlId="formLatitude">
                          <Form.Label>Latitude</Form.Label>
                          <Form.Control
                            type="text"
                            name="latitude"
                            value={clinicDetails.latitude}
                            onChange={handleClinicChange}
                            placeholder="Enter latitude"
                            disabled
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group controlId="formLongitude">
                          <Form.Label>Longitude</Form.Label>
                          <Form.Control
                            type="text"
                            name="longitude"
                            value={clinicDetails.longitude}
                            onChange={handleClinicChange}
                            placeholder="Enter longitude"
                            disabled
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </Form>
                  <div className="mt-3">
                    <MapContainerClinic
                      onLocationSelect={handleLocationSelect}
                    />
                  </div>
                </Modal.Body>

                {/* <Modal.Body>
                  <Form>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group controlId="formClinicName">
                          <Form.Label>Clinic Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={clinicDetails.name}
                            onChange={handleClinicChange}
                            placeholder="Enter clinic name"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group controlId="formClinicAddress">
                          <Form.Label>Address</Form.Label>
                          <Form.Control
                            type="text"
                            name="address"
                            value={clinicDetails.address}
                            onChange={handleClinicChange}
                            placeholder="Enter address"
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <Form.Group controlId="formContactNumber">
                          <Form.Label>Contact Number</Form.Label>
                          <Form.Control
                            type="text"
                            name="contact_number"
                            value={clinicDetails.contact_number}
                            onChange={handleClinicChange}
                            placeholder="Enter contact number"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group controlId="formEmail">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            value={clinicDetails.email}
                            onChange={handleClinicChange}
                            placeholder="Enter email"
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <Form.Group controlId="formLatitude">
                          <Form.Label>Latitude</Form.Label>
                          <Form.Control
                            type="text"
                            name="latitude"
                            value={clinicDetails.latitude}
                            onChange={handleClinicChange}
                            placeholder="Enter latitude"
                            disabled
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group controlId="formLongitude">
                          <Form.Label>Longitude</Form.Label>
                          <Form.Control
                            type="text"
                            name="longitude"
                            value={clinicDetails.longitude}
                            onChange={handleClinicChange}
                            placeholder="Enter longitude"
                            disabled
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </Form>
                  <div className="mt-3">
                    <MapContainerClinic
                      onLocationSelect={handleLocationSelect}
                    />
                  </div>
                </Modal.Body> */}
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setShow(false);

                      setErrors({
                        name: "",
                        address: "",
                        contact_number: "",
                        email: "",
                      }); // Clear errors
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleClinicSubmit}
                    disabled={loading}
                  >
                    {isUpdating ? "Update Clinic" : "Add Clinic"}
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal
                show={showConfirm}
                onHide={() => setShowConfirm(false)}
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to delete this clinic?
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowConfirm(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="danger"
                    onClick={handleDelete}
                    disabled={loading}
                  >
                    {loading ? "Deleting..." : "Delete"}
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </section>
      <AdminFooter />
      <ScrollTop />
    </>
  );
}
