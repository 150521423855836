import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from "../../_helper/helper";

import { HEALTH_METRICS, PATIENT_DETAILS } from "./actionTypes";

import {
  getPatientDetailsSuccess,
  getPatientDetailsFail,
  healthMetricsListSuccess,
  healthMetricsListFail,
} from "./actions";

import {
  patientDetails,
  getHealthMetrics,
} from "../../_helper/services/patient";
function* onGetPatientDetails() {
  try {
    const response = yield call(patientDetails);
    yield put(getPatientDetailsSuccess(response));
  } catch (error) {
    yield put(getPatientDetailsFail(error.response));
  }
}
function* onGetHealthMetrics({ payload }) {
  try {
    const response = yield call(getHealthMetrics, payload);
    yield put(healthMetricsListSuccess(response));
  } catch (error) {
    yield put(healthMetricsListFail(error.response));
  }
}

function* CartSaga() {
  yield takeLatest(PATIENT_DETAILS, onGetPatientDetails);
  yield takeLatest(HEALTH_METRICS, onGetHealthMetrics);
}

export default CartSaga;
