// import React, { useEffect, useState } from "react";
// import { Link, useLocation, useNavigate } from 'react-router-dom';
// import Modal from 'react-bootstrap/Modal';
// import { Button, Form, Table } from 'react-bootstrap';
// import { FaTrashAlt, FaEdit } from 'react-icons/fa';
// import Navbar from "../../components/navbar";
// import Sidebar from "../../components/sidebar";
// import AdminFooter from "../../components/dashboard/adminFooter";
// import ScrollTop from "../../components/scrollTop";
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import Header from "../../components/CommonComponents/Header";

// export default function StaffManagement() {
//     const [show, setShow] = useState(false);
//     const [staffData, setStaffData] = useState([]); // Initialize as an empty array
//     const [staff, setStaff] = useState({ first_name: '', last_name: '', email: '', password: '', phone_number: '' });
//     const [isUpdating, setIsUpdating] = useState(false);
//     const [loading, setLoading] = useState(false);
//     const doctorId = localStorage.getItem("doctorId");
//     const token = localStorage.getItem('accessToken');
//     const [password, setPassword] = useState("");
//     const [passwordErrors, setPasswordErrors] = useState([]);

//     const navigate = useNavigate();
//     const location = useLocation();
//     const showBackButton = location.key !== 'default';
//     const validatePassword = (password) => {
//         const errors = [];
//         if (password.length < 8) errors.push('Password must be at least 8 characters long.');
//         if (!/[A-Z]/.test(password)) errors.push('Password must contain at least one uppercase letter.');
//         if (!/[0-9]/.test(password)) errors.push('Password must contain at least one number.');
//         if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) errors.push('Password must contain at least one special character.');
//         return errors;
//     };
//     const handlePasswordChange = (e) => {
//         const value = e.target.value;
//         setPassword(value);
//         setPasswordErrors(validatePassword(value));
//         setStaff({ ...staff, password: value });
//     };
//     const fetchStaff = async () => {
//         setLoading(true);
//         try {
//             const response = await fetch(`${process.env.REACT_APP_API_URL}/doctor/${doctorId}/staff/`, {
//                 method: 'GET',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${token}`
//                 }
//             });
//             if (!response.ok) {
//                 throw new Error('Network response was not ok');
//             }
//             const result = await response.json();
//             setStaffData(result || []); // Directly set result, assuming it's already an array
//         } catch (error) {
//             console.error('Error fetching staff:', error);
//             setStaffData([]); // Set staffData to an empty array on error
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchStaff();
//     }, [doctorId, token]);

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setStaff({ ...staff, [name]: value });
//     };
//     const handleSubmit = async () => {
//         setLoading(true);
//         try {
//             const response = await fetch(`${process.env.REACT_APP_API_URL}/doctor/create-staff/`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${token}`
//                 },
//                 body: JSON.stringify({ ...staff, assigned_doctor: doctorId })
//             });

//             if (!response.ok) {
//                 const errorData = await response.json();

//                 // Check if errorData is an object
//                 if (typeof errorData === 'object') {
//                     Object.keys(errorData).forEach((key) => {
//                         const messages = errorData[key];
//                         if (Array.isArray(messages)) {
//                             messages.forEach((message) => {
//                                 ShowError(`${key.charAt(0).toUpperCase() + key.slice(1)} Error: ${message}`);
//                             });
//                         } else {
//                             ShowError(`${key.charAt(0).toUpperCase() + key.slice(1)} Error: ${messages}`);
//                         }
//                     });
//                 } else {
//                     ShowError('An unexpected error occurred.');
//                 }

//                 throw new Error('Network response was not ok');
//             }

//             ShowSucess('Staff successfully added!');
//             setShow(false);
//             fetchStaff();
//         } catch (error) {
//             console.error('Error creating staff:', error);
//             ShowError('An unexpected error occurred. Please try again later.');
//         } finally {
//             setLoading(false);
//         }
//     };

//     // const handleSubmit = async () => {
//     //     setLoading(true);
//     //     try {
//     //         const response = await fetch(`${process.env.REACT_APP_API_URL}/doctor/create-staff/`, {
//     //             method: 'POST',
//     //             headers: {
//     //                 'Content-Type': 'application/json',
//     //                 'Authorization': `Bearer ${token}`
//     //             },
//     //             body: JSON.stringify({ ...staff, assigned_doctor: doctorId })
//     //         });
//     //         if (!response.ok) {
//     //             throw new Error('Network response was not ok');
//     //         }
//     //         ShowSucess('Staff successfully added!');
//     //         setShow(false);
//     //         fetchStaff();
//     //     } catch (error) {
//     //         console.error('Error creating staff:', error);
//     //     } finally {
//     //         setLoading(false);
//     //     }
//     // };

//     const handleUpdate = async () => {
//         setLoading(true);
//         try {
//             const response = await fetch(`${process.env.REACT_APP_API_URL}/doctor/update-staff/${staff.id}/`, {
//                 method: 'PUT',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${token}`
//                 },
//                 body: JSON.stringify(staff)
//             });
//             if (!response.ok) {
//                 throw new Error('Network response was not ok');
//             }
//             ShowSucess('Staff successfully updated!');
//             setShow(false);
//             fetchStaff();
//         } catch (error) {
//             console.error('Error updating staff:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleDelete = async (id) => {
//         setLoading(true);
//         try {
//             const response = await fetch(`${process.env.REACT_APP_API_URL}/doctor/staff/${id}/delete/`, {
//                 method: 'DELETE',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${token}`
//                 }
//             });
//             if (!response.ok) {
//                 throw new Error('Network response was not ok');
//             }
//             ShowSucess('Staff successfully deleted!');
//             fetchStaff();
//         } catch (error) {
//             console.error('Error deleting staff:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const openModal = (staffMember = { first_name: '', last_name: '', email: '', password: '', phone_number: '' }) => {
//         setStaff(staffMember);
//         setIsUpdating(!!staffMember.id);
//         setShow(true);
//     };

//     return (
//         <>
//             <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container" />
//             {loading && (
//                 <div style={{
//                     position: 'fixed',
//                     top: '50%',
//                     left: '50%',
//                     transform: 'translate(-50%, -50%)',
//                     zIndex: 9999
//                 }}>
//                     <div className="spinner-border" role="status">
//                         <span className="visually-hidden">Loading...</span>
//                     </div>
//                 </div>
//             )}
//             <section className="bg-dashboard">
//                 <div className="container-fluid">
//                     <div className="row">
//                         <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
//                         <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
//                             <Header title="Staff Management"
//                                 onBackClick={() => navigate(-1)}
//                                 showBackButton={showBackButton}
//                             />
//                             <Button variant="primary" onClick={() => openModal()} style={{ marginTop: '20px' }}>Add Staff</Button>
//                             <div className="row">
//                                 <div className="col-12 mt-4 pt-2">
//                                     <div className="table-responsive shadow rounded">
//                                         <Table className="table table-center bg-white mb-0">
//                                             <thead>
//                                                 <tr>
//                                                     <th className="border-bottom p-3">First Name</th>
//                                                     <th className="border-bottom p-3">Last Name</th>
//                                                     <th className="border-bottom p-3">Email</th>
//                                                     <th className="border-bottom p-3">Phone Number</th>
//                                                     <th className="text-end border-bottom p-3" style={{ minWidth: '150px' }}></th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 {staffData.length > 0 ? staffData.map((item) => (
//                                                     <tr key={item.id}>
//                                                         <td className="p-3">{item.first_name}</td>
//                                                         <td className="p-3">{item.last_name}</td>
//                                                         <td className="p-3">{item.email}</td>
//                                                         <td className="p-3">{item.phone_number || "N/A"}</td>
//                                                         <td className="text-end p-3">
//                                                             <Button variant="outline-primary" onClick={() => openModal(item)}><FaEdit /></Button>{' '}
//                                                             <Button variant="outline-danger" onClick={() => handleDelete(item.id)}><FaTrashAlt /></Button>
//                                                         </td>
//                                                     </tr>
//                                                 )) : (
//                                                     <tr>
//                                                         <td colSpan="5" className="text-center">No staff data available</td>
//                                                     </tr>
//                                                 )}
//                                             </tbody>
//                                         </Table>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <ToastContainer />
//             </section>
//             <Modal
//                 show={show}
//                 onHide={() => {
//                     setShow(false);
//                     setStaff({ first_name: '', last_name: '', email: '', password: '', phone_number: '' });
//                     setIsUpdating(false);
//                 }}
//                 centered
//                 size="lg"
//                 backdrop="static"
//                 dialogClassName="modal-90w"
//             >
//                 <Modal.Header closeButton>
//                     <Modal.Title>{isUpdating ? 'Update Staff' : 'Add Staff'}</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <Form>
//                         <Form.Group controlId="formFirstName">
//                             <Form.Label>First Name</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 name="first_name"
//                                 value={staff.first_name}
//                                 onChange={handleChange}
//                                 placeholder="First Name"
//                             />
//                         </Form.Group>
//                         <Form.Group controlId="formLastName">
//                             <Form.Label>Last Name</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 name="last_name"
//                                 value={staff.last_name}
//                                 onChange={handleChange}
//                                 placeholder="Last Name"
//                             />
//                         </Form.Group>
//                         <Form.Group controlId="formEmail">
//                             <Form.Label>Email</Form.Label>
//                             <Form.Control
//                                 type="email"
//                                 name="email"
//                                 value={staff.email}
//                                 onChange={handleChange}
//                                 placeholder="Email"
//                             />
//                         </Form.Group>
//                         <Form.Group controlId="formPassword">
//                             <Form.Label>Password</Form.Label>
//                             <Form.Control
//                                 type="password"
//                                 name="password"
//                                 value={staff.password}
//                                 onChange={handlePasswordChange}
//                                 placeholder="Password"
//                             />
//                                <ul className="mt-2">
//                                                     {passwordErrors.map((error, index) => (
//                                                         <li key={index} className="text-danger">{error}</li>
//                                                     ))}
//                                                 </ul>
//                         </Form.Group>
//                         <Form.Group controlId="formPhoneNumber">
//                             <Form.Label>Phone Number</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 name="phone_number"
//                                 value={staff.phone_number}
//                                 onChange={handleChange}
//                                 placeholder="Phone Number"
//                             />
//                         </Form.Group>
//                     </Form>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button
//                         variant="secondary"
//                         onClick={() => {
//                             setShow(false);
//                             setStaff({ first_name: '', last_name: '', email: '', password: '', phone_number: '' });
//                             setIsUpdating(false);
//                         }}
//                     >
//                         Close
//                     </Button>
//                     {isUpdating ? (
//                         <Button variant="primary" onClick={handleUpdate}>
//                             Update
//                         </Button>
//                     ) : (
//                         <Button variant="primary" onClick={handleSubmit}>
//                             Submit
//                         </Button>
//                     )}
//                 </Modal.Footer>
//             </Modal>
//             <ScrollTop />
//             <AdminFooter />
//         </>
//     );
// }

import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Button, Form, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaTrashAlt, FaEdit, FaTasks, FaMinusCircle } from "react-icons/fa";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import AdminFooter from "../../components/dashboard/adminFooter";
import ScrollTop from "../../components/scrollTop";
import { remove } from "../../_helper/api_helper";
import index from "./index.css";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import Header from "../../components/CommonComponents/Header";
import { FaPause, FaPlay } from "react-icons/fa6";
import { ShowSucess, ShowError, getDoctorId } from "../../_helper/helper";

export default function StaffManagement() {
  const [show, setShow] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false); // New state for delete confirmation
  const [showAssignTask, setShowAssignTask] = useState(false);
  const [taskDataArr, setTaskDataArr] = useState([]);
  const [selectedTask, setSelectedTask] = useState([]);
  const [taskObj, setTaskObj] = useState(null);
  const [staffData, setStaffData] = useState([]);
  const [staff, setStaff] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    phone_number: "",
  });
  const [isUpdating, setIsUpdating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [staffToDelete, setStaffToDelete] = useState(null); // New state for staff ID to delete
  const token = localStorage.getItem("accessToken");
  const doctorId = getDoctorId();
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [password, setPassword] = useState("");
  const [passwordErrors, setPasswordErrors] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const showBackButton = location.key !== "default";

  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8)
      errors.push("Password must be at least 8 characters long.");
    if (!/[A-Z]/.test(password))
      errors.push("Password must contain at least one uppercase letter.");
    if (!/[0-9]/.test(password))
      errors.push("Password must contain at least one number.");
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password))
      errors.push("Password must contain at least one special character.");
    return errors;
  };
  const valueRender = (element) => {
    console.log(element);
    if (!element.props.value) {
      return element;
    }
    // else if (!departObj) {
    //   return element;
    // }
    // const newElement = { ...element };
    // if (departObj?.Dept_Code && departObj?.DepartmentName) {
    //   newElement.props = {
    //     ...element.props,
    //     value: departObj?.Dept_Code + "-" + departObj?.DepartmentName,
    //   };
    // }

    return <span style={{ width: "100%" }}>{element}</span>;
  };
  const itemRender = (li, itemProps) => {
    const itemChildren = <span>{itemProps?.dataItem?.codename}</span>;
    return React.cloneElement(li, li.props, itemChildren);
  };
  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setPasswordErrors(validatePassword(value));
    setStaff({ ...staff, password: value });
  };

  const fetchStaff = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/doctor/${doctorId}/staff/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        const errorDetails = await response.json();
        ShowError(errorDetails?.detail || "Network response was not ok");

        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setStaffData(result || []);
    } catch (error) {
      console.error("Error fetching staff:", error);
      setStaffData([]);
    } finally {
      setLoading(false);
    }
  };
  const removeAssignedTasks = async (selectedTasks, staffId, isRemoved) => {
    setLoading(true);
    let permissionIdsArr = [];
    if (selectedStaff && selectedStaff?.length > 0) {
      selectedTasks?.forEach((task) => {
        const filteredPerIdsArr = selectedStaff?.assigned_permissions.find(
          (permission) => permission?.id !== task?.id
        )?.id;
        permissionIdsArr?.push(filteredPerIdsArr);
      });
    } else {
      selectedTasks &&
        selectedTasks?.length > 0 &&
        selectedTasks.forEach((task) => {
          permissionIdsArr?.push(task?.id);
        });
    }

    //  this is url for asign permission
    const payload = {
      staff_id: selectedStaff?.id || staffId,
      permission_ids: permissionIdsArr,
    };
    try {
      const resp = await remove(
        `${process.env.REACT_APP_API_URL}/assign-permissions/`,
        payload
      );
      if (resp) {
        if (!isRemoved) {
          // ShowSucess("Updated Assigned Task successfully!");
        } else {
          ShowSucess("Removed Assigned Task successfully!");
          handleCancelAssignModal();
          fetchStaff();
        }

        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const getTaskData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/permissions/list`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resdata = await response.json();
      if (resdata && resdata?.data?.length > 0) {
        setTaskDataArr(resdata?.data);
        setSelectedTask(resdata?.data);
      }
      if (!response.ok) {
        const errorData = await response.json();

        if (typeof errorData === "object") {
          Object.keys(errorData).forEach((key) => {
            const messages = errorData[key];
            if (Array.isArray(messages)) {
              messages.forEach((message) => {
                ShowError(
                  `${
                    key.charAt(0).toUpperCase() + key.slice(1)
                  } Error: ${message}`
                );
              });
            } else {
              ShowError(
                `${
                  key.charAt(0).toUpperCase() + key.slice(1)
                } Error: ${messages}`
              );
            }
          });
        } else {
          ShowError("An unexpected error occurred.");
        }

        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error creating staff:", error);
      // ShowError("An unexpected error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (doctorId) {
      fetchStaff();
    }
  }, [doctorId]);
  useEffect(() => {
    getTaskData();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setStaff({ ...staff, [name]: value });
  };
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/doctor/create-staff/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ ...staff, assigned_doctor: doctorId }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();

        if (typeof errorData === "object") {
          Object.keys(errorData).forEach((key) => {
            const messages = errorData[key];
            if (Array.isArray(messages)) {
              messages.forEach((message) => {
                ShowError(
                  `${
                    key.charAt(0).toUpperCase() + key.slice(1)
                  } Error: ${message}`
                );
              });
            } else {
              ShowError(
                `${
                  key.charAt(0).toUpperCase() + key.slice(1)
                } Error: ${messages}`
              );
            }
          });
        } else {
          ShowError("An unexpected error occurred.");
        }

        throw new Error("Network response was not ok");
      }

      ShowSucess("Staff successfully added!");
      setShow(false);
      fetchStaff();
    } catch (error) {
      console.error("Error creating staff:", error);
      ShowError("An unexpected error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  const handleCancelAssignModal = () => {
    setShowAssignTask(false);
    setTaskObj(null);
  };
  const getPermissionForTask = async () => {
    setLoading(true);
    let permissionIdsArr = [];
    taskObj?.forEach((d) => permissionIdsArr?.push(d?.id));
    //  this is url for asign permission
    const payload = {
      staff_id: selectedStaff?.id,
      permission_ids: permissionIdsArr,
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/assign-permissions/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();

        if (typeof errorData === "object") {
          Object.keys(errorData).forEach((key) => {
            const messages = errorData[key];
            if (Array.isArray(messages)) {
              messages.forEach((message) => {
                ShowError(
                  `${
                    key.charAt(0).toUpperCase() + key.slice(1)
                  } Error: ${message}`
                );
              });
            } else {
              ShowError(
                `${
                  key.charAt(0).toUpperCase() + key.slice(1)
                } Error: ${messages}`
              );
            }
          });
        } else {
          ShowError("An unexpected error occurred.");
        }

        throw new Error("Network response was not ok");
      }

      ShowSucess("Task Assigned successfully!");
      handleCancelAssignModal();
      fetchStaff();
    } catch (error) {
      console.error("Error while creating assined task for staff:", error);
      ShowError("An unexpected error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const filterChange = (event) => {
    const { value } = event?.filter || {};
    const filteredTaskArr = taskDataArr?.filter((d) =>
      d?.codename?.toLowerCase().includes(value?.toLowerCase())
    );
    setSelectedTask(filteredTaskArr);
  };
  const handleUpdate = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/doctor/update-staff/${staff.id}/`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(staff),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      ShowSucess("Staff successfully updated!");
      setShow(false);
      fetchStaff();
    } catch (error) {
      console.error("Error updating staff:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!staffToDelete) return;

    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/doctor/staff/${staffToDelete}/delete/`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      ShowSucess("Staff successfully deleted!");
      setShowDeleteConfirm(false); // Hide the confirmation modal
      fetchStaff();
    } catch (error) {
      console.error("Error deleting staff:", error);
    } finally {
      setLoading(false);
    }
  };

  const openModal = (
    staffMember = {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      phone_number: "",
    }
  ) => {
    setStaff(staffMember);
    setIsUpdating(!!staffMember.id);
    setShow(true);
  };

  const openDeleteConfirm = (id) => {
    setStaffToDelete(id);
    setShowDeleteConfirm(true);
  };
  const [showToggleConfirm, setShowToggleConfirm] = useState(false);
  const [staffToToggle, setStaffToToggle] = useState({
    id: null,
    isActive: false,
  });

  const handleToggleStatus = (staffId, isActive) => {
    setStaffToToggle({ id: staffId, isActive });
    setShowToggleConfirm(true);
  };

  const handleConfirmToggleStatus = async (action) => {
    setLoading(true);
    try {
      const method = action === "enable" ? "PUT" : "PUT";
      const payload = { is_active: action === "enable" };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/staff/${staffToToggle.id}/disable/`,
        {
          method,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      ShowSucess(
        `Staff successfully ${action === "enable" ? "enabled" : "disabled"}`
      );
      fetchStaff(); // Refresh staff data
    } catch (error) {
      console.error("Error toggling staff status:", error);
      ShowError("Failed to toggle staff status");
    } finally {
      setLoading(false);
      setShowToggleConfirm(false);
    }
  };

  return (
    <>
      <Navbar
        navDark={true}
        manuClass="navigation-menu nav-left"
        containerClass="container"
      />
      {loading && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
          }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <section className="bg-dashboard">
        <div className="container-fluid">
          <div className="row">
            <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
            <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <Header
                title="Staff Management"
                onBackClick={() => navigate(-1)}
                showBackButton={showBackButton}
              />
              <Button
                variant="primary"
                onClick={() => openModal()}
                style={{ marginTop: "20px" }}
              >
                Add Staff
              </Button>
              <div className="row">
                <div className="col-12 mt-4 pt-2">
                  <div className="table-responsive shadow rounded">
                    <Table className="table table-center bg-white mb-0">
                      <thead>
                        <tr>
                          <th className="border-bottom p-3">First Name</th>
                          <th className="border-bottom p-3">Last Name</th>
                          <th className="border-bottom p-3">Email</th>
                          <th className="border-bottom p-3">Phone Number</th>
                          <th className="border-bottom p-3">Assigned Tasks</th>

                          <th
                            className="text-end border-bottom p-3"
                            style={{ minWidth: "150px" }}
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        {staffData.length > 0 ? (
                          staffData.map((item) => (
                            <tr key={item.id}>
                              <td className="p-3">{item.first_name}</td>
                              <td className="p-3">{item.last_name}</td>
                              <td className="p-3">{item.email}</td>
                              <td className="p-3">
                                {item.phone_number || "N/A"}
                              </td>
                              <td className="p-3">
                                {item?.assigned_permissions &&
                                item?.assigned_permissions?.length > 0
                                  ? "Yes"
                                  : "No"}
                              </td>

                              <td className="text-end p-3">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`edit-tooltip-${item.id}`}>
                                      Update
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    variant="outline-primary"
                                    onClick={() => openModal(item)}
                                  >
                                    <FaEdit />
                                  </Button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`delete-tooltip-${item.id}`}>
                                      Delete
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    style={{ marginLeft: "3px" }}
                                    variant="outline-danger"
                                    onClick={() => openDeleteConfirm(item.id)}
                                  >
                                    <FaTrashAlt />
                                  </Button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`status-tooltip-${item.id}`}>
                                      {item.is_active
                                        ? "DISABLE Staff"
                                        : "ENABLE Staff"}
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    variant={
                                      item.is_active
                                        ? "outline-success"
                                        : "outline-warning"
                                    }
                                    onClick={() =>
                                      handleToggleStatus(
                                        item.id,
                                        item.is_active
                                      )
                                    }
                                  >
                                    {item.is_active ? <FaPlay /> : <FaPause />}
                                  </Button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="assign-task-tooltip">
                                      {item?.assigned_permissions &&
                                      item?.assigned_permissions?.length > 0
                                        ? "Update Assigned Tasks"
                                        : " Assign a Task"}
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    variant="outline-secondary"
                                    style={{ marginLeft: "3px" }}
                                    onClick={() => {
                                      setShowAssignTask(true);
                                      setTaskObj(item?.assigned_permissions);
                                      setSelectedStaff(item);
                                    }}
                                  >
                                    <FaTasks />
                                  </Button>
                                </OverlayTrigger>
                                {item?.assigned_permissions &&
                                  item?.assigned_permissions?.length > 0 && (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip
                                          id={`delete-tooltip-${item.id}`}
                                        >
                                          Remove All Assigned Task
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        style={{ marginLeft: "3px" }}
                                        variant="outline-danger"
                                        onClick={() => {
                                          removeAssignedTasks(
                                            item?.assigned_permissions,
                                            item?.id,
                                            true
                                          );
                                        }}
                                      >
                                        <FaMinusCircle />
                                      </Button>
                                    </OverlayTrigger>
                                  )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              No staff data available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <ToastContainer /> */}
      </section>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          setStaff({
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            phone_number: "",
          });
          setIsUpdating(false);
        }}
        centered
        size="lg"
        backdrop="static"
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title>{isUpdating ? "Update Staff" : "Add Staff"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="first_name"
                value={staff.first_name}
                onChange={handleChange}
                placeholder="First Name"
              />
            </Form.Group>
            <Form.Group controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="last_name"
                value={staff.last_name}
                onChange={handleChange}
                placeholder="Last Name"
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={staff.email}
                onChange={handleChange}
                placeholder="Email"
              />
            </Form.Group>
            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={staff.password}
                onChange={handlePasswordChange}
                placeholder="Password"
              />
              <ul className="mt-2">
                {passwordErrors.map((error, index) => (
                  <li key={index} className="text-danger">
                    {error}
                  </li>
                ))}
              </ul>
            </Form.Group>
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phone_number"
                value={staff.phone_number}
                onChange={handleChange}
                placeholder="Phone Number"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShow(false);
              setStaff({
                first_name: "",
                last_name: "",
                email: "",
                password: "",
                phone_number: "",
              });
              setIsUpdating(false);
            }}
          >
            Close
          </Button>
          {isUpdating ? (
            <Button variant="primary" onClick={handleUpdate}>
              Update
            </Button>
          ) : (
            <Button variant="primary" onClick={handleSubmit}>
              Submit
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal
        show={showToggleConfirm}
        onHide={() => setShowToggleConfirm(false)}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to{" "}
          {staffToToggle.isActive ? "disable" : "enable"} this staff member?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowToggleConfirm(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() =>
              handleConfirmToggleStatus(
                staffToToggle.isActive ? "disable" : "enable"
              )
            }
          >
            {staffToToggle.isActive ? "Disable" : "Enable"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAssignTask}
        onHide={() => handleCancelAssignModal()}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedStaff?.assigned_permissions &&
            selectedStaff?.assigned_permissions?.length > 0
              ? "Update Tasks"
              : " Assign Tasks"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MultiSelect
            data={selectedTask}
            // itemRender={itemRender}
            // valueRender={valueRender}
            textField={"codename"}
            value={taskObj}
            filterable={true}
            onFilterChange={filterChange}
            placeholder="Select Tasks"
            onChange={(e) => {
              // const filteredDepart = e.value && e.value.length > 0 && e.value.filter((d) => d?.Dept_Code.toLowerCase().includes(d.DepartmentName.toLowerCase()) ||
              //   d.DepartmentName.toLowerCase().includes(d.DepartmentName.toLowerCase()));
              // if (filteredDepart && filteredDepart?.length > 0) {
              //   setDepartment(filteredDepart);
              // }
              // else
              if (e.value && e.value.length > 0) {
                setTaskObj(e.value);
              } else {
                setSelectedTask(taskDataArr);
                setTaskObj(null);
              }
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={async () => {
              if (
                selectedStaff &&
                selectedStaff?.assigned_permissions &&
                selectedStaff?.assigned_permissions?.length > 0
              ) {
                await removeAssignedTasks(selectedStaff?.assigned_permissions);
              }
              getPermissionForTask();
            }}
          >
            {selectedStaff?.assigned_permissions &&
            selectedStaff?.assigned_permissions?.length > 0
              ? "Update"
              : " Submit"}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Delete Confirmation Modal */}
      <Modal
        show={showDeleteConfirm}
        onHide={() => setShowDeleteConfirm(false)}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this staff member?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteConfirm(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <ScrollTop />
      <AdminFooter />
    </>
  );
}
