import { toast, ToastContainer } from "react-toastify";

export const getUserData = () => {
  const userData = localStorage.getItem("userData");
  if (userData) {
    return JSON.parse(userData)?.user;
  }

  return null;
};
export const getDoctorId = () => {
  const userData = localStorage.getItem("userData");
  if (userData) {
    const parsedUser = JSON.parse(userData)?.user;
    const { role, assigned_doctor_id, doctor_id } = parsedUser;
    const doctorId = role === "staff" ? assigned_doctor_id : doctor_id;
    return doctorId;
  }

  return null;
};
export const ShowSucess = (message) => {
  if (message === undefined) {
    return;
  }
  toast(`${message}`, {
    theme: "light",
    position: "top-right",
  });
  // <ToastContainer />;
};
export const ShowError = (message) => {
  if (message === undefined) {
    return;
  }
  // <ToastContainer />;
  toast.error(`${message}`, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    style: {
      width: "auto",
      height: "auto",
    },
  });
};
