import React, { useEffect, useState, useMemo } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClearIcon from "@mui/icons-material/Clear";
import ReplayIcon from "@mui/icons-material/Replay";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./index.css";
// import PrescriptionModal from "./PrescriptionModal"; // Import the modal component
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Container,
  Card,
  Row,
  Col,
  Button,
  Badge,
  OverlayTrigger,
  Tooltip,
  Table,
} from "react-bootstrap";
import {
  TextField,
  Autocomplete,
  MenuItem,
  Box,
  Typography,
  Grid,
  CardContent,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import Header from "../../components/CommonComponents/Header";
import jsPDF from "jspdf"; // PDF library
import "jspdf-autotable"; // Plugin for tables in PDF
// import Spinner from "react-bootstrap/Spinner"; // Import Spinner if needed
import { ShowSucess, ShowError } from "../../_helper/helper";
import {
  convertTo12HourFormat,
  loadingPanel,
} from "../../components/CommonComponents/CommonFunction";
import { get, post } from "../../_helper/api_helper";
import { put } from "../../api_helper";
const baseURL = process.env.REACT_APP_API_URL;

const AddEditReport = () => {
  // if (!show) return null;
  const location = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    lab_centers: [],
    patient_name: "",
    patient_email: "",
    patient_contact: "",
    age: "",
    gender: "",
    sample_collected_on: dayjs(),
    sample_received_on: dayjs(),
    sample_generated_on: dayjs(),
    test_date: dayjs(),
    test_time: "",

    // test_description: "",
  });
  const [testDetailsArr, setTestDetailsArr] = useState([
    {
      test_name: [],
      test_price: "",
      remarks: "",
      test_description: "",
      conducted_at: dayjs(),
      parameters: [],
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const showBackButton = location.key !== "default";
  const id = location?.pathname?.split("/").pop();
  const { item, testNameList, labCenterOptions } = location?.state || {};
  const health_record_no = item?.health_record_number;

  const resetTestDetailsEntity = (index) => {
    setTestDetailsArr((prevTestDetailsEntity) => {
      return prevTestDetailsEntity.map(
        (testDetailsEntity, i) =>
          i === index
            ? {
                test_name: [],
                result: "",
                test_price: "",
                remarks: "",
                test_description: "",
                conducted_at: dayjs(),
              }
            : // Reset specific fields
              testDetailsEntity // Keep other experiences unchanged
      );
    });
  };

  const handleReset = () => {
    setFormData({
      lab_centers: [],
      patient_name: "",
      patient_email: "",
      patient_contact: "",
      age: "",
      gender: "",
      sample_collected_on: dayjs(),
      sample_received_on: dayjs(),
      sample_generated_on: dayjs(),
      test_date: dayjs(),
      test_time: "",
    });
    setTestDetailsArr([
      {
        test_name: [],
        result: "",
        test_price: "",
        remarks: "",
        test_description: "",
        conducted_at: dayjs(),
      },
    ]);
  };
  const allKeysHaveValues = () => {
    const arr = testDetailsArr || [];
    if (arr.length === 0) return true; // Return true for empty array

    // Get all keys from the first object
    const keys = Object.keys(arr[0]);

    return arr.every((obj) =>
      keys.every((key) => {
        // Always true for "result" and "remarks"
        if (key === "test_name" && obj[key] && obj[key]?.length === 0) {
          return false;
        } else {
          return true;
        }
      })
    );
  };

  const handleAddTestDetailsArr = () => {
    setTestDetailsArr([
      ...testDetailsArr,
      {
        test_name: [],
        result: "",
        test_price: "",
        remarks: "",
        test_description: "",
        conducted_at: dayjs(),
      },
    ]);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Enter" || e.key === "Tab") {
      e.preventDefault(); // Prevent default behavior of Enter or Tab
      const isAllFieldsFilledTestDetailsArr = Object.values(
        testDetailsArr[index]
      ).every((value) => value.trim() !== "");
      if (isAllFieldsFilledTestDetailsArr) {
        handleAddTestDetailsArr();
      }
    }
  };

  const handleInputChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };
  const handleTestDetailsChange = (index, field, value, paramIdx) => {
    const updatedTestDetailsArr = [...testDetailsArr];
    if (field === "test_name") {
      if (value) {
        updatedTestDetailsArr[index]["test_price"] = testNameList?.find(
          (d) => d?.id === value?.id
        )?.price;
        updatedTestDetailsArr[index]["parameters"] = testNameList?.find(
          (d) => d?.test_name === value?.label
        )?.parameters;
      } else {
        updatedTestDetailsArr[index]["test_price"] = "";
        updatedTestDetailsArr[index]["parameters"] = [];
      }
    }
    if (
      field === "parameters" &&
      Array.isArray(updatedTestDetailsArr[index]["parameters"])
    ) {
      updatedTestDetailsArr[index]["parameters"][paramIdx]["value"] = value;
    } else {
      updatedTestDetailsArr[index][field] = value;
    }
    setTestDetailsArr(updatedTestDetailsArr);
    // setIsEdited(true);
  };
  const handleRemoveTestDetailsArr = (index) => {
    const updatedTestDetailsArr = testDetailsArr.filter((_, i) => i !== index);
    setTestDetailsArr(updatedTestDetailsArr);
    // setIsEdited(true);
  };

  const handleSubmit = async () => {
    // const areAllObjectsFilled = () => {
    //   if (Object.keys(formData)?.length > 0) {
    //     return Object.keys(formData).every((key) => {
    //       const value = formData[key];
    //       if (typeof value === "number" && value) return true;
    //       if (
    //         typeof value === "object" &&
    //         value &&
    //         Object.values(value)?.length > 0
    //       )
    //         return true;
    //       // Check if the value is neither undefined, null, nor an empty string
    //       return (
    //         typeof value === "string" &&
    //         value !== undefined &&
    //         value !== null &&
    //         value !== ""
    //       );
    //     });
    //   } else {
    //     return false;
    //   }
    // };

    const requiredKeys = ["age", "gender", "patient_name", "patient_email"];
    const errorMessages = {
      age: "Patient Age is required!",
      gender: " Patient Gender is required!",
      patient_name: "Patient Name is required!",
      patient_email: "Patient Email is required!",
    };

    let validationError = "";

    // Check if all required keys have non-empty values and set specific error messages
    const areAllRequiredFieldsFilled = requiredKeys.every((key) => {
      const isValid = formData[key]?.toString().trim() !== "";

      if (!isValid && !validationError) {
        validationError = errorMessages[key];
      }

      return isValid;
    });

    if (!areAllRequiredFieldsFilled) {
      ShowError(validationError);
      return;
    }
    if (!allKeysHaveValues()) {
      ShowError("Test Name is Required!");
      return;
    }
    // const isValid = testDetailsArr.every((obj) =>
    //   Object.entries(obj).every(([key, value]) => {
    //     if (key !== "test_name") return true; // Skip validation for these keys

    //     if (typeof value === "number" && value) return true;

    //     if (
    //       typeof value === "object" &&
    //       value &&
    //       Object.values(value)?.length > 0
    //     )
    //       return true;

    //     return (
    //       typeof value === "string" &&
    //       value !== undefined &&
    //       value !== null &&
    //       value.trim() !== ""
    //     );
    //   })
    // );

    // const { lab_centers, ...rest } = formData || {};
    const copyFormData = { ...formData };

    copyFormData["test_date"] = formData?.test_date
      ? dayjs(formData?.test_date).format("YYYYY-MM-DD")
      : "";

    const { lab_centers, test_time, test_date, ...rest } = copyFormData;
    const payload = {
      ...rest,
      lab_center: lab_centers?.id,
      test_date: test_date.replace("+0530", ""),
      test_time: test_time
        ? dayjs(test_time, "HH:mm")?.format("HH:mm:ss")
        : "00:00:00",
      test_details: testDetailsArr.map((testDetail) => ({
        ...testDetail,
        test_name: testDetail?.test_name?.id,
      })),
    };

    if (health_record_no) {
      payload.health_record_number = health_record_no;
      payload.status = "pending";
    }
    // if (areAllRequiredFieldsFilled && allKeysHaveValues()) {
    try {
      setLoading(true);
      let response;
      let updateResponse;
      if (health_record_no) {
        updateResponse = await put(
          `${process.env.REACT_APP_API_URL}/lab-tests/${id}/`,
          payload
        );
      } else {
        response = await post(
          `${process.env.REACT_APP_API_URL}/lab-tests/`,
          payload
        );
      }

      // if (!response.ok) throw new Error("Network response was not ok");
      if (response?.id) {
        ShowSucess(`Created successfully`, {
          theme: "light",
          position: "top-right",
        });
        setLoading(false);
        handleReset();
        navigate("/lab-test-report");
      } else if (updateResponse?.id) {
        ShowSucess(`Updated successfully`, {
          theme: "light",
          position: "top-right",
        });
        setLoading(false);
        handleReset();
        navigate("/lab-test-report");
      } else {
        ShowError("Something Went Wrong!");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error creating Lab Test Report:", error);
      ShowError("Error creating Lab Test Report");
      setLoading(false);
    } finally {
      setLoading(false); // Reset loading action
    }
    // } else {
    //   ShowError("Please fill each fields !");
    //   return;
    // }
  };
  const THStyle = {
    fontWeight: "600",
    fontSize: "17px",
    padding: "5px 0px",
    width: "25%",
  };
  useEffect(() => {
    if (id !== "new" && item && labCenterOptions) {
      const selectedLabCenters = labCenterOptions
        .map((d) => ({ label: d?.name, id: d?.id }))
        ?.find((option) => {
          const labCenter = item?.lab_center; // Extract `lab_center` for clarity

          if (!labCenter) return false; // If `lab_center` is null/undefined, exclude the option

          // If it's a single value, compare directly
          return option.id === Number(labCenter);
        });
      setFormData({
        lab_centers: selectedLabCenters,
        patient_name: item?.patient_name,
        patient_email: item?.patient_email,
        patient_contact: item?.patient_contact,
        test_date: dayjs(item?.test_date),
        test_time: item?.test_time,
        age: item?.age,
        gender: item?.gender,
        sample_collected_on: dayjs(item?.sample_collected_on),
        sample_received_on: dayjs(item?.sample_received_on),
        sample_generated_on: dayjs(item?.sample_generated_on),
        // test_description: item?.description,
      });

      setTestDetailsArr(
        item?.test_details.map(
          ({
            id,
            test_name,
            conducted_at,
            sample_collected_on,
            sample_received_on,
            sample_generated_on,
            parameters,
            ...rest
          }) => ({
            test_name: testNameList
              .map((item) => ({
                label: item?.test_name,
                id: item?.id,
              }))
              .find((d) => d?.id === Number(test_name)),
            conducted_at: dayjs(conducted_at),
            parameters: testNameList?.find((d) => d?.id === Number(test_name))
              ?.parameters,

            ...rest,
          })
        ) // Remove `id` and keep the rest
      );
    }
  }, [id, item, labCenterOptions]);
  return (
    <>
      <Navbar
        navDark={true}
        manuClass="navigation-menu nav-left"
        containerClass="container-fluid"
      />
      {/* <Container> */}
      <section className="bg-dashboard">
        <div className="container-fluid">
          <div className="row">
            <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
            <div className="col-xl-9 col-lg-8 col-md-7 mt-4 mt-sm-0 pt-sm-0">
              <Header
                title="Lab Test Report" // Replace with the title you want
                onBackClick={() => navigate(-1)}
                showBackButton={showBackButton}
              />
              <Card>
                {loading && loadingPanel()}

                <Card.Body>
                  <>
                    <Row>
                      {" "}
                      <Col xs={8}>
                        {" "}
                        <Typography variant="h4" gutterBottom>
                          {id !== "new" ? "Update" : "Add"} Lab Test
                        </Typography>
                      </Col>
                    </Row>

                    <Grid container spacing={1}>
                      {/* Lab Centers */}
                      <Grid item xs={12} sm={4}>
                        <Autocomplete
                          options={labCenterOptions.map((item) => ({
                            label: item?.name,
                            id: item?.id,
                          }))}
                          // getOptionLabel={(option) => option.label}
                          value={formData?.lab_centers || []}
                          onChange={(event, value) => {
                            handleInputChange("lab_centers", value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Lab Centers"
                              margin="dense"
                              size="small"
                              placeholder="Select Lab Centers"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                      {/* Patient Name */}
                      <Grid item xs={12} sm={4}>
                        <TextField
                          label="Patient Name"
                          size="small"
                          margin="dense"
                          value={formData.patient_name}
                          onChange={(e) =>
                            handleInputChange("patient_name", e.target.value)
                          }
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          label="Patient Age"
                          size="small"
                          margin="dense"
                          value={formData.age}
                          onChange={(e) =>
                            handleInputChange("age", e.target.value)
                          }
                          // error={!formData?.age}
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          label="Patient Gender"
                          select
                          size="small"
                          margin="dense"
                          value={formData.gender}
                          onChange={(e) =>
                            handleInputChange("gender", e.target.value)
                          }
                          required
                          fullWidth
                        >
                          {["Male", "Female", "Other"].map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      {/* Patient Email */}
                      <Grid item xs={12} sm={4}>
                        <TextField
                          label="Patient Email"
                          type="email"
                          size="small"
                          margin="dense"
                          value={formData.patient_email}
                          onChange={(e) =>
                            handleInputChange("patient_email", e.target.value)
                          }
                          required
                          fullWidth
                        />
                      </Grid>
                      {/* Patient Contact */}
                      <Grid item xs={12} sm={4}>
                        <TextField
                          label="Patient Contact"
                          type="tel"
                          size="small"
                          margin="dense"
                          value={formData.patient_contact}
                          onChange={(e) =>
                            handleInputChange("patient_contact", e.target.value)
                          }
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        style={{ marginTop: "7px" }}
                        className="custom-date"
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Sample Collected On"
                            value={formData?.sample_collected_on}
                            onChange={(selectedDate) => {
                              handleInputChange(
                                "sample_collected_on",
                                selectedDate
                              );
                            }}
                            slotProps={{
                              textField: { error: false },
                            }}
                            className="dateInput"
                            sx={{ width: "100%" }}
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                margin="dense"
                                fullWidth
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>{" "}
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        style={{ marginTop: "7px" }}
                        className="custom-date"
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Sample Received On"
                            value={formData.sample_received_on}
                            onChange={(selectedDate) => {
                              handleInputChange(
                                "sample_received_on",
                                selectedDate
                              );
                            }}
                            slotProps={{
                              textField: { error: false },
                            }}
                            className="dateInput"
                            sx={{ width: "100%" }}
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                margin="dense"
                                fullWidth
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>{" "}
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        style={{ marginTop: "7px" }}
                        className="custom-date"
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Sample Generated On"
                            value={formData?.sample_generated_on}
                            onChange={(selectedDate) => {
                              handleInputChange(
                                "sample_generated_on",
                                selectedDate
                              );
                            }}
                            slotProps={{
                              textField: { error: false },
                            }}
                            className="dateInput"
                            sx={{ width: "100%" }}
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                margin="dense"
                                fullWidth
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      {/* Test Date */}
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        style={{ marginTop: "7px" }}
                        className="custom-date"
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Test Date"
                            value={formData.test_date}
                            onChange={(selectedDate) => {
                              handleInputChange("test_date", selectedDate);
                            }}
                            className="dateInput"
                            sx={{ width: "100%" }}
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                margin="dense"
                                required
                                fullWidth
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      {/* Test Time */}
                      <Grid item xs={12} sm={4}>
                        <TextField
                          label="Test Time"
                          type="time"
                          // select
                          size="small"
                          margin="dense"
                          value={formData.test_time}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) =>
                            handleInputChange("test_time", e.target.value)
                          }
                          // required
                          fullWidth
                        />
                        {/* {availableTimeSlots.map((time, index) => (
                            <MenuItem key={index} value={time}>
                              {time}
                            </MenuItem>
                          ))} */}
                        {/* </TextField> */}
                      </Grid>
                      {testDetailsArr && testDetailsArr?.length > 0 && (
                        <Grid item xs={12} sm={12}>
                          <div>
                            <h5 style={{ marginBottom: "15px" }}>
                              Test Details
                            </h5>
                            {/* <CardBody> */}
                            {testDetailsArr.map((item, index) => (
                              <div
                                key={`test_details_${index}`}
                                className="row mb-3 align-items-center"
                              >
                                <Grid container spacing={1}>
                                  <Grid
                                    item
                                    style={{
                                      margin: "auto 0",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    <Badge pill bg="primary">
                                      {`${index + 1}`}
                                    </Badge>{" "}
                                  </Grid>
                                  <Grid item xs={11} sm={11}>
                                    <Grid container spacing={1}>
                                      <Grid item xs={12} sm={4}>
                                        <Autocomplete
                                          options={testNameList.map((item) => ({
                                            label: item?.test_name,
                                            id: item?.id,
                                          }))}
                                          value={item?.test_name || []}
                                          onChange={(event, value) => {
                                            handleTestDetailsChange(
                                              index,
                                              "test_name",
                                              value
                                            );
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Test Names"
                                              margin="dense"
                                              size="small"
                                              error={!item?.test_name}
                                              placeholder="Select Test Name"
                                              required
                                              fullWidth
                                            />
                                          )}
                                        />
                                      </Grid>

                                      <Grid
                                        item
                                        xs={12}
                                        sm={4}
                                        style={{ marginTop: "7px" }}
                                      >
                                        <TextField
                                          type="text"
                                          placeholder="Test Price"
                                          label="Test Price"
                                          variant="outlined"
                                          fullWidth
                                          aria-readonly
                                          size="small"
                                          value={item?.test_price}
                                          InputProps={{
                                            readOnly: true, // Makes the input read-only
                                          }}
                                          required
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={4}
                                        style={{ marginTop: "7px" }}
                                        className="custom-date"
                                      >
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                        >
                                          <DatePicker
                                            label="Test Date"
                                            value={item.conducted_at}
                                            onChange={(selectedDate) => {
                                              handleTestDetailsChange(
                                                index,
                                                "conducted_at",
                                                selectedDate
                                              );
                                            }}
                                            slotProps={{
                                              textField: { error: false },
                                            }}
                                            className="dateInput"
                                            sx={{ width: "100%" }}
                                            renderInput={(params) => (
                                              <TextField
                                                size="small"
                                                margin="dense"
                                                required
                                                fullWidth
                                                {...params}
                                              />
                                            )}
                                          />
                                        </LocalizationProvider>
                                      </Grid>

                                      {/* <Grid item xs={6} sm={6}>
                                        <TextField
                                          type="text"
                                          placeholder="Result"
                                          label="Result"
                                          variant="outlined"
                                          fullWidth
                                          size="small"
                                          value={item?.result}
                                          onChange={(e) =>
                                            handleTestDetailsChange(
                                              index,
                                              "result",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Grid> */}
                                    </Grid>

                                    {item?.parameters?.length > 0 && (
                                      <div
                                        className="card team border-0 rounded shadow overflow-hidden"
                                        style={{ margin: "10px auto" }}
                                      >
                                        <table style={{ margin: "20px" }}>
                                          <thead>
                                            <tr>
                                              <th style={THStyle}>
                                                Parameter Name
                                              </th>
                                              <th
                                                style={{
                                                  ...THStyle,
                                                  width: "30%",
                                                }}
                                              >
                                                Value
                                              </th>
                                              <th style={THStyle}>Unit</th>
                                              <th style={THStyle}>
                                                Normal Range
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {item?.parameters?.map(
                                              (param, idx) => (
                                                <tr>
                                                  <td
                                                    style={{
                                                      padding: "5px 0px",
                                                    }}
                                                  >
                                                    {param?.parameter_name}
                                                  </td>
                                                  <td
                                                    style={{
                                                      padding: "5px 0px",
                                                    }}
                                                  >
                                                    <TextField
                                                      type="text"
                                                      size="small"
                                                      margin="dense"
                                                      value={param.value}
                                                      placeholder="value"
                                                      onChange={(e) =>
                                                        handleTestDetailsChange(
                                                          index,
                                                          "parameters",
                                                          e.target.value,
                                                          idx
                                                        )
                                                      }
                                                    />
                                                    {/* {param?.value} */}
                                                  </td>
                                                  <td
                                                    style={{
                                                      padding: "5px 0px",
                                                    }}
                                                  >
                                                    {param?.units}
                                                  </td>
                                                  <td
                                                    style={{
                                                      padding: "5px 0px",
                                                    }}
                                                  >
                                                    {param?.normal_range}
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    )}

                                    <Grid container spacing={1}>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        style={{ marginTop: "10px" }}
                                      >
                                        <TextField
                                          multiline
                                          placeholder="Remarks"
                                          label="Remarks"
                                          variant="outlined"
                                          fullWidth
                                          size="small"
                                          value={item?.remarks}
                                          rows={2}
                                          onChange={(e) =>
                                            handleTestDetailsChange(
                                              index,
                                              "remarks",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                      <Grid
                                        item
                                        xs={11}
                                        sm={11}
                                        style={{ marginTop: "10px" }}
                                      >
                                        <TextField
                                          multiline
                                          fullWidth
                                          size="small"
                                          placeholder="Description"
                                          label="Test Description"
                                          variant="outlined"
                                          rows={2}
                                          value={item?.test_description}
                                          onChange={(e) =>
                                            handleTestDetailsChange(
                                              index,
                                              "test_description",
                                              e.target.value
                                            )
                                          }
                                          onKeyDown={(e) =>
                                            handleKeyDown(e, index)
                                          }
                                          // required
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={1}
                                        sm={1}
                                        style={{
                                          marginTop: "10px",
                                        }}
                                      >
                                        {/* {index !== 0 && ( */}
                                        <Grid container>
                                          <Grid item>
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip
                                                  id={`tooltip-${index}`}
                                                >
                                                  Remove Entity
                                                </Tooltip>
                                              }
                                            >
                                              <ClearIcon
                                                color="primary"
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  if (
                                                    testDetailsArr &&
                                                    testDetailsArr?.length > 1
                                                  ) {
                                                    handleRemoveTestDetailsArr(
                                                      index
                                                    );
                                                  } else {
                                                    ShowError(
                                                      "At least one entry must remain!"
                                                    );
                                                  }
                                                }}
                                              />
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip
                                                  id={`tooltip-${index}`}
                                                >
                                                  Reset Entity
                                                </Tooltip>
                                              }
                                            >
                                              <ReplayIcon
                                                color="primary"
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  resetTestDetailsEntity(index)
                                                }
                                              />
                                            </OverlayTrigger>
                                          </Grid>
                                        </Grid>

                                        {/* )} */}
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  {/* </div> */}
                                </Grid>
                              </div>
                            ))}

                            <div style={{ marginLeft: "35px" }}>
                              <Button
                                variant="primary"
                                disabled={!allKeysHaveValues(testDetailsArr)}
                                onClick={() => handleAddTestDetailsArr()}
                                size="small"
                              >
                                + Add New Entry
                              </Button>
                            </div>
                            {/* </CardBody> */}
                          </div>
                        </Grid>
                      )}
                      {/* Submit Button */}
                      <Grid container justifyContent="center" spacing={2}>
                        {/* Submit Button */}
                        <Grid item>
                          <Button
                            variant="primary"
                            type="submit"
                            onClick={() => handleSubmit()}
                          >
                            {id !== "new" ? "Update" : "Submit"}
                          </Button>
                        </Grid>

                        {/* Reset Button */}
                        <Grid item>
                          <Button
                            variant="primary"
                            type="button"
                            onClick={handleReset} // Add your reset handler
                          >
                            Reset
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                  {/* )} */}
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </section>

      {/* </Container> */}
    </>

    // <Modal show={show} onHide={onHide} size="lg">

    // </Modal>
  );
};

export default AddEditReport;
