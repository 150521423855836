// import React, { useEffect, useState } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import Modal from "react-bootstrap/Modal";
// import { Button, Form, Row, Col } from "react-bootstrap";
// import axios from "axios";
// import Navbar from "../../navbar";
// import Sidebar from "../../sidebar";
// import Header from "../../CommonComponents/Header";
// import ScrollTop from "../../scrollTop";
// import AdminFooter from "../adminFooter";
// import { ShowError, ShowSucess } from "../../../_helper/helper";
// import { FaEdit } from "react-icons/fa";

// export default function TestManagement() {
//   const token = localStorage.getItem("accessToken");
//   const [loading, setLoading] = useState(false);
//   const [tests, setTests] = useState([]);
//   const [show, setShow] = useState(false);
//   const [isUpdating, setIsUpdating] = useState(false);
//   const [formData, setFormData] = useState({
//     test_name: "",
//     price: "",
//   });
//   const [error, setError] = useState(null);
//   const location = useLocation();
//   const navigate = useNavigate();

//   const showBackButton = location.key !== "default";

//   useEffect(() => {
//     const fetchTests = async () => {
//       try {
//         setLoading(true);
//         const response = await axios.get(
//           `${process.env.REACT_APP_API_URL}/test_name/display/`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//         setTests(response.data || []);
//         setLoading(false);
//       } catch (error) {
//         setLoading(false);
//         setError("There was an error fetching the tests!");
//         console.error(error);
//       }
//     };
//     fetchTests();
//   }, [token]);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     if (Object.values(formData).some((field) => field === "")) {
//       ShowError("All fields are required!");
//       return;
//     }

//     setLoading(true);

//     const payload = {
//       test_name: formData.test_name,
//       price: formData.price,
//     };

//     const apiUrl = isUpdating
//       ? `${process.env.REACT_APP_API_URL}/test_name/${formData.id}/update/`
//       : `${process.env.REACT_APP_API_URL}/test_name/create/`;

//     const method = isUpdating ? "put" : "post";

//     axios[method](apiUrl, payload, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//     })
//       .then((response) => {
//         setLoading(false);
//         ShowSucess(
//           isUpdating ? "Test Updated Successfully!" : "Test Added Successfully!"
//         );
//         setShow(false);
//         setFormData({ test_name: "", price: "" });
//       })
//       .catch((error) => {
//         setLoading(false);
//         ShowError("There was an error! Please try again.");
//         console.error(error);
//       });
//   };

//   const handleModalShow = () => {
//     setShow(true);
//     setIsUpdating(false);
//     setFormData({ test_name: "", price: "" });
//   };

//   const handleEditTest = (test) => {
//     setFormData({
//       id: test.id,
//       test_name: test.test_name,
//       price: test.price,
//     });
//     setIsUpdating(true);
//     setShow(true);
//   };

//   const handleModalClose = () => {
//     setShow(false);
//   };

//   return (
//     <>
//       <Navbar
//         navDark={true}
//         manuClass="navigation-menu nav-left"
//         containerClass="container"
//       />
//       <section className="bg-dashboard">
//         <div className="container-fluid">
//           <div className="row">
//             <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
//             <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
//               <Header
//                 title="Test Management"
//                 onBackClick={() => navigate(-1)}
//                 showBackButton={showBackButton}
//               />
//               <Button
//                 variant="primary"
//                 onClick={handleModalShow}
//                 style={{ marginTop: "20px" }}
//               >
//                 Add Test
//               </Button>
//               <div className="mt-4">
//                 <h5>Test List</h5>
//                 <table className="table">
//                   <thead>
//                     <tr>
//                       <th>Test Name</th>
//                       <th>Price</th>
//                       <th>Actions</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {tests.length > 0 ? (
//                       tests.map((test) => (
//                         <tr key={test.id}>
//                           <td>{test.test_name}</td>
//                           <td>₹{test.price}</td>
//                           <td>
//                             <Button
//                               variant="primary"
//                               onClick={() => handleEditTest(test)}
//                               className="btn-icon btn-pills btn-soft-primary my-1"
//                             >
//                               <FaEdit />
//                             </Button>
//                           </td>
//                         </tr>
//                       ))
//                     ) : (
//                       <tr>
//                         <td colSpan="3">No tests found.</td>
//                       </tr>
//                     )}
//                   </tbody>
//                 </table>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* Modal for Test Form */}
//       <Modal
//         show={show}
//         onHide={handleModalClose}
//         size="lg"
//         backdrop="static"
//         keyboard={false}
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>{isUpdating ? "Edit Test" : "Add Test"}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form onSubmit={handleSubmit}>
//             <Form.Group controlId="test_name">
//               <Form.Label>Test Name</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="test_name"
//                 value={formData.test_name}
//                 onChange={handleInputChange}
//                 required
//               />
//             </Form.Group>

//             <Form.Group controlId="price" className="mt-3">
//               <Form.Label>Price</Form.Label>
//               <Form.Control
//                 type="number"
//                 name="price"
//                 value={formData.price}
//                 onChange={handleInputChange}
//                 required
//               />
//             </Form.Group>

//             <Button variant="primary" type="submit" disabled={loading} className="mt-3">
//               {loading
//                 ? "Saving..."
//                 : isUpdating
//                 ? "Update Test"
//                 : "Add Test"}
//             </Button>
//           </Form>
//         </Modal.Body>
//       </Modal>

//       <ScrollTop />
//       <AdminFooter />
//     </>
//   );
// }


// import React, { useEffect, useState } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import Modal from "react-bootstrap/Modal";
// import { Button, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
// import axios from "axios";
// import Navbar from "../../navbar";
// import Sidebar from "../../sidebar";
// import Header from "../../CommonComponents/Header";
// import ScrollTop from "../../scrollTop";
// import AdminFooter from "../adminFooter";
// import { ShowError, ShowSucess } from "../../../_helper/helper";
// import { FaEdit, FaTrashAlt } from "react-icons/fa";

// export default function TestManagement() {
//   const token = localStorage.getItem("accessToken");
//   const [loading, setLoading] = useState(false);
//   const [tests, setTests] = useState([]);
//   const [show, setShow] = useState(false);
//   const [showConfirm, setShowConfirm] = useState(false);
//   const [isUpdating, setIsUpdating] = useState(false);
//   const [formData, setFormData] = useState({
//     test_name: "",
//     price: "",
//     normal_range: "", // Added normal_range to form data

//   });
//   const [testToDelete, setTestToDelete] = useState(null);
//   const [error, setError] = useState(null);
//   const location = useLocation();
//   const navigate = useNavigate();

//   const showBackButton = location.key !== "default";

//   const fetchTests = async () => {
//     try {
//       setLoading(true);
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/test_name/display/`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       setTests(response.data || []);
//       setLoading(false);
//     } catch (error) {
//       setLoading(false);
//       setError("There was an error fetching the tests!");
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     fetchTests();
//   }, [token]);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (Object.values(formData).some((field) => field === "")) {
//       ShowError("All fields are required!");
//       return;
//     }

//     setLoading(true);

//     const payload = {
//       test_name: formData.test_name,
//       price: formData.price,
//       normal_range: formData.normal_range, // Include normal_range in the payload

//     };

//     const apiUrl = isUpdating
//       ? `${process.env.REACT_APP_API_URL}/test_name/${formData.id}/update/`
//       : `${process.env.REACT_APP_API_URL}/test_name/create/`;

//     const method = isUpdating ? "put" : "post";

//     try {
//       await axios[method](apiUrl, payload, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "application/json",
//         },
//       });
//       ShowSucess(
//         isUpdating ? "Test Updated Successfully!" : "Test Added Successfully!"
//       );
//       setShow(false);
//       setFormData({ test_name: "", price: "" });
//       fetchTests();
//     } catch (error) {
//       ShowError("There was an error! Please try again.");
//       console.error(error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const confirmDelete = (testId) => {
//     setTestToDelete(testId);
//     setShowConfirm(true);
//   };

//   const handleDelete = async () => {
//     if (!testToDelete) return;
//     setLoading(true);

//     try {
//       await axios.delete(
//         `${process.env.REACT_APP_API_URL}/test_name/${testToDelete}/delete/`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       ShowSucess("Test successfully deleted!");
//       setShowConfirm(false);
//       setTestToDelete(null);
//       fetchTests();
//     } catch (error) {
//       console.error("Error deleting test:", error);
//       ShowError("Error deleting test. Please try again.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleModalShow = () => {
//     setShow(true);
//     setIsUpdating(false);
//     setFormData({ test_name: "", price: "", normal_range: "" }); // Initialize with normal_range
//   };

//   const handleEditTest = (test) => {
//     setFormData({
//       id: test.id,
//       test_name: test.test_name,
//       normal_range: test.normal_range, // Prepopulate normal_range when editing
//     price: parseInt(test.price, 10), 
//     });
//     setIsUpdating(true);
//     setShow(true);
//   };

//   const handleModalClose = () => {
//     setShow(false);
//   };

//   return (
//     <>
//       <Navbar
//         navDark={true}
//         manuClass="navigation-menu nav-left"
//         containerClass="container"
//       />
//       <section className="bg-dashboard">
//         <div className="container-fluid">
//           <div className="row">
//             <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
//             <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
//               <Header
//                 title="Test Management"
//                 onBackClick={() => navigate(-1)}
//                 showBackButton={showBackButton}
//               />
//               <Button
//                 variant="primary"
//                 onClick={handleModalShow}
//                 style={{ marginTop: "20px" }}
//               >
//                 Add Test
//               </Button>
//               <div className="mt-4">
//                 {/* <h5>Test List</h5> */}
//                 <table className="table">
//                   <thead>
//                     <tr>
//                     <th>No.</th>
//                       <th>Test Name</th>
//                       <th>Price</th>
//                       <th>Normal Range</th>
//                       <th>Actions</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//   {tests.length > 0 ? (
//     tests.map((test, index) => (
//       <tr key={test.id}>
//         <td>{index + 1}</td> {/* Add numbering here */}
//         <td>{test.test_name}</td>
//         <td>₹ {Number(test.price).toFixed(0)}</td>
//         <td>{test.normal_range}</td> {/* Display normal_range */}

//         <td>
//           <Button
//             variant="primary"
//             onClick={() => handleEditTest(test)}
//             className="btn-icon btn-pills btn-soft-primary my-1"
//           >
//             <FaEdit />
//           </Button>
//           <OverlayTrigger
//             placement="top"
//             overlay={
//               <Tooltip id={`tooltip-delete-${test.id}`}>
//                 Delete Test
//               </Tooltip>
//             }
//           >
//             <Button
//               variant="danger"
//               onClick={() => confirmDelete(test.id)}
//               className="btn-icon btn-pills btn-soft-danger my-1"
//             >
//               <FaTrashAlt />
//             </Button>
//           </OverlayTrigger>
//         </td>
//       </tr>
//     ))
//   ) : (
//     <tr>
//       <td colSpan="4" className="text-center">
//         No list found.
//       </td>
//     </tr>
//   )}
// </tbody>

//                   {/* <tbody>
//                     {tests.length > 0 ? (
//                       tests.map((test) => (
//                         <tr key={test.id}>
//                           <td>{test.test_name}</td>
//                           <td>₹{test.price}</td>
//                           <td>
//                             <Button
//                               variant="primary"
//                               onClick={() => handleEditTest(test)}
//                               className="btn-icon btn-pills btn-soft-primary my-1"
//                             >
//                               <FaEdit />
//                             </Button>
//                             <OverlayTrigger
//                               placement="top"
//                               overlay={
//                                 <Tooltip id={`tooltip-delete-${test.id}`}>
//                                   Delete Test
//                                 </Tooltip>
//                               }
//                             >
//                               <Button
//                                 variant="danger"
//                                 onClick={() => confirmDelete(test.id)}
//                                 className="btn-icon btn-pills btn-soft-danger my-1"
//                               >
//                                 <FaTrashAlt />
//                               </Button>
//                             </OverlayTrigger>
//                           </td>
//                         </tr>
//                       ))
//                     ) : (
//                       <tr>
//                         <td colSpan="3">No tests found.</td>
//                       </tr>
//                     )}
//                   </tbody> */}
//                 </table>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       <Modal
//         show={showConfirm}
//         onHide={() => setShowConfirm(false)}
//         centered
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Confirm Deletion</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           Are you sure you want to delete this test?
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={() => setShowConfirm(false)}>
//             Cancel
//           </Button>
//           <Button
//             variant="danger"
//             onClick={handleDelete}
//             disabled={loading}
//           >
//             {loading ? "Deleting..." : "Delete"}
//           </Button>
//         </Modal.Footer>
//       </Modal>
//       {/* Modal for Test Form */}
//       <Modal
//         show={show}
//         onHide={handleModalClose}
//         size="lg"
//         backdrop="static"
//         keyboard={false}
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>{isUpdating ? "Edit Test" : "Add Test"}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form onSubmit={handleSubmit}>
//             <Form.Group controlId="test_name">
//               <Form.Label>Test Name</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="test_name"
//                 value={formData.test_name}
//                 onChange={handleInputChange}
//                 required
//               />
//             </Form.Group>

//             <Form.Group controlId="price" className="mt-3">
//               <Form.Label>Price</Form.Label>
//               <Form.Control
//                 type="number"
//                 name="price"
//                 value={formData.price}
//                 onChange={handleInputChange}
//                 required
//               />
//             </Form.Group>
//             <Form.Group controlId="normal_range" className="mt-3">
//               <Form.Label>Normal Range</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="normal_range"
//                 value={formData.normal_range}
//                 onChange={handleInputChange}
//                 required
//               />
//             </Form.Group>

//             <Button variant="primary" type="submit" disabled={loading} className="mt-3">
//               {loading
//                 ? "Saving..."
//                 : isUpdating
//                 ? "Update Test"
//                 : "Add Test"}
//             </Button>
//           </Form>
//         </Modal.Body>
//       </Modal>
//       <ScrollTop />
//       <AdminFooter />
//     </>
//   );
// }


import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Button, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import axios from "axios";
import Navbar from "../../navbar";
import Sidebar from "../../sidebar";
import Header from "../../CommonComponents/Header";
import ScrollTop from "../../scrollTop";
import AdminFooter from "../adminFooter";
import { ShowError, ShowSucess } from "../../../_helper/helper";
import { FaEdit, FaTrashAlt, FaPlusCircle } from "react-icons/fa";

export default function TestManagement() {
  const token = localStorage.getItem("accessToken");
  const [loading, setLoading] = useState(false);
  const [tests, setTests] = useState([]);
  const [show, setShow] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [formData, setFormData] = useState({
    test_name: "",
    price: "",
    normal_range: "",
    parameters: [{ parameter_name: "", normal_range: "", units: "" }]
  });
  const [testToDelete, setTestToDelete] = useState(null);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const showBackButton = location.key !== "default";

  const fetchTests = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/test_name/display/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTests(response.data || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError("There was an error fetching the tests!");
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTests();
  }, [token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const nameParts = name.split("."); // Handle nested parameters fields
    
    if (nameParts.length > 1) {
      setFormData((prevData) => {
        const newParameters = [...prevData.parameters];
        newParameters[parseInt(nameParts[1], 10)][nameParts[0]] = value;
        return { ...prevData, parameters: newParameters };
      });
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const addParameter = () => {
    setFormData((prevData) => ({
      ...prevData,
      parameters: [
        ...prevData.parameters,
        { parameter_name: "", normal_range: "", units: "" }
      ]
    }));
  };

  const deleteParameter = (index) => {
    const updatedParameters = formData.parameters.filter((_, i) => i !== index);
    setFormData((prevData) => ({
      ...prevData,
      parameters: updatedParameters
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (Object.values(formData).some((field) => field === "")) {
      ShowError("All fields are required!");
      return;
    }

    setLoading(true);

    const payload = {
      test_name: formData.test_name,
      price: formData.price,
      normal_range: formData.normal_range,
      parameters: formData.parameters, // Include parameters in the payload
    };

    const apiUrl = isUpdating
      ? `${process.env.REACT_APP_API_URL}/tests/${formData.id}/`
      : `${process.env.REACT_APP_API_URL}/test_name/create/`;

    const method = isUpdating ? "put" : "post";

    try {
      await axios[method](apiUrl, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      ShowSucess(
        isUpdating ? "Test Updated Successfully!" : "Test Added Successfully!"
      );
      setShow(false);
      setFormData({
        test_name: "",
        price: "",
        normal_range: "",
        parameters: [{ parameter_name: "", normal_range: "", units: "" }]
      });
      fetchTests();
    } catch (error) {
      ShowError("There was an error! Please try again.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditTest = (test) => {
    setFormData({
      id: test.id,
      test_name: test.test_name,
      normal_range: test.normal_range,
      price: parseInt(test.price, 10),
      parameters: test.parameters || [{ parameter_name: "", normal_range: "", units: "" }] // Prepopulate parameters when editing
    });
    setIsUpdating(true);
    setShow(true);
  };

  const handleModalClose = () => {
    setShow(false);
      setFormData({
    test_name: "",
    price: "",
    normal_range: "",
    parameters: [{ parameter_name: "", normal_range: "", units: "" }]
  });

  };
  const [showConfirm, setShowConfirm] = useState(false);
  const confirmDelete = (testId) => {
    setTestToDelete(testId);
    setShowConfirm(true);
  };
  const handleDelete = async () => {
    if (!testToDelete) return;
    setLoading(true);

    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/test_name/${testToDelete}/delete/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      ShowSucess("Test successfully deleted!");
      setShowConfirm(false);
      setTestToDelete(null);
      fetchTests();
    } catch (error) {
      console.error("Error deleting test:", error);
      ShowError("Error deleting test. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Navbar
        navDark={true}
        manuClass="navigation-menu nav-left"
        containerClass="container"
      />
      <section className="bg-dashboard">
        <div className="container-fluid">
          <div className="row">
            <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
            <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <Header
                title="Test Management"
                onBackClick={() => navigate(-1)}
                showBackButton={showBackButton}
              />
              <Button
                variant="primary"
                onClick={() => setShow(true)}
                style={{ marginTop: "20px" }}
              >
                Add Test
              </Button>
              <div className="mt-4">
                <table className="table">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Test Name</th>
                      <th>Price</th>
                      <th>Normal Range</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tests.length > 0 ? (
                      tests.map((test, index) => (
                        <tr key={test.id}>
                          <td>{index + 1}</td>
                          <td>{test.test_name}</td>
                          <td>₹ {Number(test.price).toFixed(0)}</td>
                          <td>{test.normal_range}</td>
                          <td>
                            <Button
                              variant="primary"
                              onClick={() => handleEditTest(test)}
                              className="btn-icon btn-pills btn-soft-primary my-1"
                            >
                              <FaEdit />
                            </Button>
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id={`tooltip-delete-${test.id}`}>Delete Test</Tooltip>}
                            >
                              <Button
                                variant="danger"
                                onClick={() => confirmDelete(test.id)}
                                className="btn-icon btn-pills btn-soft-danger my-1"
                              >
                                <FaTrashAlt />
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">No tests found.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this test?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirm(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={handleDelete}
            disabled={loading}
          >
            {loading ? "Deleting..." : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Test Form Modal */}
      <Modal show={show} onHide={handleModalClose} size="lg" backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{isUpdating ? "Edit Test" : "Add Test"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="test_name">
              <Form.Label>Test Name</Form.Label>
              <Form.Control
                type="text"
                name="test_name"
                value={formData.test_name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="price" className="mt-3">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                name="price"
                value={formData.price}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="normal_range" className="mt-3">
              <Form.Label>Normal Range</Form.Label>
              <Form.Control
                type="text"
                name="normal_range"
                value={formData.normal_range}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <h5 className="mt-4">Test Parameters</h5>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Parameter Name</th>
                  <th>Normal Range</th>
                  <th>Units</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {formData.parameters.map((parameter, index) => (
                  <tr key={index}>
                    <td>
                      <Form.Control
                        type="text"
                        name={`parameter_name.${index}`}
                        value={parameter.parameter_name}
                        onChange={handleInputChange}
                        required
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        name={`normal_range.${index}`}
                        value={parameter.normal_range}
                        onChange={handleInputChange}
                        required
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        name={`units.${index}`}
                        value={parameter.units}
                        onChange={handleInputChange}
                        required
                      />
                    </td>
                    <td>
                      {formData.parameters.length > 1 && (
                        <Button
                          variant="link"
                          onClick={() => deleteParameter(index)}
                          className="text-danger"
                        >
                          <FaTrashAlt />
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <Button
              variant="link"
              onClick={addParameter}
              className="text-info d-flex align-items-center"
            >
              <FaPlusCircle className="me-2" /> Add More Parameters
            </Button>

            <Button
              variant="primary"
              type="submit"
              disabled={loading}
              className="mt-3"
            >
              {loading ? "Saving..." : isUpdating ? "Update Test" : "Add Test"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <ScrollTop />
      <AdminFooter />
    </>
  );
}
