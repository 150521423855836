import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { getDoctorId } from "../../../_helper/helper";
import SkeletonLoader from "../../../pages/dashboard/SkeltonLoader";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the required components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels // Register the plugin

);

export default function HealthProviderEarning() {
  const [earningsData, setEarningsData] = useState({
    monthly: {},
    quarterly: {},
    yearly: {},
  });
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({
    viewMode: "month", // month or year
    startYear: null, // Selected start year for year range
    endYear: null, // Selected end year for year range
    startDate: null, // Start date for month view
    endDate: null, // End date for month view
    month: null, // Selected month for month view
    year: moment().year(), // Default to the current year
    specificYear: null, // New field for specific year
  });

  const token = localStorage.getItem("accessToken");
  const doctorId = getDoctorId();
  const updateTotalEarnings = (data) => {
    let total = 0;

    // Monthwise calculation
    if (filter.viewMode === "month" && filter.year) {
      const selectedYearData = data.monthly[filter.year] || {};

      if (filter.startDate && filter.endDate) {
        // If date range is provided, filter the months that fall within the range
        const startDate = moment(filter.startDate);
        const endDate = moment(filter.endDate);

        for (const month in selectedYearData) {
          const monthDate = moment(month, "MMMM").year(filter.year);
          if (monthDate.isBetween(startDate, endDate, "month", "[]")) {
            total += selectedYearData[month] || 0;
          }
        }
      } else {
        // If no date range, sum all months in the selected year
        total = Object.values(selectedYearData).reduce(
          (sum, value) => sum + value,
          0
        );
      }
    }
    // Year Range calculation
    else if (filter.viewMode === "year" && filter.startYear && filter.endYear) {
      for (let year = filter.startYear; year <= filter.endYear; year++) {
        const selectedYearData = data.yearly[year] || 0;
        total += selectedYearData;
      }
    }
    // Specific Year calculation (month-wise for specific year)
    else if (filter.viewMode === "specificYear" && filter.specificYear) {
      const selectedYearData = data.monthly[filter.specificYear] || {};
      total = Object.values(selectedYearData).reduce(
        (sum, value) => sum + value,
        0
      );
    }
    // Quarterly calculation (Q1, Q2, Q3, Q4)
    else if (filter.viewMode === "quarter" && filter.quarter && filter.year) {
      const quarterMonths = {
        Q1: ["January", "February", "March"],
        Q2: ["April", "May", "June"],
        Q3: ["July", "August", "September"],
        Q4: ["October", "November", "December"],
      };

      const selectedQuarter = quarterMonths[filter.quarter] || [];
      const selectedYearData = data.monthly[filter.year] || {};

      total = selectedQuarter.reduce(
        (sum, month) => sum + (selectedYearData[month] || 0),
        0
      );
    }

    setTotalEarnings(total);
  };
const healthprovider = localStorage.getItem("health_provider_id")
  const fetchEarningsData = async () => {
    try {
      const payload = preparePayload();

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/earnings-report/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data || {};
      setEarningsData(data);

      // Now update total earnings dynamically based on the filter settings
      updateTotalEarnings(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching earnings data:", error);
      setLoading(false);
    }
  };

  const preparePayload = () => {
    // const payload = {};
    const payload = {
        health_provider_id: healthprovider, // Include health provider ID in the payload
      };

    if (filter.viewMode === "month" && filter.startDate && filter.endDate) {
      // If Month-wise view, send start_date and end_date
      payload.start_date = moment(filter.startDate).format("YYYY-MM-DD");
      payload.end_date = moment(filter.endDate).format("YYYY-MM-DD");
    }

    if (filter.viewMode === "year" && filter.startYear && filter.endYear) {
      // If Year-wise view, send start_year and end_year
      payload.start_year = filter.startYear;
      payload.end_year = filter.endYear;
    }

    return payload;
  };

  // Prepare the data for the chart
  const chartData = () => {
    let labels = [];
    let data = [];

    if (filter.viewMode === "month") {
      // Month-wise view
      labels = moment.months();
      const selectedYearData = earningsData.monthly[filter.year] || {};

      data = labels.map((month, index) => {
        const monthDate = moment().month(index).year(filter.year);

        if (filter.startDate && filter.endDate) {
          const startDate = moment(filter.startDate);
          const endDate = moment(filter.endDate);

          if (!monthDate.isBetween(startDate, endDate, "month", "[]")) {
            return 0;
          }
        }

        return selectedYearData[month] || 0;
      });
    } else if (filter.viewMode === "year") {
      // Year-wise view
      const yearsInRange = [];
      if (filter.startYear && filter.endYear) {
        for (let year = filter.startYear; year <= filter.endYear; year++) {
          yearsInRange.push(year);
        }
      } else if (filter.year) {
        yearsInRange.push(filter.year);
      }

      labels = yearsInRange.map((year) => `${year}`);
      data = yearsInRange.map((year) => earningsData.yearly[year] || 0);
    } else if (filter.viewMode === "quarter") {
      // Quarterly view
      const quarterMonths = {
        Q1: ["January", "February", "March"],
        Q2: ["April", "May", "June"],
        Q3: ["July", "August", "September"],
        Q4: ["October", "November", "December"],
      };

      const selectedQuarter = quarterMonths[filter.quarter] || [];
      labels = selectedQuarter;

      const selectedYearData = earningsData.monthly[filter.year] || {};
      data = labels.map((month) => selectedYearData[month] || 0);
    } else if (filter.viewMode === "specificYear") {
      // Specific Year View - Show all 12 months
      labels = moment.months(); // All months of the year
      const selectedYearData = earningsData.monthly[filter.specificYear] || {};

      data = labels.map((month) => selectedYearData[month] || 0); // Data for each month
    }

    return {
      labels: labels,
      datasets: [
        {
          label:
            filter.viewMode === "month"
              ? "Monthly Earnings"
              : filter.viewMode === "year"
              ? "Yearly Earnings"
              : filter.viewMode === "specificYear"
              ? `Earnings for ${filter.specificYear}`
              : "Quarterly Earnings",
          data: data,
          backgroundColor: "rgba(54, 162, 235, 0.6)",
        },
      ],
    };
  };

  useEffect(() => {
    // Fetch data on component mount
    fetchEarningsData();
  }, [filter]); // Rerun when filter changes (date range or view mode)

  // Handle filter changes
  const handleFilterChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  return (
    <div className="container mt-5">
      {loading ? (
        <SkeletonLoader />
      ) : (
        <>
          {/* Earnings Report Header */}
          <div className="row mb-4">
            <div className="col-12">
              <div className="card shadow-lg border-0 p-4">
                <h4 className="card-title text-primary mb-3">
                  Earnings Report
                </h4>
                <p className="text-muted">
                  View and filter your earnings data.
                </p>
              </div>
            </div>
          </div>

          {/* Filter Section */}
          {/* Filter Section */}
          <div className="row mb-4">
            <div className="col-12">
              <div className="d-flex align-items-center">
                {/* View Mode Dropdown */}
                <div className="me-3">
                  <label htmlFor="viewMode" className="form-label">
                    View Mode
                  </label>
                  <select
                    className="form-select"
                    name="viewMode"
                    value={filter.viewMode}
                    onChange={handleFilterChange}
                  >
                    <option value="month">Month Wise</option>
                    <option value="year">Year Wise</option>
                    <option value="specificYear">Specific Year</option>{" "}
                    {/* New option */}
                    <option value="quarter">Quarterly</option>
                  </select>
                </div>

                {/* Month and Date Range Filter */}
                {filter.viewMode === "month" && (
                  <div className="d-flex me-3">
                    <div className="me-3">
                      <label htmlFor="startDate" className="form-label">
                        Start Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        name="startDate"
                        value={filter.startDate || ""}
                        onChange={handleFilterChange}
                      />
                    </div>
                    <div>
                      <label htmlFor="endDate" className="form-label">
                        End Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        name="endDate"
                        value={filter.endDate || ""}
                        onChange={handleFilterChange}
                      />
                    </div>
                  </div>
                )}

                {/* Year Range Filter */}
                {filter.viewMode === "year" && (
                  <div className="d-flex me-3">
                    <div className="me-3">
                      <label htmlFor="startYear" className="form-label">
                        Start Year
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="startYear"
                        value={filter.startYear || ""}
                        onChange={handleFilterChange}
                      />
                    </div>
                    <div>
                      <label htmlFor="endYear" className="form-label">
                        End Year
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="endYear"
                        value={filter.endYear || ""}
                        onChange={handleFilterChange}
                      />
                    </div>
                  </div>
                )}
                {filter.viewMode === "specificYear" && (
                  <div className="me-3">
                    <label htmlFor="specificYear" className="form-label">
                      Select Year
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="specificYear"
                      value={filter.specificYear || ""}
                      onChange={handleFilterChange}
                    />
                  </div>
                )}

                {/* Quarterly Dropdown */}
                {filter.viewMode === "quarter" && (
                  <div className="me-3">
                    <label htmlFor="quarter" className="form-label">
                      Select Quarter
                    </label>
                    <select
                      className="form-select"
                      name="quarter"
                      value={filter.quarter || ""}
                      onChange={handleFilterChange}
                    >
                      <option value="Q1">Q1 (Jan - Mar)</option>
                      <option value="Q2">Q2 (Apr - Jun)</option>
                      <option value="Q3">Q3 (Jul - Sep)</option>
                      <option value="Q4">Q4 (Oct - Dec)</option>
                    </select>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Earnings Chart */}
          <div className="row mb-4">
            <div className="col-12">
              <div className="card shadow-lg border-0 p-4">
              <Bar
  data={chartData()}
  options={{
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
    elements: {
      bar: {
        borderWidth: 1,
        borderColor: "rgba(54, 162, 235, 1)",
      },
    },
    plugins: {
      tooltip: {
        enabled: true, // Re-enable the tooltip on hover
      },
      datalabels: {
        display: true, // Always display the data labels
        color: "white", // Set label color
        font: {
          weight: "bold",
          size: 12,
        },
        anchor: "center",  // Center anchor, inside the bar
        align: "center",   // Center align, inside the bar
        formatter: (value) => `₹${value}`, // Format value to include currency symbol
        offset: 5, // Optional: Adjust position slightly to avoid overlap
      },
    },
  }}
/>


                {/* <Bar
                  data={chartData()}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      x: {
                        beginAtZero: true,
                      },
                      y: {
                        beginAtZero: true,
                      },
                    },
                    elements: {
                      bar: {
                        borderWidth: 1,
                        borderColor: "rgba(54, 162, 235, 1)",
                      },
                    },
                  }}
                /> */}
              </div>
            </div>
          </div>

          {/* Total Earnings Section */}
          <div className="row">
            <div className="col-12">
              <div className="card shadow-lg border-0 p-4">
                <h5 className="card-title">Total Earnings: ₹{totalEarnings}</h5>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
