import React from 'react'
import Navbar from '../../components/navbar'
import AdminFooter from '../../components/dashboard/adminFooter'
import ScrollTop from '../../components/scrollTop'
import Sidebar from '../../components/sidebar'
import Header from '../../components/CommonComponents/Header'
import { useNavigate } from 'react-router-dom'
import HealthProviderEarning from '../../components/dashboard/EarningReport/HealthProviderEarning'

function HealthProviderDashboard() {
    const navigate=useNavigate()
    const showBackButton = location.key !== "default";

  return (
   
        <>
          <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container" />
          <section className="bg-dashboard">
        <div className="container-fluid">
          <div className="row">
            <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
            <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <Header
                title="Dashboard"
                onBackClick={() => navigate(-1)}
                showBackButton={showBackButton}
              />
            <HealthProviderEarning />
            </div>
          </div>
        </div>
      </section>
  
          <AdminFooter />
          <ScrollTop />
        </>
      
  )
}

export default HealthProviderDashboard