import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import image1 from "../../assets/images/doctors/01.jpg";
import { useDispatch, useSelector } from "react-redux";
import { doctorDetails } from "../../store/doctor/actions";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import AdminFooter from "../../components/dashboard/adminFooter";
import { ShowError, ShowSucess } from "../../_helper/helper";
import { loadingPanel } from "../../components/CommonComponents/CommonFunction";
import Header from "../../components/CommonComponents/Header";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";

export default function Healthproviderprofile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("accessToken");
  const [loading, setLoading] = useState(false);
  const { doctorDetailsResults } = useSelector((state) => state.DoctorReducer);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [hide, setHide] = useState(false);
  const { image } = doctorDetailsResults;
  const location = useLocation();
  const showBackButton = location.key !== "default";
  const doctorid = localStorage.getItem("doctorId");
  const [userData, setUserData] = useState(null);
  const getUserData = () => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      return JSON.parse(userData);
    }
    return null;
  };
  const [patientImage, setImage] = useState(null); // State for the image

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };
  const [isImageVisible, setIsImageVisible] = useState(true);


const updateDetails = async () => {
  const url = `${process.env.REACT_APP_API_URL}/user/update/`;

  const data = new FormData();
  data.append("first_name", document.getElementById("first_name").value);
  data.append("last_name", document.getElementById("last_name").value);
  data.append("email", document.getElementById("email").value);
  data.append("phone_number", document.getElementById("phone_number").value);

  if (patientImage) {
    data.append("image", patientImage); // Append image if it exists
  }

  try {
    // Send the PUT request with FormData
    const response = await axios.put(url, data, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    });

    if (response.status === 200) {
      const updatedUserData = { ...getUserData(), user: formData }; // Adjust as needed
      localStorage.setItem("userData", JSON.stringify(updatedUserData));

      setUserData(updatedUserData);
      setIsImageVisible(false);
      // fetchPatientProfile();
      dispatch(getPatientDetails());
      ShowSucess("Details updated successfully");
      console.log("Details updated successfully");
    } else {
      ShowError("Failed to update details. Please try again.");
    }
  } catch (error) {
    if (error.response) {
      const errorData = error.response.data;
      if (errorData.phone_number) {
        ShowError(`Phone number error: ${errorData.phone_number[0]}`);
      } else if (errorData.email) {
        ShowError(`Email error: ${errorData.email[0]}`);
      } else if (errorData.image) {
        ShowError(`Image Error: ${errorData.image[0]}`);
      } else {
        ShowError(errorData.detail || "Failed to update details.");
      }
      console.error("Failed to update details:", errorData);
    } else if (error.request) {
      // No response from server
      console.error("No response received:", error.request);
      ShowError("An unexpected error occurred, no response from server.");
    } else {
      // Other errors (e.g., network issues)
      console.error("Error:", error.message);
      ShowError("An unexpected error occurred.");
    }
  }
};

//   const updateDetails = async () => {
//     const url = `${process.env.REACT_APP_API_URL}/user/update/`;

//     const data = new FormData();
//     data.append("first_name", document.getElementById("first_name").value);
//     data.append("last_name", document.getElementById("last_name").value);
//     data.append("email", document.getElementById("email").value);
//     data.append("phone_number", document.getElementById("phone_number").value);


//     if (patientImage) {
//       data.append("image", patientImage); // Append image if it exists
//     }

//     try {
//       const response = await fetch(url, {
//         method: "PUT",
//         headers: {
//           Authorization: `Bearer ${token}`, // Ensure you have the token defined
//         },
//         body: data, // Use FormData as the body
//       });

//       if (response.ok) {
//         // Update local storage with the new user details
//         const updatedUserData = { ...getUserData(), user: formData }; // Adjust as needed
//         localStorage.setItem("userData", JSON.stringify(updatedUserData));

//         // Update state with the new user details
//         setUserData(updatedUserData);
//         setIsImageVisible(false);
//         // fetchPatientProfile();
//         dispatch(getPatientDetails());
//         ShowSucess("Details updated successfully");
//         console.log("Details updated successfully");
//       } else {
//         // Parse the error response to extract field-specific error messages
//         const errorData = await response.json();

//         // Check for specific error fields and show them
//         if (errorData.phone_number) {
//           ShowError(`Phone number error: ${errorData.phone_number[0]}`);
//         } else if (errorData.email) {
//           ShowError(`Email error: ${errorData.email[0]}`);
//         } else if (errorData.image) {
//           ShowError(`Image Error: ${errorData.image[0]}`);
//         } else {
//           ShowError(errorData.detail || "Failed to update details.");
//         }

//         console.error("Failed to update details:", errorData);
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       ShowError("An unexpected error occurred.");
//     }
//   };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const result = await axios.delete(
        `${baseURL}/doctors/${Number(doctorid)}/remove-image/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("deleteresp", result);
      const resp = result.data.image;
      if (resp) {
        setLoading(false);
        dispatch(doctorDetails(doctorid));
        ShowSucess("Profile Removed Successfully!");
      } else {
        ShowError("Something Went Wrong!.");
        setLoading(false);
      }
    } catch (err) {
      ShowError(err);
      setLoading(false);
    }
  };

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordErrors, setPasswordErrors] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8)
      errors.push("Password must be at least 8 characters long.");
    if (!/[A-Z]/.test(password))
      errors.push("Password must contain at least one uppercase letter.");
    if (!/[0-9]/.test(password))
      errors.push("Password must contain at least one number.");
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password))
      errors.push("Password must contain at least one special character.");
    return errors;
  };

  const handlePasswordChange = (e) => {
    const { id, value } = e.target;
    if (id === "current_password") setCurrentPassword(value);
    if (id === "new_password") {
      setNewPassword(value);
      setPasswordErrors(validatePassword(value));
    }
    if (id === "confirm_new_password") setConfirmNewPassword(value);
  };

  const changePassword = async () => {
    if (passwordErrors.length > 0) {
      setError("Please fix the errors in the password fields.");
      return;
    }

    if (!currentPassword || !newPassword || !confirmNewPassword) {
      setError("All fields are required.");
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setError("New passwords do not match.");
      return;
    }

    try {
      const url = `${process.env.REACT_APP_API_URL}/users/change-password/`;

      const data = {
        current_password: currentPassword,
        new_password: newPassword,
        confirm_new_password: confirmNewPassword,
      };

      // Using Axios instead of fetch
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Ensure `token` is defined in your component
        },
      });

      if (response.status === 200) {
        setCurrentPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
        setPasswordErrors([]);
        ShowSucess("Password updated successfully!");
        setError(""); // Clear any previous error messages
      } else {
        // If status code is not 200, handle accordingly
        setError(
          response.data.message ||
            "Failed to update password. Please try again."
        );
        setSuccess("");
      }
    } catch (error) {
      // Handle errors here
      setError("An error occurred. Please try again.");
      setSuccess("");
      console.error("Error:", error);
      // Optional: Check if error response exists
      if (error.response) {
        console.error("Error response:", error.response.data);
      }
    }
  };

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
  });

  useEffect(() => {
    // Retrieve and parse user data from localStorage
    const userData = JSON.parse(localStorage.getItem("userData"));

    if (userData) {
      // Update state with retrieved data
      setFormData({
        first_name: userData?.user.first_name || "",
        last_name: userData?.user.last_name || "",
        email: userData?.user.email || "",
        phone_number: userData?.user.phone_number || "",
      });
    }
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const [showModal, setShowModal] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");
  const [forgotError, setForgotError] = useState("");
  const [forgotLoading, setForgotLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false); // State for password visibility

  const handleForgotPassword = async () => {
    if (!forgotEmail) {
      setForgotError("Please enter your email.");
      return;
    }

    setForgotError("");
    setForgotLoading(true);

    try {
      // Replace with your password reset API endpoint
      // Example:
      await axios.post(
        `${process.env.REACT_APP_API_URL}/password-reset-request/`,
        { email: forgotEmail }
      );

      setForgotError(
        "If the email is registered, you will receive a password reset link."
      );
    } catch (err) {
      console.error("Error:", err);
      if (err.response && err.response.data) {
        setForgotError(
          err.response.data.detail ||
            "Failed to send reset email. Please try again."
        );
      } else {
        setForgotError("Failed to send reset email. Please try again.");
      }
    } finally {
      setForgotLoading(false);
    }
  };

  // Modal close and reset states
  const handleCloseModal = () => {
    setShowModal(false);
    setForgotError("");
    setForgotEmail("");
  };

  const handleShowModal = () => setShowModal(true);
  return (
    <>
      <Navbar
        navDark={true}
        manuClass="navigation-menu nav-left"
        containerClass="container"
      />
      <section className="bg-dashboard">
        <div className="container-fluid">
          <div className="row justify-content-center">
            {loading && loadingPanel()}
            <Sidebar colClass="col-xl-4 col-lg-4 col-md-5 col-12" />

            <div className="col-xl-8 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <Header
                title="Profile Settings" // Replace with the title you want
                onBackClick={() => navigate(-1)}
                showBackButton={showBackButton}
              />
              <div className="tab-pane fade show active">
                <h5 className="mb-0">Personal Information :</h5>
                <div className="row align-items-center mt-4"></div>

                <form className="mt-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label">First Name</label>
                        <input
                          name="first_name"
                          id="first_name"
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          value={formData.first_name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label">Last Name</label>
                        <input
                          name="last_name"
                          id="last_name"
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          value={formData.last_name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label">Your Email</label>
                        <input
                          name="email"
                          id="email"
                          type="email"
                          className="form-control"
                          placeholder="Your email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label">Phone no.</label>
                        <input
                          name="phone_number"
                          id="phone_number"
                          type="number"
                          className="form-control"
                          placeholder="Phone no."
                          value={formData.phone_number}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 mb-3">
                      <label className="form-label">
                        Upload Image (optional)
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        accept="image/*" // Accept only image files
                        onChange={handleImageChange}
                        name="image" // Change name to patient_image
                        id="image" // Change id to patient_image
                      />
                    </div>
                    {patientImage && isImageVisible && (
                      <div
                        className="col-md-6 mb-3"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img
                          src={URL.createObjectURL(patientImage)} // Create a URL for the uploaded file
                          alt="Uploaded"
                          style={{
                            width: "90px", // Set the width to a smaller size
                            height: "90px", // Set the height to match width
                            borderRadius: "50%", // Make the image round
                            objectFit: "cover", // Ensure the image covers the area
                            marginTop: "0.5rem",
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={updateDetails}
                      >
                        Update Details
                      </button>
                    </div>
                  </div>
                </form>
                <div className="rounded shadow mt-4">
                  <div className="p-4 border-bottom">
                    <h5 className="mb-0">Change Password :</h5>
                  </div>

                  <div className="p-4">
                    <form className="mt-4">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="mb-3">
                            <label className="form-label">
                              Current Password
                            </label>
                            <input
                              name="current_password"
                              id="current_password"
                              type="password"
                              className="form-control"
                              placeholder="Current password"
                              value={currentPassword}
                              onChange={handlePasswordChange}
                            />
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="mb-3">
                            <label className="form-label">New Password</label>
                            <input
                              name="new_password"
                              id="new_password"
                              type="password"
                              className="form-control"
                              placeholder="New password"
                              value={newPassword}
                              onChange={handlePasswordChange}
                            />
                            <ul className="mt-2">
                              {passwordErrors.map((error, index) => (
                                <li key={index} className="text-danger">
                                  {error}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="mb-3">
                            <label className="form-label">
                              Confirm New Password
                            </label>
                            <input
                              name="confirm_new_password"
                              id="confirm_new_password"
                              type="password"
                              className="form-control"
                              placeholder="Re-type New password"
                              value={confirmNewPassword}
                              onChange={handlePasswordChange}
                            />
                          </div>
                        </div>

                        {error && (
                          <div className="col-lg-12 mb-3">
                            <div className="alert alert-danger">{error}</div>
                          </div>
                        )}

                        <div className="col-lg-12 mt-2 mb-0">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={changePassword}
                          >
                            Save Password
                          </button>
                        </div>
                      </div>
                    </form>
                    <button
                      type="button"
                      className="btn btn-link p-0 mt-2"
                      onClick={handleShowModal}
                    >
                      Forgot Password?
                    </button>
                  </div>

                  <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Forgot Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="mb-3">
                        <label className="form-label">
                          Your Email <span className="text-danger">*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          value={forgotEmail}
                          onChange={(e) => setForgotEmail(e.target.value)}
                          required
                        />
                      </div>
                      {forgotError && (
                        <p className="text-danger text-center">{forgotError}</p>
                      )}
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                      </Button>
                      <Button
                        variant="primary"
                        onClick={handleForgotPassword}
                        disabled={forgotLoading}
                      >
                        {forgotLoading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Send Reset Link"
                        )}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>

                <div className="mt-4 pt-2">
                  <h5 className="mb-0 text-danger">Delete Account :</h5>
                  <p className="mb-0 mt-4">
                    Do you want to delete the account? Please press the button
                    below "Delete Account"
                  </p>
                  <div className="mt-4">
                    <button className="btn btn-danger" onClick={handleDelete}>
                      Delete Account
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AdminFooter />
    </>
  );
}
