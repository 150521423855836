import React, { useState } from "react";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FaCopy } from "react-icons/fa";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
export default function PaymentModal({
  openPaymentModal,
  setOpenPaymentModal,
  appointmentId,
}) {
  const [amount, setAmount] = useState("");
  const [copied, setCopied] = useState(false);
  const [getLink, setGetLink] = useState(false);
  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };
  const navigate = useNavigate();
  const handleClose = () => setOpenPaymentModal(false);

  const paymentUrl = `${process.env.REACT_APP_URL}/razorpay/${amount}/${appointmentId}`;
  const handleCopy = () => {
    navigator.clipboard.writeText(paymentUrl); // Copy the URL to clipboard
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
      setGetLink(false);
    }, 2000); // Reset copied state after 2 seconds
  };

  return (
    <>
      <Modal
        show={openPaymentModal}
        onHide={handleClose}
        size="md"
        centered
        backdrop="static"

        // onClick={handleModalClick}
      >
        <Modal.Header closeButton={!copied}>
          <Modal.Title
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <h4 style={{ flexGrow: 1 }}>UPI Link Generator</h4>
            {copied && (
              <span
                style={{
                  color: "#6F7378",
                  fontWeight: "500",
                  fontSize: "1.6rem",
                  marginLeft: "auto", // Ensure it's pushed to the right
                }}
              >
                Copied!
              </span>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              backgroundColor: "#0000ff", // Blue background
              color: "white",
              padding: "20px",
              borderRadius: "15px",
              textAlign: "center",
              maxWidth: "100vw",
              margin: "auto",
            }}
          >
            <Form>
              <Form.Group className="mb-3">
                <Form.Label
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Amount = {amount ? amount : 0} $
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text
                    style={{
                      borderTopLeftRadius: "30px",
                      borderBottomLeftRadius: "30px",
                      backgroundColor: "#eaeaea",
                    }}
                  >
                    $
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    value={amount}
                    onChange={handleAmountChange}
                    placeholder="Enter amount"
                    style={{
                      borderTopRightRadius: "30px",
                      borderBottomRightRadius: "30px",
                    }}
                  />
                  <Button
                    variant="outline-primary" // Use outline variant to achieve a white background with blue border and text
                    onClick={() => {
                      const confirmPayment = window.confirm(
                        "Are you sure you want to make the payment?"
                      );
                      if (confirmPayment) {
                        navigate(`/razorpay/${amount}/${appointmentId}`);
                      }
                    }}
                    disabled={!amount}
                    style={{
                      borderRadius: "30px",
                      marginLeft: "10px",
                      backgroundColor: "palegreen", // White background
                      color: "blue", // Blue text color
                      borderColor: "blue", // Blue border
                      display: "flex", // To align the icon and text
                      alignItems: "center", // Vertically center the icon and text
                      padding: "8px 16px", // Adjust padding for a better appearance
                    }}
                  >
                    👉{""}
                    {""}
                    Pay
                  </Button>
                </InputGroup>
              </Form.Group>
              {/* <Form.Group
                className="mb-3"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Form.Label
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    whiteSpace: "nowrap",
                    marginRight: "10px", // Adds spacing between the label and input
                  }}
                >
                  Amount =
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text
                    style={{
                      borderTopLeftRadius: "30px",
                      borderBottomLeftRadius: "30px",
                      backgroundColor: "#eaeaea",
                    }}
                  >
                    $
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    value={amount}
                    onChange={handleAmountChange}
                    placeholder="Enter amount"
                    style={{
                      borderTopRightRadius: "30px",
                      borderBottomRightRadius: "30px",
                    }}
                  />
                </InputGroup>
              </Form.Group> */}

              <Button
                variant="dark"
                onClick={() => setGetLink(true)}
                style={{
                  borderRadius: "30px",
                  width: "100%",
                }}
                disabled={!amount}
              >
                {getLink ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      overflow: "hidden", // Hide overflowing content
                      whiteSpace: "nowrap", // Prevent the text from wrapping to the next line
                      textOverflow: "ellipsis", // Add ellipsis for overflow
                      maxWidth: "100%", // Ensure the text doesn’t overflow button width
                    }}
                    title={paymentUrl} // Tooltip to show the full link on hover
                  >
                    {paymentUrl.length > 45
                      ? `${paymentUrl.slice(0, 45)}...`
                      : paymentUrl}
                    <FaCopy onClick={() => handleCopy()} />
                  </div>
                ) : (
                  "Get Link"
                )}
              </Button>
            </Form>

            <p style={{ fontSize: "12px", marginTop: "20px" }}>
              By using our service, you agree to our <a>TOS</a> and{" "}
              <a>Privacy Policy</a>
            </p>

            <h4>#GoCashless</h4>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
