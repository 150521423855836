import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Button, Form, Table } from "react-bootstrap";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

import { FaTrashAlt, FaEdit } from "react-icons/fa";
import { getUserData, ShowError } from "../../_helper/helper";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import AdminFooter from "../../components/dashboard/adminFooter";
import ScrollTop from "../../components/scrollTop";

import { invoiceData } from "../../data/data";
import { ShowSucess } from "../../_helper/helper";
import Header from "../../components/CommonComponents/Header";

export default function DoctorTimetable() {
  const [show, setShow] = useState(false);
  const [slots, setSlots] = useState([
    {
      clinic_name: "",
      day_of_week: "",
      start_time: "",
      end_time: "",
      break_start: "",
      break_end: "",
      appointment_duration: "",
    },
  ]);
  const [isUpdating, setIsUpdating] = useState(false); // Track if we are in update mode
  const [timetableData, setTimetableData] = useState([]); // State to store timetable data
  const [currentIndex, setCurrentIndex] = useState(null); // Index of the item being updated
  const { role, assigned_doctor_id, doctor_id } = getUserData();
  const doctorId = role === "staff" ? assigned_doctor_id : doctor_id;
  const token = localStorage.getItem("accessToken");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [clinics, setClinics] = useState([]); // State for clinics

  const fetchClinics = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/clinics/${doctorId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setClinics(result.data);
    } catch (error) {
      console.error("Error fetching clinics:", error);
    } finally {
      setLoading(false);
    }
  };

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const fetchTimetable = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/timetable/display/${doctorId}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      if (result.status === "success") {
        setTimetableData(result.data); // Use result.data
      } else {
        // Handle API error message
        console.error("API Error:", result.message);
      }
    } catch (error) {
      console.error("Error fetching timetable:", error);
      // Handle error (e.g., show an error message)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTimetable();
    fetchClinics();
  }, [doctorId, token]);

  // const handleAddRow = () => {
  //   setSlots([
  //     ...slots,
  //     {
  //       clinic_name: "",
  //       day_of_week: "",
  //       start_time: "",
  //       end_time: "",
  //       break_start: "",
  //       break_end: "",
  //       appointment_duration: "",
  //     },
  //   ]);
  // };

  // const handleRemoveRow = (index) => {
  //   if (slots.length > 1) {
  //     const newSlots = slots.filter((_, i) => i !== index);
  //     setSlots(newSlots);
  //   }
  // };
  const [errors, setErrors] = useState(
    slots.map(() => ({
      clinic: "",
      clinic_name: "",
      day_of_week: "",
      start_time: "",
      end_time: "",
    }))
  );
  const handleAddRow = () => {
    setSlots([
      ...slots,
      {
        clinic_name: "",
        day_of_week: "",
        start_time: "",
        end_time: "",
        break_start: "",
        break_end: "",
        appointment_duration: "",
      },
    ]);
    setErrors([
      ...errors,
      {
        clinic: "",
        clinic_name: "",
        day_of_week: "",
        start_time: "",
        end_time: "",
      },
    ]);
  };
  const resetErrors = () => {
    setErrors(
      slots.map(() => ({
        clinic: "",
        clinic_name: "",
        day_of_week: "",
        start_time: "",
        end_time: "",
        break_start: "",
        break_end: "",
        appointment_duration: "",
      }))
    );
  };

  const handleRemoveRow = (index) => {
    const newSlots = slots.filter((_, i) => i !== index);
    const newErrors = errors.filter((_, i) => i !== index);

    setSlots(newSlots);
    setErrors(newErrors);
  };

  // const [errors, setErrors] = useState({
  //   clinic_name: "",
  //   day_of_week: "",
  //   start_time: "",
  //   end_time: "",
  // });
  const handleChange = (index, e) => {
    const { name, value } = e.target;

    setSlots((prevSlots) => {
      const updatedSlots = [...prevSlots];

      // Preserve the existing values of the slot, only updating the changed field
      updatedSlots[index] = {
        ...updatedSlots[index], // Keep other values intact
        [name]: value,
      };

      // If the field being changed is the clinic ID, update the address as well
      if (name === "clinic") {
        const selectedClinic = clinics.find(
          (clinic) => clinic.id === parseInt(value)
        );

        if (selectedClinic) {
          updatedSlots[index].clinic_name = selectedClinic.name;
          updatedSlots[index].clinic_address = selectedClinic.address;
        }
      }

      return updatedSlots;
    });

    setErrors((prevErrors) => {
      const newErrors = Array.isArray(prevErrors) ? [...prevErrors] : [];
      if (!value) {
        newErrors[index] = {
          ...newErrors[index],
          [name]: `${name} is required`,
        };
      } else {
        newErrors[index] = {
          ...newErrors[index],
          [name]: undefined,
        };
      }
      return newErrors;
    });
  };

  // const handleChange = (index, e) => {
  //   const { name, value } = e.target;

  //   // Update the state for the slots if needed
  //   setSlots((prevSlots) => {
  //     const updatedSlots = [...prevSlots];
  //     updatedSlots[index] = {
  //       ...updatedSlots[index],
  //       [name]: value,
  //     };
  //     return updatedSlots;
  //   });

  //   // Validate input and update errors
  //   setErrors((prevErrors) => {
  //     // Ensure prevErrors is an array
  //     const newErrors = Array.isArray(prevErrors) ? [...prevErrors] : [];
  //     // Example validation, replace with your own logic
  //     if (!value) {
  //       newErrors[index] = {
  //         ...newErrors[index],
  //         [name]: `${name} is required`,
  //       };
  //     } else {
  //       newErrors[index] = {
  //         ...newErrors[index],
  //         [name]: undefined, // Clear the error if valid
  //       };
  //     }
  //     return newErrors;
  //   });
  // };

  //   const handleChange = (index, event) => {
  //     const { name, value } = event.target;

  //     // Update the slot data
  //     const updatedSlots = [...slots];
  //     updatedSlots[index][name] = value;
  //     setSlots(updatedSlots);

  //     // Clear the specific error for this field if it has been filled
  //     if (value) {
  //         setErrors((prevErrors) => {
  //             const newErrors = [...prevErrors];
  //             newErrors[index][name] = ""; // Clear error for the specific field in the current row
  //             return newErrors;
  //         });
  //     }
  // };
  // const handleChange = (index, event) => {
  //   const { name, value } = event.target;

  //   // Update the slot data
  //   const updatedSlots = [...slots];
  //   updatedSlots[index][name] = value;
  //   setSlots(updatedSlots);

  //   // Clear the specific error for this field if it has been filled
  //   if (value) {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       [name]: "",
  //     }));
  //   }
  // };

  // const handleChange = (index, e) => {
  //   const { name, value } = e.target;
  //   const newSlots = [...slots];
  //   newSlots[index] = { ...newSlots[index], [name]: value };
  //   setSlots(newSlots);
  // };
  const convertMinutesToHHMMSS = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${String(hours).padStart(2, "0")}:${String(mins).padStart(
      2,
      "0"
    )}:00`;
  };
  const handleSubmit = async () => {
    // Reset errors for each slot
    const initialErrors = slots.map(() => ({
      clinic: "",
      clinic_name: "",
      day_of_week: "",
      start_time: "",
      end_time: "",
    }));

    let hasError = false;
    const newErrors = [...initialErrors];

    // Validate each slot
    slots.forEach((slot, index) => {
      if (!slot.clinic_name) {
        newErrors[index].clinic_name = "Required.";
        hasError = true;
      }
      if (!slot.clinic) {
        newErrors[index].clinic = "Required.";
        hasError = true;
      }
      if (!slot.day_of_week) {
        newErrors[index].day_of_week = "Required.";
        hasError = true;
      }
      if (!slot.start_time) {
        newErrors[index].start_time = "Required.";
        hasError = true;
      }
      if (!slot.end_time) {
        newErrors[index].end_time = "Required.";
        hasError = true;
      }
    });

    // Update state with the new errors
    setErrors(newErrors);

    // If there are errors, stop submission
    if (hasError) return;

    setLoading(true);
    try {
      const adjustedSlots = slots.map((slot) => ({
        clinic_name: slot.clinic_name,
        clinic_address: slot.clinic_address,
        day_of_week: slot.day_of_week,
        start_time: slot.start_time,
        end_time: slot.end_time,
        break_start: slot.break_start || null,
        break_end: slot.break_end || null,
        clinic_address: slot.clinic_address,
        appointment_duration: slot.appointment_duration
          ? convertMinutesToHHMMSS(Number(slot.appointment_duration))
          : convertMinutesToHHMMSS(5),
      }));

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/timetable/${doctorId}/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(adjustedSlots),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      ShowSucess("Clinic successfully created!");
      setShow(false);
      setTimeout(() => {
        fetchTimetable();
      }, 2000);
    } catch (error) {
      console.error("Error creating timetable:", error);
    } finally {
      setLoading(false);
    }
  };

  // const handleSubmit = async () => {
  //   let hasError = false;
  //   const newErrors = { clinic_name: "", day_of_week: "", start_time: "", end_time: "" };

  //   slots.forEach((slot) => {
  //     if (!slot.clinic_name) {
  //       newErrors.clinic_name = "Required.";
  //       hasError = true;
  //     }
  //     if (!slot.day_of_week) {
  //       newErrors.day_of_week = "Required.";
  //       hasError = true;
  //     }
  //     if (!slot.start_time) {
  //       newErrors.start_time = "Required.";
  //       hasError = true;
  //     }
  //     if (!slot.end_time) {
  //       newErrors.end_time = "Required.";
  //       hasError = true;
  //     }
  //   });

  //   setErrors(newErrors);

  //   if (hasError) return; // Stop submission if there are errors

  //   setLoading(true);
  //   try {
  //     const adjustedSlots = slots.map((slot) => ({
  //       clinic_name: slot.clinic_name,
  //       day_of_week: slot.day_of_week,
  //       start_time: slot.start_time,
  //       end_time: slot.end_time,
  //       break_start: slot.break_start || null,
  //       break_end: slot.break_end || null,
  //       appointment_duration: slot.appointment_duration ? convertMinutesToHHMMSS(Number(slot.appointment_duration)) : convertMinutesToHHMMSS(5),
  //     }));

  //     const response = await fetch(`${process.env.REACT_APP_API_URL}/timetable/${doctorId}/`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify(adjustedSlots),
  //     });

  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }

  //     ShowSucess("Clinic successfully created!");
  //     setShow(false);
  //     setTimeout(() => {
  //       fetchTimetable();
  //     }, 2000);
  //   } catch (error) {
  //     console.error("Error creating timetable:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleUpdate = async () => {
    const slot = slots[0];
    let hasError = false;
    const newErrors = {
      clinic_name: "",
      day_of_week: "",
      start_time: "",
      end_time: "",
    };

    if (!slot.clinic_name) {
      newErrors.clinic_name = "Clinic is required.";
      hasError = true;
    }
    if (!slot.day_of_week) {
      newErrors.day_of_week = "Day is required.";
      hasError = true;
    }
    if (!slot.start_time) {
      newErrors.start_time = "Start Time is required.";
      hasError = true;
    }
    if (!slot.end_time) {
      newErrors.end_time = "End Time is required.";
      hasError = true;
    }

    setErrors(newErrors);

    if (hasError) return; // Stop update if there are errors

    setLoading(true);

    try {
      const requestBody = {
        doctor: slot.doctor,
        clinic: slot.clinic,
        clinic_address: slot.clinic_address,
        clinic_name: slot.clinic_name,
        day_of_week: slot.day_of_week,
        start_time: slot.start_time,
        end_time: slot.end_time,
        break_start: slot.break_start || null,
        break_end: slot.break_end || null,
        appointment_duration: slot.appointment_duration
          ? convertMinutesToHHMMSS(Number(slot.appointment_duration))
          : null,
        id: slot.id,
        date: "2024-08-14",
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/timetable/updateanddelete/${slot.id}/`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.status === 200) {
        ShowSucess("Clinic successfully updated!");
        setShow(false);
        setTimeout(() => {
          fetchTimetable();
        }, 2000);
      } else {
        const errorMessage = await response.json();
        throw new Error(errorMessage.error || "Failed to update clinic");
      }
    } catch (error) {
      ShowError(error.message);
      console.error("Error updating timetable:", error);
    } finally {
      setLoading(false);
    }
  };

  const openDeleteConfirm = (id) => {
    setDeleteId(id);
    setConfirmDelete(true);
  };

  const handleDeleteConfirm = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/timetable/updateanddelete/${deleteId}/`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "An unexpected error occurred");
      }

      ShowSucess("Clinic successfully deleted!");
      setConfirmDelete(false);
      setDeleteId(null);
      setTimeout(() => {
        fetchTimetable();
      }, 2000);
    } catch (error) {
      console.error("Error deleting timetable:", error);
      ShowError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // const handleDeleteConfirm = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_URL}/timetable/updateanddelete/${deleteId}/`,
  //       {
  //         method: "DELETE",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     if (!response.ok) {
  //       const errorData = await response.json(); // Parse the JSON error response
  //       throw new Error(errorData.error || "An unexpected error occurred");
  //     }

  //     ShowSucess("Clinic successfully deleted!");
  //     setConfirmDelete(false);
  //     setTimeout(() => {
  //       fetchTimetable();
  //     }, 2000);
  //   } catch (error) {
  //     console.error("Error deleting timetable:", error);
  //     ShowError(error.message); // Display the dynamic error message
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const convertHHMMSSToMinutes = (hhmmss) => {
    if (!hhmmss) return "";
    const [hours, minutes] = hhmmss.split(":").map(Number);
    return hours * 60 + minutes;
  };
  const openUpdateModal = (index) => {
    setCurrentIndex(index);
    setIsUpdating(true);

    const slot = timetableData[index] || {}; // Use an empty object as a fallback
    setSlots([
      {
        ...slot,
        appointment_duration: convertHHMMSSToMinutes(
          slot.appointment_duration || "00:00:00"
        ), // Default if undefined
      },
    ]);

    setShow(true);
  };

  // const openUpdateModal = (index) => {
  //   setCurrentIndex(index);
  //   setIsUpdating(true);
  //   const slot = timetableData[index];
  //   setSlots([
  //     {
  //       ...slot,
  //       appointment_duration: convertHHMMSSToMinutes(slot.appointment_duration),
  //     },
  //   ]);
  //   setShow(true);
  // };

  const showBackButton = location.key !== "default";

  const formatTime = (time) => {
    if (!time) return "N/A"; // Return 'N/A' if time is null or undefined

    // Split and map to number, with fallback to zero if time is not valid
    const [hours = 0, minutes = 0] = time.split(":").map(Number);

    // Check if the values are valid numbers
    if (isNaN(hours) || isNaN(minutes)) return "Invalid Time";

    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert 24-hour to 12-hour
    const formattedMinutes = String(minutes).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes} ${period}`;
  };

  return (
    <>
      <Navbar
        navDark={true}
        manuClass="navigation-menu nav-left"
        containerClass="container"
      />
      <Modal
        show={confirmDelete}
        onHide={() => setConfirmDelete(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this clinic slot? This action cannot
          be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setConfirmDelete(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {loading && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
          }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <section className="bg-dashboard">
        <div className="container-fluid">
          <div className="row">
            <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
            <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
              {/* <h5 className="mb-0">Clinic Slots List</h5> */}
              <Header
                title="Clinic Timetable"
                onBackClick={() => navigate(-1)} // Go back to the previous page
                showBackButton={showBackButton}
              />
              <Button
                variant="primary"
                onClick={() => {
                  setIsUpdating(false);
                  setSlots([
                    {
                      clinic_name: "",
                      day_of_week: "",
                      start_time: "",
                      end_time: "",
                      break_start: "",
                      break_end: "",
                      appointment_duration: "",
                    },
                  ]);
                  setShow(true);
                }}
                style={{ marginTop: "20px" }}
              >
                Add Clinic Timetable
              </Button>
              <div className="row">
                <div className="col-12 mt-4 pt-2">
                  <div className="table-responsive shadow rounded">
                    <table className="table table-center bg-white mb-0">
                      <thead>
                        <tr>
                          <th className="border-bottom p-3">Clinic Name</th>
                          <th className="border-bottom p-3">Clinic Adress</th>
                          <th className="border-bottom p-3">Day</th>
                          <th className="text-center border-bottom p-3">
                            Start Time
                          </th>
                          <th className="text-center border-bottom p-3">
                            End Time
                          </th>
                          <th className="text-center border-bottom p-3">
                            Break Start
                          </th>
                          <th className="text-center border-bottom p-3">
                            Break End
                          </th>
                          <th className="text-center border-bottom p-3">
                            Appointment Duration
                          </th>
                          <th
                            className="text-end border-bottom p-3"
                            style={{ minWidth: "150px" }}
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        {timetableData.map((item, index) => (
                          <tr key={index}>
                            <td className="p-3">{item.clinic_name}</td>
                            <td className="p-3">{item.clinic_address}</td>
                            <td className="p-3">{item.day_of_week}</td>
                            <td className="text-center p-3">
                              {formatTime(item.start_time)}
                            </td>
                            <td className="text-center p-3">
                              {formatTime(item.end_time)}
                            </td>
                            <td className="text-center p-3">
                              {formatTime(item.break_start) || "N/A"}{" "}
                            </td>
                            <td className="text-center p-3">
                              {formatTime(item.break_end) || "N/A"}{" "}
                            </td>
                            {/* <td className="text-center p-3">{item.appointment_duration || "5"} </td> */}
                            <td className="text-center p-3">
                              {item.appointment_duration
                                ? `${convertHHMMSSToMinutes(
                                    item.appointment_duration
                                  )} minutes`
                                : "N/A"}
                            </td>
                            <td className="text-end p-3">
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-edit-${index.id}`}>
                                    Edit
                                  </Tooltip>
                                }
                              >
                                <Link
                                  onClick={() => openUpdateModal(index)}
                                  className="btn btn-icon btn-pills btn-soft-primary my-1"
                                >
                                  <FaEdit />
                                </Link>
                              </OverlayTrigger>{" "}
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-delete-${item.id}`}>
                                    Delete
                                  </Tooltip>
                                }
                              >
                                <Link
                                  onClick={() => openDeleteConfirm(item.id)}
                                  className="btn btn-icon btn-pills btn-soft-danger my-1"
                                >
                                  <FaTrashAlt />
                                </Link>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          setSlots([
            {
              clinic_name: "",
              day_of_week: "",
              start_time: "",
              end_time: "",
              break_start: "",
              break_end: "",
              appointment_duration: "",
            },
          ]);
          setIsUpdating(false);
          resetErrors([]);
        }}
        centered
        size="lg"
        backdrop="static"
        dialogClassName="modal-90w" // This class helps with responsive width
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isUpdating ? "Update Clinic Slot" : "Add Clinic Slot"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            <Table bordered>
              <thead>
                <tr>
                  <th>Clinic Name</th>
                  <th>Clinic Adress</th>
                  <th>Day</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Break Start</th>
                  <th>Break End</th>
                  <th>Appointment Duration</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {slots.map((slot, index) => (
                  <tr key={index}>
                    <td>
                      <Form.Control
                        as="select"
                        name="clinic" // Store clinic ID
                        value={slot?.clinic || ""} // Use clinic ID for the value
                        onChange={(e) => handleChange(index, e)}
                        style={{ width: "100px" }}
                      >
                        <option value="">Select Clinic</option>
                        {clinics.map((clinic) => (
                          <option key={clinic.id} value={clinic.id}>
                            {clinic.name}
                          </option>
                        ))}
                      </Form.Control>

                      {errors[index]?.clinic && (
                        <div className="text-danger">
                          {errors[index].clinic}
                        </div>
                      )}
                    </td>

                    <td>
                      <Form.Control
                        type="text"
                        name="clinic_address"
                        value={slot?.clinic_address || ""} // Auto-filled address
                        placeholder="Clinic Address"
                        readOnly
                        style={{ width: "150px" }}
                      />
                    </td>

                    {/* <td>
                      <Form.Control
                        as="select"
                        name="clinic_name"
                        value={slot?.clinic_name || ""} // Use optional chaining
                        onChange={(e) => handleChange(index, e)}
                        placeholder="Select Clinic"
                        style={{ width: '100px' }} 
                      >
                        <option value="">Select Clinic</option>
                        {clinics.map((clinic) => (
                          <option key={clinic.id} value={clinic.name}>
                            {clinic.name}
                          </option>
                        ))}
                      </Form.Control>
                      {errors[index]?.clinic_name && (
                        <div className="text-danger">
                          {errors[index].clinic_name}
                        </div>
                      )}
                    </td> */}
                    <td>
                      <Form.Select
                        name="day_of_week"
                        value={slot?.day_of_week || ""} // Handle missing day_of_week
                        onChange={(e) => handleChange(index, e)}
                        style={{ width: "100px" }}
                      >
                        <option value="">Select Day</option>
                        {daysOfWeek.map((day) => (
                          <option key={day} value={day}>
                            {day}
                          </option>
                        ))}
                      </Form.Select>
                      {errors[index]?.day_of_week && (
                        <div className="text-danger">
                          {errors[index].day_of_week}
                        </div>
                      )}
                    </td>
                    <td>
                      <Form.Control
                        type="time"
                        name="start_time"
                        value={slot?.start_time || ""} // Handle missing start_time
                        onChange={(e) => handleChange(index, e)}
                      />
                      {errors[index]?.start_time && (
                        <div className="text-danger">
                          {errors[index].start_time}
                        </div>
                      )}
                    </td>
                    <td>
                      <Form.Control
                        type="time"
                        name="end_time"
                        value={slot?.end_time || ""} // Handle missing end_time
                        onChange={(e) => handleChange(index, e)}
                      />
                      {errors[index]?.end_time && (
                        <div className="text-danger">
                          {errors[index].end_time}
                        </div>
                      )}
                    </td>
                    <td>
                      <Form.Control
                        type="time"
                        name="break_start"
                        value={slot.break_start}
                        onChange={(e) => handleChange(index, e)}
                        placeholder="Break Start"
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="time"
                        name="break_end"
                        value={slot.break_end}
                        onChange={(e) => handleChange(index, e)}
                        placeholder="Break End"
                      />
                    </td>
                    <td>
                      <Form.Control
                        // type="time"
                        type="number"
                        name="appointment_duration"
                        value={slot.appointment_duration}
                        onChange={(e) => handleChange(index, e)}
                        placeholder="Appointment Duration"
                      />
                    </td>
                    <td>
                      {index > 0 && ( // Conditional rendering for the Remove button
                        <Button
                          variant="danger"
                          onClick={() => handleRemoveRow(index)}
                        >
                          Remove
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <Button
            variant="secondary"
            onClick={handleAddRow}
            style={{ marginTop: "10px" }}
          >
            Add More
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShow(false);
              setSlots([
                {
                  clinic_name: "",
                  day_of_week: "",
                  start_time: "",
                  end_time: "",
                  break_start: "",
                  break_end: "",
                  appointment_duration: "",
                },
              ]);
              setIsUpdating(false);
              resetErrors([]);
            }}
          >
            Close
          </Button>
          {isUpdating ? (
            <Button variant="primary" onClick={handleUpdate}>
              Update
            </Button>
          ) : (
            <Button variant="primary" onClick={handleSubmit}>
              Submit
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <ScrollTop />
      <AdminFooter />
    </>
  );
}
