import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import RegistrationDoctorForm from "../components/RegisterDoctor/RegistrationDoctorForm";
import logoDark from "../assets/images/logo-dark.png";
import logo from "../assets/images/healthBridge_transparent_logo.png";

import logoLight from "../assets/images/logo-light.png";
import dr1 from "../assets/images/doctors/01.jpg";

import Offcanvas from "react-bootstrap/Offcanvas";
import image from "../assets/images/mobile-app.svg";
import { getUserData, ShowError, ShowSucess } from "../_helper/helper";
import {
  FiSettings,
  FiSearch,
  GrDashboard,
  LiaSignOutAltSolid,
  FiShoppingCart,
  FiDribbble,
  RiBehanceLine,
  FaFacebookF,
  FiInstagram,
  FiTwitter,
  LuMail,
  LuGlobe,
  FaQuestionCircle,
} from "../assets/icons/vander";
import { doctorDetails } from "../store/doctor/actions";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ImageWithFallback } from "./CommonComponents/CommonFunction";
import { getPatientDetails } from "../store/patient/actions";

export default function Navbar({ navDark, manuClass, containerClass }) {
  let [show, setShow] = useState(false);
  let [showTwo, setShowTwo] = useState(false);
  let [scroll, setScroll] = useState(false);
  let [isMenu, setisMenu] = useState(false);
  let [modal, setModal] = useState(false);
  const [userRole, setUserRole] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storedUserRole = localStorage.getItem("userRole");
  const assigned_doctor_id = localStorage.getItem("assigndoctorId");
  const userData = JSON.parse(localStorage.getItem("userData"));
  console.log("userRole", userRole);
  const doctor_id = localStorage.getItem("doctorId");
  const doctorId = userRole === "staff" ? assigned_doctor_id : doctor_id;
  const { doctorDetailsResults } = useSelector((state) => state.DoctorReducer);
  const { patientDetailsResults } = useSelector(
    (state) => state.PatientReducer
  );
  const newPatientDetails = useMemo(
    () => JSON.stringify(patientDetailsResults),
    [patientDetailsResults]
  );

  const { image } = doctorDetailsResults;
  const profileImg = image || patientDetailsResults?.image;
  const apiUrl = process.env.REACT_APP_API_URL;
  const [imageUrl, setImageUrl] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    comment: "",
    phoneno: "",
  });

  const [errors, setErrors] = useState({});
  const clearForm = () => {
    setFormData({
      name: "",
      email: "",
      phoneNo: "",
      subject: "",
      comment: "",
    });
    setErrors({}); // Clear any existing errors
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" })); // Clear error when user types
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Your name is required.";
    if (!formData.email) {
      newErrors.email = "Your email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email format is invalid.";
    }
    if (!formData.phoneno) {
      newErrors.phoneno = "Your phone number is required.";
    } else if (!/^\d{10}$/.test(formData.phoneno)) {
      newErrors.phoneno = "Phone number must be 10 digits.";
    }
    if (!formData.comment) newErrors.comment = "Comments are required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // No errors
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Stop if validation fails
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/queries/create/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...formData,
            phoneno: Number(formData.phoneno), // Convert phoneno to number for submission
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      ShowSucess("Query submitted successfully!");

      // Clear all form fields
      setFormData({
        name: "",
        email: "",
        phoneno: "",
        subject: "",
        comment: "",
      });
    } catch (error) {
      console.error("Error submitting query:", error);
      ShowError("Failed to submit query. Please try again.");
    }
  };

  useEffect(() => {
    // Retrieve the image URL from local storage
    const storedImageUrl = localStorage.getItem("profileImage");
    setImageUrl(storedImageUrl); // Set the image URL in state
  }, []);

  useEffect(() => {
    // const doctorid = localStorage.getItem("doctorId");
    if (
      doctorDetailsResults &&
      Object.keys(doctorDetailsResults)?.length === 0 &&
      doctorId &&
      doctorId !== "null"
      // doctorid &&
      // userRole === "doctor"
    ) {
      dispatch(doctorDetails(doctorId));
    }
  }, [doctorId, doctorDetailsResults]);
  useEffect(() => {
    const userRole = localStorage.getItem("userRole");

    // const doctorid = localStorage.getItem("doctorId");
    if (
      userRole === "patient" &&
      newPatientDetails &&
      Object.keys(JSON.parse(newPatientDetails))?.length === 0
    ) {
      dispatch(getPatientDetails());
    }
  }, [newPatientDetails]);
  useEffect(() => {
    if (storedUserRole) {
      setUserRole(storedUserRole);
    }
  }, [storedUserRole]);
  let handleClose = () => {
    setShow(false);
    setFormData({
      name: "",
      email: "",
      phoneno: "",
      subject: "",
      comment: "",
    });
  };
  // let handleClose = () => setShow(false);
  let handleShow = () => setShow(true);
  const handleLoginClick = () => {
    navigate("/login");
  };
  const handleSignupclick = () => {
    navigate("/signup");
  };
  // let handleCloseTwo = () => setShowTwo(false);
  let handleCloseTwo = () => {
    setShowTwo(false);
    setFormData({
      name: "",
      email: "",
      phoneno: "",
      subject: "",
      comment: "",
    });
  };
  let handleShowTwo = () => setShowTwo(true);

  let [manu, setManu] = useState("");
  let location = useLocation();

  let toggleMenu = () => {
    setisMenu(!isMenu);
    if (document.getElementById("navigation")) {
      const anchorArray = Array.from(
        document.getElementById("navigation").getElementsByTagName("a")
      );
      anchorArray.forEach((element) => {
        element.addEventListener("click", (elem) => {
          const target = elem.target.getAttribute("href");
          if (target !== "") {
            if (elem.target.nextElementSibling) {
              var submenu = elem.target.nextElementSibling.nextElementSibling;
              submenu.classList.toggle("open");
            }
          }
        });
      });
    }
  };
  const [userEmail, setUserEmail] = useState("");
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastName] = useState("");
  const [authToken, setAuthToken] = useState("");
  useEffect(() => {
    setUserEmail(localStorage.getItem("userEmail") || "");
    setFirstname(localStorage.getItem("firstName") || "");
    setLastName(localStorage.getItem("lastName") || "");
    setAuthToken(localStorage.getItem("authToken") || "");
  }, []);
  const { user, logout } = useAuth0();
  const handleLogout = () => {
    console.log("ababababaa", window.location.href);
    // return
    logout({ returnTo: window.location.href });

    localStorage.removeItem("authToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userRole");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("profileImage");
    localStorage.removeItem('health_provider_id')
  };
  const id = localStorage.getItem("userId");

  const modalRef = useRef(null); // Ref for the modal
  const logoutRef = useRef(null); // Ref for the logout button
  useEffect(() => {
    // Set current menu based on the pathname
    let current = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1
    );
    setManu(current);

    // Handle scroll behavior
    const handleScroll = () => setScroll(window.scrollY > 50);
    window.addEventListener("scroll", handleScroll);
    window.scrollTo(0, 0);

    return () => {
      // document.removeEventListener("mousedown", closeModal);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const imageStyle = {
    width: windowWidth < 768 ? "45px" : "50px",
    height: windowWidth < 768 ? "45px" : "50px",
    borderRadius: "50%",
    objectFit: "cover",
  };
  const imageStyle1 = {
    width: windowWidth < 768 ? "35px" : "38px",
    height: windowWidth < 768 ? "40px" : "40px",
    borderRadius: "50%",
    objectFit: "cover",
  };

  return (
    <header
      id="topnav"
      className={`${scroll ? "nav-sticky" : ""} navigation sticky`}
    >
      <div className={containerClass}>
        <div>
          {navDark === true ? (
            <Link className="logo" to="/">
              <img
                // src={logoDark}
                src={logo}
                height="50"
                className="logo-light-mode"
                alt=""
              />
              <img src={logo} height="50" className="logo-dark-mode" alt="" />
            </Link>
          ) : (
            <Link className="logo" to="/">
              <span className="logo-light-mode">
                <img src={logo} className="l-dark" height="50" alt="" />
                <img src={logo} className="l-light" height="50" alt="" />
              </span>
              <img src={logo} height="50" className="logo-dark-mode" alt="" />
            </Link>
          )}
        </div>

        <div className="menu-extras">
          <div className="menu-item">
            <Link
              to="#"
              className={`navbar-toggle ${isMenu ? "open" : ""}`}
              id="isToggle"
              onClick={() => toggleMenu()}
            >
              <div className="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </Link>
          </div>
        </div>

        <ul className="dropdowns list-inline mb-0">
          {!userRole && (
            <li className="list-inline-item mb-0">
              <button
                type="button"
                className="btn btn-primary btn-sm"
                style={{ marginRight: "10px" }}
                onClick={handleLoginClick}
              >
                Login & Signup
              </button>
            </li>
          )}

          <li className="list-inline-item mb-0">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-top">Ask Queries</Tooltip>}
            >
              <Link to="#" onClick={handleShowTwo}>
                <div className="btn btn-icon btn-pills btn-primary">
                  <FaQuestionCircle className="fea icon-sm" />
                </div>
              </Link>
            </OverlayTrigger>
          </li>
          <Offcanvas show={showTwo} onHide={handleCloseTwo} placement="end">
            <Offcanvas.Header
              closeButton
              className="offcanvas-header p-4 border-bottom"
            >
              <h5 id="offcanvasRightLabel" className="mb-0">
                <img src={logo} height="50" className="light-version" alt="" />
                <img src={logo} height="50" className="dark-version" alt="" />
              </h5>
            </Offcanvas.Header>

            <Offcanvas.Body>
              <div className="row">
                <div className="col-12">
                  <div>
                    <h5 className="my-4">Get in touch!</h5>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="form-label">
                              Your Name <span className="text-danger">*</span>
                            </label>
                            <input
                              name="name"
                              id="name"
                              type="text"
                              className="form-control border rounded"
                              placeholder="First Name :"
                              value={formData.name}
                              onChange={handleChange}
                            />
                            {errors.name && (
                              <div className="text-danger">{errors.name}</div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="form-label">
                              Your Email <span className="text-danger">*</span>
                            </label>
                            <input
                              name="email"
                              id="email"
                              type="email"
                              className="form-control border rounded"
                              placeholder="Your email :"
                              value={formData.email}
                              onChange={handleChange}
                            />
                            {errors.email && (
                              <div className="text-danger">{errors.email}</div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="form-label">
                              Your Phone Number{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              name="phoneno"
                              id="phoneno"
                              type="tel"
                              className="form-control border rounded"
                              placeholder="Your phone number :"
                              value={formData.phoneno}
                              onChange={handleChange}
                            />
                            {errors.phoneno && (
                              <div className="text-danger">
                                {errors.phoneno}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="form-label">Subject</label>
                            <input
                              name="subject"
                              id="subject"
                              className="form-control border rounded"
                              placeholder="Your subject :"
                              value={formData.subject}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="form-label">
                              Comments <span className="text-danger">*</span>
                            </label>
                            <textarea
                              name="comment"
                              id="comment"
                              rows="4"
                              className="form-control border rounded"
                              placeholder="Your Message :"
                              value={formData.comment}
                              onChange={handleChange}
                            ></textarea>
                            {errors.comment && (
                              <div className="text-danger">
                                {errors.comment}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <button
                            type="submit"
                            id="submit"
                            name="send"
                            className="btn btn-primary"
                          >
                            Send Message
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>

          <Offcanvas
            show={show}
            onHide={handleClose}
            placement="top"
            style={{ height: "250px" }}
          >
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Header closeButton></Offcanvas.Header>

            <Offcanvas.Body className="pb-3">
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="text-center">
                      <h4>Search now.....</h4>
                      <div className="subcribe-form mt-4">
                        <form>
                          <div className="mb-0">
                            <input
                              type="text"
                              id="help"
                              name="name"
                              className="border rounded-pill"
                              required=""
                              placeholder="Search"
                            />
                            <button
                              type="submit"
                              className="btn btn-pills btn-primary"
                            >
                              Search
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
          {userRole && (
            <li className="list-inline-item mb-0 ms-1">
              <div className="dropdown dropdown-primary">
                {user ? (
                  <button
                    type="button"
                    className="btn btn-pills btn-soft-primary dropdown-toggle p-0"
                    onClick={() => setModal(!modal)}
                  >
                    <img
                      src={user.picture}
                      className="avatar avatar-ex-small rounded-circle"
                      alt=""
                    />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-pills btn-soft-primary dropdown-toggle p-0"
                    onClick={() => setModal(!modal)}
                  >
                    <ImageWithFallback
                      src={
                        profileImg ||
                        (userData?.user.patient_image
                          ? `${apiUrl}${userData.user.patient_image}`
                          : imageUrl) ||
                        dr1
                      }
                      defaultSrc={dr1}
                      alt={""}
                      style={imageStyle1}
                    />
                  </button>
                )}
                <div
                  className={`${
                    modal === true ? "show" : ""
                  } dropdown-menu dd-menu shadow border-0 mt-3 py-3`}
                  style={{ minWidth: "200px", transform: "translateX(-50%)" }} // Moves the dropdown 50% left

                  // className={`${
                  //   modal === true ? "show" : ""
                  // } dropdown-menu dd-menu dropdown-menu-end shadow border-0 mt-3 py-3`}
                  // style={{ minWidth: "100px" }}
                >
                  <Link
                    className="dropdown-item d-flex align-items-center text-dark"
                    to="#"
                  >
                    {user ? (
                      <>
                        <ImageWithFallback
                          src={user.picture}
                          className="avatar avatar-md-sm rounded-circle border shadow"
                          alt=""
                          style={imageStyle}
                        />

                        <div className="flex-1 ms-2">
                          <span className="d-block mb-1">{user.name}</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <ImageWithFallback
                          src={
                            profileImg ||
                            imageUrl ||
                            (userData?.user?.patient_image
                              ? `${apiUrl}${userData.user.patient_image}`
                              : dr1)
                          }
                          defaultSrc={dr1}
                          alt={""}
                          style={imageStyle}
                        />

                        <div className="flex-1 ms-2">
                          <span className="d-block mb-1">
                            {userData?.user?.first_name}{" "}
                            {userData?.user?.last_name}
                          </span>
                        </div>
                      </>
                    )}
                  </Link>
                  {userRole === "patient" && (
                    <Link
                      className="dropdown-item text-dark"
                      to={`/patient-profile/${id}`}
                    >
                      {" "}
                      <span className="mb-0 d-inline-block me-1">
                        <FiSettings className="align-middle h6 mb-0" />
                      </span>{" "}
                      Profile
                    </Link>
                  )}
                  {userRole === "health_provider" && (
                    <Link
                      className="dropdown-item text-dark"
                      to={`/healthprovider-profile-setting`}
                    >
                      {" "}
                      <span className="mb-0 d-inline-block me-1">
                        <FiSettings className="align-middle h6 mb-0" />
                      </span>{" "}
                      Profile Setting
                    </Link>
                  )}
                  {userRole === "staff" && (
                    <Link
                      className="dropdown-item text-dark"
                      to={`/doctor-profile-setting`}
                    >
                      {" "}
                      <span className="mb-0 d-inline-block me-1">
                        <FiSettings className="align-middle h6 mb-0" />
                      </span>{" "}
                      Profile Settings
                    </Link>
                  )}
                  {userRole === "doctor" && (
                    <Link
                      className="dropdown-item text-dark"
                      to={`/doctor-profile-setting`}
                    >
                      {" "}
                      <span className="mb-0 d-inline-block me-1">
                        <FiSettings className="align-middle h6 mb-0" />
                      </span>{" "}
                      Profile Settings
                    </Link>
                  )}
                  <div className="dropdown-divider border-top"></div>

                  <Link
                    className={`dropdown-item ${
                      manu === "logout" ? "active" : ""
                    }`}
                    to="#"
                    onClick={() => {
                      console.log("Logging out");
                      handleLogout();
                    }}
                  >
                    <LiaSignOutAltSolid /> Logout
                  </Link>
                </div>
              </div>
            </li>
          )}
        </ul>

        <div id="navigation" style={{ display: isMenu ? "block" : "none" }}>
          <ul className={manuClass}>
            {/* {userRole && (
              <li
                className={`${
                  ["doctor-dashboard", "patient-dashboard"].includes(manu)
                    ? "active"
                    : ""
                } has-submenu parent-menu-item`}
              >
                <Link
                  to={
                    userRole === "doctor" || userRole === "staff"
                      ? "/doctor-dashboard"
                      : "/patient-dashboard"
                  }
                >
                  Dashboard
                </Link>
              </li>
            )} */}
            {userRole && (
  <li
    className={`${
      ["doctor-dashboard", "patient-dashboard", "health-provider"].includes(manu)
        ? "active"
        : ""
    } has-submenu parent-menu-item`}
  >
    <Link
      to={
        userRole === "doctor" || userRole === "staff"
          ? "/doctor-dashboard"
          : userRole === "health_provider"
          ? "/healthprovider"  // New condition for health_provider role
          : "/patient-dashboard"
      }
    >
      {userRole === "health_provider" ? "Health Provider" : "Dashboard"}  {/* Change text for health_provider role */}
    </Link>
  </li>
)}


            <li
              className={`${
                [
                  "doctor-dashboard",
                  "doctor-appointment",
                  "patient-list",
                  "doctor-schedule",
                  "invoices",
                  "patient-review",
                  "doctor-messages",
                  "doctor-profile",
                  "doctor-profile-setting",
                  "doctor-chat",
                  "login",
                  "signup",
                  "forgot-password",
                  "doctor-team-one",
                  "doctor-team-two",
                  "doctor-team-three",
                ].includes(manu)
                  ? "active"
                  : ""
              } has-submenu parent-parent-menu-item`}
            >
              {userRole === "staff" ? (
                <Link to="/doctor-search" className="sub-menu-item">
                  Doctors Listing
                </Link>
              ) : (
                <>
                  {userRole === "patient" && (
                    <Link to="/doctor-search" className="sub-menu-item">
                      Doctors Listing
                    </Link>
                    // </li>
                  )}
                  {userRole === "doctor" && (
                    <Link to="/doctor-search" className="sub-menu-item">
                      Doctors Listing
                    </Link>
                    // </li>
                  )}
                </>
              )}
            </li>
            {userRole === "patient" && (
              <li
                className={`${
                  [
                    "patient-dashboard",
                    "patient-profile",
                    "booking-appointment",
                    "patient-invoice",
                  ].includes(manu)
                    ? "active"
                    : ""
                } has-submenu parent-menu-item`}
              >
                <Link to={`/patient-profile/${id}`}>Profile</Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </header>
  );
}
