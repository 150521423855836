// import React from 'react';
// import { Link } from 'react-router-dom';
// import { FiArrowRight } from 'react-icons/fi';

// function JoinHealthBridge() {
//   return (
//     <div className="join-healthbridge">
//       <div className="content">
//         <h4 className="title mb-4">Join healthBridge</h4>
//         <p className="description mb-4">
//           Become a part of our growing network. Whether you're a patient looking for quality care or a doctor aiming to expand your reach, healthBridge is here to support you.
//         </p>
//         <Link to="/signup" className="cta-button">
//           Sign Up Now <FiArrowRight className="icon" />
//         </Link>
//       </div>

//       <style jsx>{`
//         .join-healthbridge {
//           background-color: #f5f5f5; /* Light gray background */
//           padding: 5rem 0;
//           text-align: center;
//           color: #333;
//         }
//         .content {
//           max-width: 800px;
//           margin: 0 auto;
//           padding: 0 1.5rem;
//         }
//         .title {
//           font-size: 2.5rem;
//           font-weight: bold;
//           margin-bottom: 1rem;
//         }
//         .description {
//           font-size: 1.25rem;
//           margin-bottom: 2rem;
//         }
//         .cta-button {
//           display: inline-flex;
//           align-items: center;
//           background-color: #007bff; /* Bold button color */
//           color: #fff;
//           padding: 1rem 2rem;
//           font-size: 1.25rem;
//           border-radius: 0.5rem;
//           text-decoration: none;
//           transition: background-color 0.3s ease;
//           position: relative;
//         }
//         .cta-button:hover {
//           background-color: #0056b3; /* Darker button color on hover */
//         }
//         .icon {
//           margin-left: 0.5rem;
//           font-size: 1.5rem;
//         }
//       `}</style>
//     </div>
//   );
// }

// export default JoinHealthBridge;


// import React from 'react';
// import { Link } from 'react-router-dom';
// import { FiArrowRight } from 'react-icons/fi';

// function JoinHealthBridge() {
//   return (
//     <div className="join-healthbridge">
//       <div className="content">
//         <h4 className="title mb-4">Join healthBridge</h4>
//         <p className="text-muted para-desc mb-0">
//           Become a part of our growing network. Whether you're a patient looking for quality care or a doctor aiming to expand your reach, healthBridge is here to support you.
//         </p>
//         <Link to="/signup" className="cta-button">
//           Sign Up Now <FiArrowRight className="icon" />
//         </Link>
//       </div>

//       <style jsx>{`
//         .join-healthbridge {
//           background-color: #f8f9fa; /* Matching background color from FeatureSection */
//           padding: 5rem 0;
//           text-align: center;
//           color: #333;
//         }
//         .content {
//           max-width: 800px;
//           margin: 0 auto;
//           padding: 0 1.5rem;
//         }
      
        
//         .cta-button {
//           display: inline-flex;
//           align-items: center;
//           background-color: #007bff; /* Bold button color */
//           color: #fff;
//           padding: 1rem 2rem;
//           font-size: 1.25rem;
//           border-radius: 0.5rem;
//           text-decoration: none;
//           transition: background-color 0.3s ease;
//           position: relative;
//         }
//         .cta-button:hover {
//           background-color: #0056b3; /* Darker button color on hover */
//         }
//         .icon {
//           margin-left: 0.5rem;
//           font-size: 1.5rem;
//         }
//       `}</style>
//     </div>
//   );
// }

// export default JoinHealthBridge;

// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import { FiArrowRight } from 'react-icons/fi';
// import RegistrationDoctorForm from '../RegisterDoctor/RegistrationDoctorForm';

// function JoinHealthBridge() {
//   const [registerDrModal, setRegisterDrModal] = useState(false);

//   return (
//     <div className="join-healthbridge">
//       <div className="content">
//         <h4 className="title mb-4">Join healthBridge</h4>
//         <p className="text-muted para-desc mb-4">
//           Become a part of our growing network. Whether you're a patient looking for quality care or a doctor aiming to expand your reach, healthBridge is here to support you.
//         </p>
//         <Link 
//          onClick={(e) => {
//           e.preventDefault(); // Prevent the default navigation
//           setRegisterDrModal(true); // Open the modal
//         }}
        
//         className="cta-button">
//           Sign Up Now <FiArrowRight className="icon" />
//         </Link>
//       </div>
//       <RegistrationDoctorForm
//         registerDrModal={registerDrModal}
//         setRegisterDrModal={setRegisterDrModal}
//       />
//       <style jsx>{`
//         .join-healthbridge {
//           background-color: #f8f9fa; /* Light background color */
//           padding: 5rem 0;
//           text-align: center;
//           color: #333;
//         }
//         .content {
//           max-width: 800px;
//           margin: 0 auto;
//           padding: 0 1.5rem;
//           display: flex;
//           flex-direction: column;
//           align-items: center;
//           justify-content: center;
//           height: 100%; /* Ensure content is vertically centered */
//         }
       
//         .text-muted {
//           font-size: .9rem;
//           margin-bottom: 2rem;
//           color: #666; /* Muted text color */
//         }
//         .cta-button {
//           display: inline-flex;
//           align-items: center;
//           background-color: #007bff; /* Button color */
//           color: #fff;
//           padding: 1rem 2rem;
//           font-size: 1.25rem;
//           border-radius: 0.5rem;
//           text-decoration: none;
//           transition: background-color 0.3s ease, transform 0.3s ease;
//           position: relative;
//           box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adding shadow for depth */
//         }
//         .cta-button:hover {
//           background-color: #0056b3; /* Darker color on hover */
//           transform: translateY(-2px); /* Slight lift effect on hover */
//         }
//         .icon {
//           margin-left: 0.5rem;
//           font-size: 1.5rem;
//         }
//       `}</style>
//     </div>
//   );
// }

// export default JoinHealthBridge;



// import React, { useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { FiArrowRight } from 'react-icons/fi';
// import RegistrationDoctorForm from '../RegisterDoctor/RegistrationDoctorForm';

// function JoinHealthBridge() {
//   const [registerDrModal, setRegisterDrModal] = useState(false);
//   const [showPrompt, setShowPrompt] = useState(false); // State to control the prompt
//   const navigate = useNavigate(); // For navigation to signup page

//   // Function to handle the "Join as Moderator" action
//   const handleModeratorClick = () => {
//     setShowPrompt(false); // Close the prompt
//     localStorage.setItem('moderatorjoin', 'moderatorjoin'); // You can set any key-value pair here

//     navigate('/signup'); // Navigate to the signup page for Moderator
//   };

//   // Function to handle the "Join as Doctor" action
//   const handleDoctorClick = () => {
//     setShowPrompt(false); // Close the prompt
//     setRegisterDrModal(true); // Open the modal for Doctor registration
//   };

//   return (
//     <div className="join-healthbridge">
//       <div className="content">
//         <h4 className="title mb-4">Join healthBridge</h4>
//         <p className="text-muted para-desc mb-4">
//           Become a part of our growing network. Whether you're a patient looking for quality care or a doctor aiming to expand your reach, healthBridge is here to support you.
//         </p>
//         <Link 
//           onClick={(e) => {
//             e.preventDefault(); // Prevent the default navigation
//             setShowPrompt(true); // Show the prompt asking for user role
//           }}
//           className="cta-button"
//         >
//           Sign Up Now <FiArrowRight className="icon" />
//         </Link>
//       </div>

//       {/* Popup prompt for role selection */}
//       {showPrompt && (
//         <div className="role-prompt">
//           <div className="popup-content">
//             <h5>Choose your role</h5>
//             <button onClick={handleModeratorClick} className="role-button">Join as Moderator</button>
//             <button onClick={handleDoctorClick} className="role-button">Join as Doctor</button>
//           </div>
//         </div>
//       )}

//       {/* Registration form modal */}
//       <RegistrationDoctorForm
//         registerDrModal={registerDrModal}
//         setRegisterDrModal={setRegisterDrModal}
//       />

//       <style jsx>{`
//         .join-healthbridge {
//           background-color: #f8f9fa;
//           padding: 5rem 0;
//           text-align: center;
//           color: #333;
//         }
//         .content {
//           max-width: 800px;
//           margin: 0 auto;
//           padding: 0 1.5rem;
//           display: flex;
//           flex-direction: column;
//           align-items: center;
//           justify-content: center;
//           height: 100%;
//         }
//         .text-muted {
//           font-size: .9rem;
//           margin-bottom: 2rem;
//           color: #666;
//         }
//         .cta-button {
//           display: inline-flex;
//           align-items: center;
//           background-color: #007bff;
//           color: #fff;
//           padding: 1rem 2rem;
//           font-size: 1.25rem;
//           border-radius: 0.5rem;
//           text-decoration: none;
//           transition: background-color 0.3s ease, transform 0.3s ease;
//           position: relative;
//           box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
//         }
//         .cta-button:hover {
//           background-color: #0056b3;
//           transform: translateY(-2px);
//         }
//         .icon {
//           margin-left: 0.5rem;
//           font-size: 1.5rem;
//         }

//         /* Style for the role selection popup */
//         .role-prompt {
//           position: fixed;
//           top: 0;
//           left: 0;
//           right: 0;
//           bottom: 0;
//           background: rgba(0, 0, 0, 0.5);
//           display: flex;
//           justify-content: center;
//           align-items: center;
//           z-index: 9999;
//         }
//         .popup-content {
//           background: #fff;
//           padding: 2rem;
//           border-radius: 0.5rem;
//           text-align: center;
//           max-width: 400px;
//           width: 100%;
//         }
//         .role-button {
//           display: inline-block;
//           margin: 10px;
//           padding: 1rem 2rem;
//           background-color: #007bff;
//           color: #fff;
//           border-radius: 0.5rem;
//           border: none;
//           cursor: pointer;
//           font-size: 1.25rem;
//           transition: background-color 0.3s ease;
//         }
//         .role-button:hover {
//           background-color: #0056b3;
//         }
//       `}</style>
//     </div>
//   );
// }

// export default JoinHealthBridge;



import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FiArrowRight, FiX } from 'react-icons/fi';
import RegistrationDoctorForm from '../RegisterDoctor/RegistrationDoctorForm';

function JoinHealthBridge() {
  const [registerDrModal, setRegisterDrModal] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false); // State to control the prompt
  const navigate = useNavigate(); // For navigation to signup page

  // Function to handle the "Join as Moderator" action
  const handleModeratorClick = () => {
    setShowPrompt(false); // Close the prompt
    localStorage.setItem('moderatorjoin', 'moderatorjoin'); // You can set any key-value pair here
    navigate('/signup'); // Navigate to the signup page for Moderator
  };

  // Function to handle the "Join as Doctor" action
  const handleDoctorClick = () => {
    setShowPrompt(false); // Close the prompt
    setRegisterDrModal(true); // Open the modal for Doctor registration
  };

  // Function to close the prompt
  const handleClosePrompt = () => {
    setShowPrompt(false); // Close the prompt
  };

  return (
    <div className="join-healthbridge">
      <div className="content">
        <h4 className="title mb-4">Join healthBridge</h4>
        <p className="text-muted para-desc mb-4">
          Become a part of our growing network. Whether you're a patient looking for quality care or a doctor aiming to expand your reach, healthBridge is here to support you.
        </p>
        <Link 
          onClick={(e) => {
            e.preventDefault(); // Prevent the default navigation
            setShowPrompt(true); // Show the prompt asking for user role
          }}
          className="cta-button"
        >
          Sign Up Now <FiArrowRight className="icon" />
        </Link>
      </div>

      {/* Popup prompt for role selection */}
      {showPrompt && (
        <div className="role-prompt">
          <div className="popup-content">
            <button className="close-btn" onClick={handleClosePrompt}>
              <FiX />
            </button>
            <h5>Choose your role</h5>
            <button onClick={handleModeratorClick} className="role-button">Join as Moderator</button>
            <button onClick={handleDoctorClick} className="role-button">Join as Doctor</button>
          </div>
        </div>
      )}

      {/* Registration form modal */}
      <RegistrationDoctorForm
        registerDrModal={registerDrModal}
        setRegisterDrModal={setRegisterDrModal}
      />

      <style jsx>{`
        .join-healthbridge {
          background-color: #f8f9fa;
          padding: 5rem 0;
          text-align: center;
          color: #333;
        }
        .content {
          max-width: 800px;
          margin: 0 auto;
          padding: 0 1.5rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
        .text-muted {
          font-size: .9rem;
          margin-bottom: 2rem;
          color: #666;
        }
        .cta-button {
          display: inline-flex;
          align-items: center;
          background-color: #007bff;
          color: #fff;
          padding: 1rem 2rem;
          font-size: 1.25rem;
          border-radius: 0.5rem;
          text-decoration: none;
          transition: background-color 0.3s ease, transform 0.3s ease;
          position: relative;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }
        .cta-button:hover {
          background-color: #0056b3;
          transform: translateY(-2px);
        }
        .icon {
          margin-left: 0.5rem;
          font-size: 1.5rem;
        }

        /* Style for the role selection popup */
        .role-prompt {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 9999;
        }
        .popup-content {
          background: #fff;
          padding: 2rem;
          border-radius: 0.5rem;
          text-align: center;
          max-width: 400px;
          width: 100%;
          position: relative;
        }
        .close-btn {
          position: absolute;
          top: 10px;
          right: 10px;
          background: transparent;
          border: none;
          color: #333;
          font-size: 1.5rem;
          cursor: pointer;
        }
        .close-btn:hover {
          color: #007bff;
        }
        .role-button {
          display: inline-block;
          margin: 10px;
          padding: 1rem 2rem;
          background-color: #007bff;
          color: #fff;
          border-radius: 0.5rem;
          border: none;
          cursor: pointer;
          font-size: 1.25rem;
          transition: background-color 0.3s ease, transform 0.3s ease;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
        .role-button:hover {
          background-color: #0056b3;
          transform: translateY(-2px);
        }
        .role-button:active {
          background-color: #004085;
          transform: translateY(1px);
        }
      `}</style>
    </div>
  );
}

export default JoinHealthBridge;
