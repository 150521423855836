import React, { useCallback, useState } from "react";
import { post } from "../../api_helper";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ReplayIcon from "@mui/icons-material/Replay";
import ClearIcon from "@mui/icons-material/Clear";
import debounce from "lodash.debounce";
import { loadingPanel } from "../CommonComponents/CommonFunction";
import { ShowSucess, ShowError } from "../../_helper/helper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Card, OverlayTrigger, Tooltip, Badge } from "react-bootstrap";
import {
  Box,
  TextField,
  MenuItem,
  IconButton,
  Dialog,
  DialogContent,
  Typography,
  Grid,
  Container,
  Button,
  Step,
  StepLabel,
  Stepper,
  Alert,
} from "@mui/material";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import { Col } from "reactstrap";
const steps = [
  "Personal Information",
  "Professional Information",
  "Upload Documents",
  // "Address Information",
  "Account Information",
];

const baseURL = process.env.REACT_APP_API_URL;
export default function RegistrationDoctorForm(props) {
  const { registerDrModal, setRegisterDrModal } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  // const [isEdited, setIsEdited] = useState(false);
  const [formData, setFormData] = React.useState({
    first_name: "",
    last_name: "",
    date_of_birth: "",
    gender: "",
    blood_type: "",
    contact_number: "",
    email: "",
    medical_license_number: "",
    specialization: "",
    years_of_experience: "",
    experience: "",
    expdetails: "",
    department: "",
    charge: "",
    hospital_clinic_affiliation: "",
    medical_school: "",
    graduation_year: "",
    residency: "",
    fellowships: "",
    photo_id: "",
    medical_license: "",
    certifications: "",
    street_address: "",
    city: "",
    state_province: "",
    postal_code: "",
    country: "",
    // username: "",
    signature: null,
    password: "",
    active: false,
    description: "testing",
    location: "test",
  });
  const [errors, setErrors] = React.useState({});
  const [experiences, setExperiences] = useState([
    {
      institution: "",
      role: "",
      start_date: "",
      end_date: "",
      total_year: "",
      description: "",
    },
  ]);
  const [educations, setEducations] = useState([
    { degree: "", institution: "", year_of_graduation: "" },
  ]);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleDocClick = (fileObject, e) => {
    e.preventDefault();

    // Create a URL for the file object
    const documentURL = URL.createObjectURL(fileObject);

    // Open the document in a new tab
    window.open(documentURL, "_blank");

    // Optional: Clean up the object URL when no longer needed
    setTimeout(() => URL.revokeObjectURL(documentURL), 5000); // Clean up after 5 seconds
  };

  const handleFileChange = (event) => {
    // Handle file inputs
    const { name, files: fileList } = event.target;
    const file = fileList[0];
    setFormData({
      ...formData,
      [name]: file ? file : null,
    });
  };
  const createDoctorRegistration = async () => {
    try {
      setLoading(true);
      // const token = localStorage.getItem("jwt_access_token");
      const token = localStorage.getItem("accessToken");

      const formDataToSend = new FormData();

      // Append file fields
      if (formData.photo_id) {
        formDataToSend.append("photo_id", formData.photo_id);
      }
      if (formData.medical_license) {
        formDataToSend.append("medical_license", formData.medical_license);
      }
      if (formData.certifications) {
        formDataToSend.append("certifications", formData.certifications);
      }
      if (formData.signature) {
        formDataToSend.append("signature", formData.signature);
      }
      // if (formData?.experience) {
      //   formDataToSend.append(
      //     "experience",
      //     JSON.stringify([
      //       {
      //         // job_title: "Cardiologist",
      //         // institution: "NYC Heart Clinic",
      //         // start_date: "2010-01-01",
      //         // end_date: "2023-01-01",
      //         description: formData?.experience,
      //       },
      //     ])
      //   );
      // }
      formDataToSend.append("experience", JSON.stringify(experiences));
      formDataToSend.append("Education", JSON.stringify(educations));
      // Append other fields
      for (const key in formData) {
        if (
          key !== "photo_id" &&
          key !== "medical_license" &&
          key !== "certifications" &&
          key !== "experience"
        ) {
          formDataToSend.append(key, formData[key]);
        }
      }

      const res = await (
        await fetch(`${baseURL}/register/doctor/`, {
          method: "POST",
          body: formDataToSend,
          // headers: {
          //   // Authorization: `Bearer ${token}`,
          //   // "x-access-token": token,
          //   "Content-Type": "multipart/form-data",
          // },
        })
      ).json();
      if (res?.user) {
        setLoading(false);
        setIsRegister(true);
        ShowSucess("SuccessFully Registered!");
        // setRegisterDrModal(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      ShowError("Something Went Wrong!");
      console.log("error", error);
    }
  };
  const handleClose = () => {
    setRegisterDrModal(false);
    handleReset();
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    // if (validateForm()) {
    createDoctorRegistration();
    // }

    // Perform form submission logic
    // Example: validate form data, submit API request, etc.
  };

  // const isStepOptional = (step) => {
  //   return step === 3; // Example: Address Information step is optional
  // };

  const isStepSkipped = (step) => {
    return false; // Placeholder for skipped steps logic if needed
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const validateField = (name, value) => {
    let error = "";
    if (!value) {
      error = "This field is required";
    } else {
      switch (name) {
        case "email":
          if (!/\S+@\S+\.\S+/.test(value)) {
            error = "Email address is invalid";
          }
          break;
        // case "contact_number":
        //   if (!/^\d{10}$/.test(value)) {
        //     error = "Contact number is invalid";
        //   }
        //   break;
        case "password":
          if (value.length < 6) {
            error = "Password must be at least 6 characters";
          }
          break;
        // case "confirmPassword":
        //   if (value !== formData.password) {
        //     error = "Passwords do not match";
        //   }
        //   break;
        default:
          break;
      }
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };
  const handleKeyDown = (e, index, type) => {
    if (e.key === "Enter" || e.key === "Tab") {
      e.preventDefault(); // Prevent default behavior of Enter or Tab
      const isAllFieldsFilledForExperience = Object.values(
        experiences[index]
      ).every((value) => value.trim() !== "");
      const isAllFieldsFilledForEducation = Object.values(
        educations[index]
      ).every((value) => value.trim() !== "");
      if (isAllFieldsFilledForExperience && type === "experience") {
        handleAddExperience();
      } else if (isAllFieldsFilledForEducation && type === "education") {
        handleAddEducation();
      }
    }
  };
  const validateForm = () => {
    let valid = true;
    Object.keys(formData).forEach((key) => {
      validateField(key, formData[key]);
      if (!formData[key] || errors[key]) {
        valid = false;
      }
    });
    return valid;
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    validateField(name, value);
  };
  const handleReset = () => {
    setActiveStep(0);
    setFormData({
      name: "",
      date_of_birth: "",
      gender: "",
      contact_number: "",
      email: "",
      medical_license_number: "",
      specialization: "",
      years_of_experience: "",
      experience: "",
      expdetails: "",
      department: null,
      hospital_clinic_affiliation: "",
      medical_school: "",
      graduation_year: "",
      residency: "",
      fellowships: "",
      photo_id: "",
      medical_license: "",
      certifications: "",
      address: "",
      street_address: "",
      city: "",
      state_province: "",
      postal_code: "",
      country: "",
      username: "",
      password: "",
    });
    setErrors({});
  };
  const allKeysHaveValues = (arr) => {
    if (arr.length === 0) return true; // Return true for empty array

    // Get all keys from the first object
    const keys = Object.keys(arr[0]);

    return arr.every((obj) =>
      keys.every(
        (key) => obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== ""
      )
    );
  };
  const handleAddEducation = () => {
    setEducations([
      ...educations,
      { degree: "", institution: "", year_of_graduation: "" },
    ]);
  };
  const handleAddExperience = () => {
    setExperiences([
      ...experiences,
      {
        institution: "",
        role: "",
        start_date: "",
        end_date: "",
        total_year: "",
        description: "",
      },
    ]);
  };
  console.log("image", formData);

  function calculateDateDifference(startDate, endDate) {
    // Convert inputs to Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calculate the total difference in years, months, and days
    let years = end.getFullYear() - start.getFullYear();
    let months = end.getMonth() - start.getMonth();
    let days = end.getDate() - start.getDate();

    // Adjust months and years if necessary
    if (days < 0) {
      months--; // Borrow one month
      days += new Date(end.getFullYear(), end.getMonth(), 0).getDate(); // Get days in previous month
    }

    if (months < 0) {
      years--; // Borrow one year
      months += 12; // Normalize months to positive value
    }

    // Return the result as an object
    return { years, months, days };
  }
  const handleExperienceChange = (index, field, value) => {
    if (field === "start_date" || field === "end_date") {
      handleCalculate(index);
    }
    const updatedMedicines = [...experiences];
    updatedMedicines[index][field] = value;
    setExperiences(updatedMedicines);
    // setIsEdited(true);
  };
  const handleRemoveExperience = (index) => {
    const updatedMedicines = experiences.filter((_, i) => i !== index);
    setExperiences(updatedMedicines);
    // setIsEdited(true);
  };
  const handleEducationChange = (index, field, value) => {
    const updatedEducations = [...educations];
    updatedEducations[index][field] = value;
    setEducations(updatedEducations);
    // setIsEdited(true);
  };
  const handleRemoveEducation = (index) => {
    const updatedEducations = educations.filter((_, i) => i !== index);
    setEducations(updatedEducations);
    // setIsEdited(true);
  };
  const renderTextInput = useCallback(
    (
      type,
      state,
      label,
      disabled = false,
      required = false,
      multiline = false
    ) => {
      const showError = !!errors[state];
      const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
      };
      return (
        <TextField
          required={required}
          size="small"
          fullWidth
          margin="dense"
          variant="outlined"
          label={label}
          name={state}
          id={state}
          type={state === "phone" ? "number" : type}
          disabled={disabled}
          error={showError}
          helperText={showError ? errors[state] : undefined}
          multiline={multiline}
          rows={multiline ? 3 : 1}
          value={formData[state] || ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      );
    },
    [formData, errors, handleBlur]
  );
  const handleCalculate = debounce((index) => {
    const startDate = experiences[index]["start_date"];
    const endDate = experiences[index]["end_date"];
    if (startDate && endDate) {
      const { years, months, days } = calculateDateDifference(
        startDate,
        endDate
      );
      const difference = `${
        years ? `${years} ${years > 1 ? "years " : "year "}` : ""
      }  ${months ? `${months} ${months > 1 ? "months " : "month "}` : ""} ${
        days ? `${days} ${days > 1 ? "days" : "day"}` : ""
      }`;
      const updatedExpriences = [...experiences];
      updatedExpriences[index]["total_year"] =
        difference.trim() !== "" ? difference : "0";
      setExperiences(updatedExpriences);
      // ShowError(error);
    }
  }, 300);

  const resetExperience = (index) => {
    setExperiences((prevExperiences) => {
      return prevExperiences.map(
        (experience, i) =>
          i === index
            ? { institution: "", role: "", duration: "", description: "" }
            : // Reset specific fields
              experience // Keep other experiences unchanged
      );
    });
  };
  const resetEducation = (index) => {
    setEducations((prevEducations) => {
      return prevEducations.map(
        (education, i) =>
          i === index
            ? { degree: "", institution: "", year_of_graduation: "" }
            : // Reset specific fields
              education // Keep other experiences unchanged
      );
    });
  };
  const isFormValid = () => {
    switch (activeStep) {
      case 0:
        return (
          formData.first_name !== "" &&
          formData.last_name !== "" &&
          formData.date_of_birth !== "" &&
          formData.gender !== "" &&
          formData.contact_number !== "" &&
          formData.email !== ""
          // Add more validations as needed for each step
        );
      case 1:
        return (
          formData.medical_license_number !== "" &&
          formData.specialization !== "" &&
          formData.years_of_experience !== "" &&
          formData.hospital_clinic_affiliation !== "" &&
          formData.medical_school !== "" &&
          formData.graduation_year !== "" &&
          formData.residency !== "" &&
          formData.fellowships !== ""
        );
      case 2:
        return (
          formData.photo_id !== "" &&
          formData?.signature !== "" &&
          formData.medical_license !== "" &&
          formData.certifications !== ""
        );
      // case 3:
      //   return (
      //     formData.address !== "" &&
      //     formData.city !== "" &&
      //     formData.country !== ""
      //   );
      case 3:
        return (
          formData.username !== "" && formData.password !== ""
          // formData.confirmPassword !== "" &&
          // formData.password === formData.confirmPassword
        );
      default:
        return false;
    }
  };
  const country = [
    { value: "United States", label: "United States" },
    { value: "Canada", label: "Canada" },
    { value: "Australia", label: "Australia" },
  ];
  const options = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
    { value: "O", label: "Other" },
  ];
  const specializationMenu = [
    { value: "Cardiology", label: "Cardiology" },
    { value: "Dermatology", label: "Dermatology" },
    { value: "Neurology", label: "Neurology" },
    { value: "Pediatrics", label: "Pediatrics" },
    { value: "Other", label: "Other" },
  ];
  return (
    <>
      {registerDrModal && (
        <Dialog
          open={registerDrModal}
          // onClose={() => setRegisterDrModal(false)}
          fullWidth
          maxWidth="lg"
        >
          <IconButton
            aria-label="close"
            onClick={() => handleClose()}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            {" "}
            <Box sx={{ width: "98%" }}>
              {loading && loadingPanel()}
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  // if (isStepOptional(index)) {
                  //   labelProps.optional = (
                  //     <Typography variant="caption">Optional</Typography>
                  //   );
                  // }
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <Container style={{ maxWidth: "950px" }}>
                <form onSubmit={handleSubmit}>
                  {activeStep === 0 && (
                    <div>
                      <Typography variant="h6" gutterBottom>
                        Personal Information
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput(
                            "text",
                            "first_name",
                            "First Name",
                            false
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput(
                            "text",
                            "last_name",
                            "Last Name",
                            false
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            type="date"
                            name="date_of_birth"
                            fullWidth
                            margin="dense"
                            label="DOB"
                            size="small"
                            placeholder="Date of Birth"
                            value={
                              formData.date_of_birth ||
                              dayjs(new Date()).format("MM/DD/YYYY")
                            }
                            onChange={handleInputChange}
                            error={!!errors.date_of_birth}
                            helperText={errors.date_of_birth}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            select={true}
                            id="outlined-select-requestType"
                            size="small"
                            label="Gender"
                            margin="dense"
                            name="gender"
                            value={formData.gender}
                            onChange={handleInputChange}
                            error={!!errors.gender}
                            helperText={errors.gender}
                          >
                            {options.map((item) => (
                              <MenuItem value={item.value} key={item.value}>
                                {item?.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput(
                            "text",
                            "blood_type",
                            "Blood Type",
                            false
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput(
                            "text",
                            "contact_number",
                            "Contact Number",
                            false
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput("text", "email", "Email", false)}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput("text", "address", "Address", false)}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput(
                            "text",
                            "street_address",
                            "Street Address",
                            false
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput("text", "city", "City", false)}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          {renderTextInput(
                            "text",
                            "state_province",
                            "State/Province",
                            false
                          )}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          {renderTextInput(
                            "text",
                            "postal_code",
                            "Postal Code",
                            false
                          )}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            select
                            name="country"
                            fullWidth
                            margin="dense"
                            size="small"
                            label="Country"
                            value={formData.country}
                            onChange={handleInputChange}
                            error={!!errors.country}
                            helperText={errors.country}
                          >
                            {country.map((item) => (
                              <MenuItem value={item.value} key={item.value}>
                                {item?.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {activeStep === 1 && (
                    <div>
                      <Typography variant="h6" gutterBottom>
                        Professional Information
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            select
                            name="specialization"
                            fullWidth
                            margin="dense"
                            size="small"
                            label="Specialization"
                            value={formData.specialization}
                            onChange={handleInputChange}
                            error={!!errors.specialization}
                            helperText={errors.specialization}
                          >
                            {specializationMenu.map((item) => (
                              <MenuItem value={item.value} key={item.value}>
                                {item?.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput(
                            "number",
                            "years_of_experience",
                            "Years Of Experience",
                            false
                          )}
                        </Grid>
                        {educations && educations?.length > 0 && (
                          <Grid item xs={12} sm={12}>
                            <div>
                              <h5 style={{ marginBottom: "15px" }}>
                                Education
                              </h5>
                              {/* <CardBody> */}
                              {educations.map((education, index) => (
                                <div
                                  key={`education_${index}`}
                                  className="row mb-3 align-items-center"
                                >
                                  <Grid container spacing={1}>
                                    <Grid
                                      item
                                      style={{
                                        margin: "auto 0",
                                        paddingRight: "5px",
                                      }}
                                    >
                                      <Badge pill bg="primary">
                                        {`${index + 1}`}
                                      </Badge>{" "}
                                    </Grid>
                                    <Grid item xs={11} sm={11}>
                                      <Grid container spacing={1}>
                                        <Grid item xs={6} sm={6}>
                                          <TextField
                                            type="text"
                                            placeholder="Degree Name"
                                            label="Degree"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            value={education?.degree}
                                            error={!education?.degree}
                                            onChange={(e) =>
                                              handleEducationChange(
                                                index,
                                                "degree",
                                                e.target.value
                                              )
                                            }
                                            required
                                          />
                                        </Grid>

                                        <Grid item xs={6} sm={6}>
                                          <TextField
                                            type="text"
                                            placeholder="Graduation Year"
                                            label="Graduation Year"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            value={
                                              education?.year_of_graduation
                                            }
                                            error={
                                              !education?.year_of_graduation
                                            }
                                            onChange={(e) =>
                                              handleEducationChange(
                                                index,
                                                "year_of_graduation",
                                                e.target.value
                                              )
                                            }
                                            required
                                          />
                                        </Grid>
                                      </Grid>
                                      <Grid container spacing={1}>
                                        <Grid
                                          item
                                          xs={11}
                                          sm={11}
                                          style={{ marginTop: "10px" }}
                                        >
                                          <TextField
                                            type="text"
                                            fullWidth
                                            size="small"
                                            placeholder="Institute Name"
                                            label="Institute Name"
                                            variant="outlined"
                                            value={education?.institution}
                                            error={!education?.institution}
                                            onChange={(e) =>
                                              handleEducationChange(
                                                index,
                                                "institution",
                                                e.target.value
                                              )
                                            }
                                            onKeyDown={(e) =>
                                              handleKeyDown(
                                                e,
                                                index,
                                                "education"
                                              )
                                            }
                                            required
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={1}
                                          sm={1}
                                          style={{
                                            marginTop: "10px",
                                          }}
                                        >
                                          {/* {index !== 0 && ( */}
                                          <Grid container>
                                            <Grid item>
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${index}`}
                                                  >
                                                    Remove Entity
                                                  </Tooltip>
                                                }
                                              >
                                                <ClearIcon
                                                  color="primary"
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    if (
                                                      educations &&
                                                      educations?.length > 1
                                                    ) {
                                                      handleRemoveEducation(
                                                        index
                                                      );
                                                    } else {
                                                      ShowError(
                                                        "At least one entry must remain!"
                                                      );
                                                    }
                                                  }}
                                                />
                                              </OverlayTrigger>
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${index}`}
                                                  >
                                                    Reset Entity
                                                  </Tooltip>
                                                }
                                              >
                                                <ReplayIcon
                                                  color="primary"
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    resetEducation(index)
                                                  }
                                                />
                                                {/* <Button
                                                      onClick={() =>
                                                        setExperiences
                                                      }
                                                      size="small"
                                                      sx={{ ml: 1 }}
                                                      startIcon={}
                                                    ></Button> */}
                                              </OverlayTrigger>
                                            </Grid>
                                          </Grid>

                                          {/* )} */}
                                        </Grid>
                                      </Grid>
                                    </Grid>

                                    {/* </div> */}
                                  </Grid>
                                </div>
                              ))}

                              <div style={{ marginLeft: "35px" }}>
                                <Button
                                  variant="contained"
                                  disabled={!allKeysHaveValues(educations)}
                                  onClick={() => handleAddEducation()}
                                  size="small"
                                  sx={{ mr: 1 }}
                                >
                                  + Add New Entry
                                </Button>
                              </div>
                              {/* </CardBody> */}
                            </div>
                          </Grid>
                        )}
                        {experiences && experiences?.length > 0 && (
                          <Grid item xs={12} sm={12}>
                            <Card>
                              <h5 style={{ marginBottom: "15px" }}>
                                Experience
                              </h5>
                              {/* <CardBody> */}
                              {experiences.map((experience, index) => (
                                <div
                                  key={index}
                                  className="row mb-3 align-items-center"
                                >
                                  <Grid container spacing={1}>
                                    <Grid
                                      item
                                      style={{
                                        margin: "auto 0",
                                        paddingRight: "5px",
                                      }}
                                    >
                                      <Badge pill bg="primary">
                                        {`${index + 1}`}
                                      </Badge>
                                    </Grid>
                                    <Grid item xs={11} sm={11}>
                                      <Grid container spacing={1}>
                                        <Grid item xs={6} sm={6}>
                                          <TextField
                                            type="text"
                                            fullWidth
                                            size="small"
                                            placeholder="Hospital Name"
                                            label="Hospital Name"
                                            value={experience?.institution}
                                            error={!experience?.institution}
                                            onChange={(e) =>
                                              handleExperienceChange(
                                                index,
                                                "institution",
                                                e.target.value
                                              )
                                            }
                                            required
                                            variant="outlined"
                                          />
                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                          <TextField
                                            type="text"
                                            placeholder="Role"
                                            label="Role"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            value={experience?.role}
                                            error={!experience?.role}
                                            onChange={(e) =>
                                              handleExperienceChange(
                                                index,
                                                "role",
                                                e.target.value
                                              )
                                            }
                                            required
                                          />
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        container
                                        spacing={1}
                                        style={{ marginTop: "1px" }}
                                      >
                                        <Grid item xs={4} sm={4}>
                                          <TextField
                                            type="date"
                                            name="start_date"
                                            fullWidth
                                            margin="dense"
                                            label="Start Date"
                                            size="small"
                                            placeholder="Start Date"
                                            error={!experience?.start_date}
                                            value={experience?.start_date || ""}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            onChange={(e) => {
                                              if (
                                                experience?.end_date &&
                                                e?.target?.value >
                                                  experience?.end_date
                                              ) {
                                                ShowError(
                                                  "Start Date must be less than End date!"
                                                );
                                                return;
                                              }
                                              handleExperienceChange(
                                                index,
                                                "start_date",
                                                e?.target?.value
                                              );
                                            }}
                                          />
                                        </Grid>
                                        <Grid item xs={4} sm={4}>
                                          <TextField
                                            type="date"
                                            name="end_date"
                                            fullWidth
                                            margin="dense"
                                            label="End Date"
                                            size="small"
                                            placeholder="End Date"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            error={!experience?.end_date}
                                            value={experience?.end_date || ""}
                                            onChange={(e) => {
                                              if (
                                                experience?.start_date &&
                                                e?.target?.value <
                                                  experience?.start_date
                                              ) {
                                                ShowError(
                                                  "End Date must be greater than Start Date!"
                                                );
                                                return;
                                              }
                                              handleExperienceChange(
                                                index,
                                                "end_date",
                                                e?.target?.value
                                              );
                                            }}
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={4}
                                          sm={4}
                                          style={{ marginTop: "7px" }}
                                        >
                                          <TextField
                                            type="text"
                                            placeholder="Total"
                                            label="Total"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            value={experience?.total_year || ""}
                                            error={!experience?.total_year}
                                            onChange={(e) =>
                                              handleExperienceChange(
                                                index,
                                                "total_year",
                                                e.target.value
                                              )
                                            }
                                            required
                                          />
                                        </Grid>
                                      </Grid>
                                      <Grid container spacing={1}>
                                        <Grid
                                          item
                                          xs={11}
                                          sm={11}
                                          style={{ marginTop: "10px" }}
                                        >
                                          <TextField
                                            multiline
                                            fullWidth
                                            // size="small"
                                            placeholder="description"
                                            label="Description"
                                            variant="outlined"
                                            value={experience?.description}
                                            error={!experience?.description}
                                            onChange={(e) =>
                                              handleExperienceChange(
                                                index,
                                                "description",
                                                e.target.value
                                              )
                                            }
                                            rows={2}
                                            onKeyDown={(e) =>
                                              handleKeyDown(
                                                e,
                                                index,
                                                "experience"
                                              )
                                            }
                                            required
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={1}
                                          sm={1}
                                          style={{
                                            margin: "auto 0",
                                          }}
                                        >
                                          <Grid container>
                                            <Grid item>
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${index}`}
                                                  >
                                                    Remove Entity
                                                  </Tooltip>
                                                }
                                              >
                                                <ClearIcon
                                                  color="primary"
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    if (
                                                      experiences &&
                                                      experiences?.length > 1
                                                    ) {
                                                      handleRemoveExperience(
                                                        index
                                                      );
                                                    } else {
                                                      ShowError(
                                                        "At least one entry must remain!"
                                                      );
                                                    }
                                                  }}
                                                />
                                              </OverlayTrigger>
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${index}`}
                                                  >
                                                    Reset Entity
                                                  </Tooltip>
                                                }
                                              >
                                                <ReplayIcon
                                                  color="primary"
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    resetExperience(index)
                                                  }
                                                />
                                              </OverlayTrigger>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </div>
                              ))}
                              {/* </CardBody> */}
                              <div style={{ marginLeft: "35px" }}>
                                <Button
                                  variant="contained"
                                  disabled={!allKeysHaveValues(experiences)}
                                  onClick={() => handleAddExperience()}
                                  size="small"
                                  sx={{ mr: 1 }}
                                >
                                  + Add New Entry
                                </Button>
                              </div>
                            </Card>
                          </Grid>
                        )}

                        {/* <Grid item xs={12} sm={12}>
                          {renderTextInput(
                            "text",
                            "experience",
                            "Experience",
                            false
                          )}
                        </Grid> */}
                        <Grid item xs={12} sm={12}>
                          {renderTextInput(
                            "text",
                            "expdetails",
                            "Experience Details",
                            false,
                            false,
                            true
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput("number", "charge", "Charge", false)}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput(
                            "text",
                            "medical_license_number",
                            "Medical License Number",
                            false
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput(
                            "text",
                            "hospital_clinic_affiliation",
                            "Affiliation",
                            false
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput(
                            "text",
                            "medical_school",
                            "Medical School",
                            false
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput(
                            "text",
                            "graduation_year",
                            "Graduation Year",
                            false
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput(
                            "text",
                            "residency",
                            "Residency",
                            false
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput(
                            "text",
                            "fellowships",
                            "Fellowships",
                            false
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {activeStep === 2 && (
                    <div
                      style={{
                        margin: "35px 0px",
                      }}
                    >
                      {/* <Typography variant="h6" gutterBottom>
                        Upload Documents
                      </Typography> */}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col>
                          {formData?.photo_id && (
                            <div
                              style={{
                                height: formData?.photo_id ? "135px" : 0,
                              }}
                            >
                              {/* <Typography variant="subtitle1">
                                Preview:
                              </Typography> */}
                              <img
                                src={
                                  formData?.photo_id &&
                                  typeof formData?.photo_id === "string"
                                    ? formData?.photo_id
                                    : formData?.photo_id instanceof Blob
                                    ? URL.createObjectURL(formData?.photo_id)
                                    : ""
                                }
                                alt="Preview"
                                style={{
                                  width: "100%",
                                  maxWidth: 210,
                                  maxHeight: 170,
                                }}
                              />
                            </div>
                          )}
                          <Button
                            component="label"
                            variant="outlined"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon />}
                          >
                            Upload Profile Image
                            <input
                              type="file"
                              accept="image/*"
                              name="photo_id"
                              id="image"
                              onChange={handleFileChange}
                              style={{ display: "none" }}
                            />
                            {/* <VisuallyHiddenInput type="file" /> */}
                          </Button>
                          <br />
                          {errors?.photo_id ? (
                            <span
                              style={{
                                // marginBottom: "-9px",
                                // marginTop: "3px",
                                marginTop: 0,
                                color: "#d32f2f",
                                fontSize: "0.75rem",
                              }}
                              // className="error"
                            >
                              {errors?.photo_id}
                              {/* Image file Name should be at least of 100
                                  charactor length */}
                            </span>
                          ) : null}
                        </Col>
                        <Col>
                          <div
                            style={{
                              height: formData?.signature
                                ? "135px"
                                : formData?.photo_id
                                ? "135px"
                                : 0,
                            }}
                          >
                            {/* <Typography variant="subtitle1">
                                Preview:
                              </Typography> */}
                            {formData?.signature && (
                              <img
                                src={
                                  formData?.signature &&
                                  typeof formData?.signature === "string"
                                    ? formData?.signature
                                    : formData?.signature instanceof Blob
                                    ? URL.createObjectURL(formData?.signature)
                                    : ""
                                }
                                alt="Preview"
                                style={{
                                  width: "100%",
                                  maxWidth: 200,
                                  height: formData?.signature
                                    ? "130px"
                                    : "130px",
                                }}
                              />
                            )}
                          </div>
                          <Button
                            component="label"
                            variant="outlined"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon />}
                            style={{ marginLeft: "10px" }}
                          >
                            Upload Signature
                            <input
                              type="file"
                              accept="image/*"
                              name="signature"
                              id="signature"
                              onChange={handleFileChange}
                              style={{ display: "none" }}
                            />
                            {/* <VisuallyHiddenInput type="file" /> */}
                          </Button>
                          <br />
                          {errors?.signature ? (
                            <span
                              style={{
                                // marginBottom: "-9px",
                                // marginTop: "3px",
                                marginTop: 0,
                                color: "#d32f2f",
                                fontSize: "0.75rem",
                              }}
                              // className="error"
                            >
                              {errors?.signature}
                              {/* Signature file Name should be at least of 100
                                  charactor length */}
                            </span>
                          ) : null}
                        </Col>
                        <Col>
                          <div
                            style={{
                              marginTop: formData?.medical_license?.name
                                ? "93px"
                                : formData?.photo_id
                                ? "93px"
                                : 0,
                            }}
                          >
                            {formData?.medical_license?.name ? (
                              <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<VisibilityIcon />}
                                onClick={(e) =>
                                  handleDocClick(formData?.medical_license, e)
                                }
                                style={{ marginBottom: "5px" }}
                                href={formData?.medical_license}
                                target="_blank" // Opens the link in a new tab
                                rel="noopener noreferrer"
                              >
                                {" "}
                                <a
                                  style={{ color: "#fff" }}
                                  href={formData?.medical_license}
                                  onClick={(e) =>
                                    handleDocClick(formData?.medical_license, e)
                                  }
                                >
                                  {formData?.medical_license?.name ? (
                                    <div>
                                      {formData.medical_license.name.length > 15
                                        ? `${formData.medical_license.name.slice(
                                            0,
                                            15
                                          )}...`
                                        : formData.medical_license.name}
                                    </div>
                                  ) : (
                                    "Medical License(pdf/png)"
                                  )}
                                </a>{" "}
                              </Button>
                            ) : null}
                            <Button
                              component="label"
                              variant="outlined"
                              tabIndex={-1}
                              startIcon={<CloudUploadIcon />}
                            >
                              Medical License Doc
                              <input
                                type="file"
                                id="medical_license"
                                name="medical_license"
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                              />
                              {/* <VisuallyHiddenInput type="file" /> */}
                            </Button>
                          </div>
                        </Col>
                        <Col>
                          <div
                            style={{
                              marginTop: formData?.certifications?.name
                                ? "93px"
                                : formData?.photo_id
                                ? "93px"
                                : 0,
                            }}
                          >
                            {formData?.certifications?.name ? (
                              <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<VisibilityIcon />}
                                onClick={(e) =>
                                  handleDocClick(formData?.certifications, e)
                                }
                                style={{ marginBottom: "5px" }}
                                href={formData?.certifications}
                                target="_blank" // Opens the link in a new tab
                                rel="noopener noreferrer"
                              >
                                {" "}
                                <a
                                  style={{ color: "#fff" }}
                                  href={formData?.certifications}
                                  onClick={(e) =>
                                    handleDocClick(formData?.certifications, e)
                                  }
                                >
                                  {formData?.certifications?.name ? (
                                    <div>
                                      {formData.certifications.name.length > 15
                                        ? `${formData.certifications.name.slice(
                                            0,
                                            15
                                          )}...`
                                        : formData.certifications.name}
                                    </div>
                                  ) : (
                                    " Certificate.pdf"
                                  )}
                                </a>{" "}
                              </Button>
                            ) : null}
                            <Button
                              component="label"
                              variant="outlined"
                              tabIndex={-1}
                              startIcon={<CloudUploadIcon />}
                            >
                              Certifications Doc
                              <input
                                type="file"
                                name="certifications"
                                id="certifications"
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                              />
                            </Button>
                          </div>
                        </Col>
                      </div>
                    </div>
                  )}

                  {activeStep === 3 && (
                    <div>
                      <Typography variant="h6" gutterBottom>
                        Account Information
                      </Typography>
                      <Grid container spacing={2}>
                        {/* <Grid item xs={12} sm={12}>
                          {renderTextInput(
                            "text",
                            "username",
                            "Username",
                            false
                          )}
                        </Grid> */}
                        <Grid item xs={12} sm={12}>
                          {renderTextInput(
                            "password",
                            "password",
                            "Password",
                            false
                          )}
                        </Grid>
                        {/* <Grid item xs={12} sm={12}>
                          {renderTextInput(
                            "password",
                            "confirmPassword",
                            "Confirm Password",
                            false
                          )}
                        </Grid> */}
                      </Grid>
                    </div>
                  )}
                  <div style={{ marginTop: "24px" }}>
                    <Button
                      disabled={activeStep === 0}
                      variant="contained"
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    {activeStep !== steps.length - 1 && (
                      <Button
                        variant="contained"
                        disabled={!isFormValid()}
                        onClick={handleNext}
                        sx={{ mr: 1 }}
                      >
                        Next
                      </Button>
                    )}
                    {activeStep === steps.length - 1 && (
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={!isFormValid()}
                        sx={{ mr: 1 }}
                      >
                        Submit
                      </Button>
                    )}
                    <Button variant="contained" onClick={handleReset}>
                      Reset
                    </Button>
                  </div>
                </form>
                {isRegister && (
                  <Alert severity="success" style={{ marginTop: "15px" }}>
                    {" "}
                    Successfully Registered! Please check your email to verify
                    your account.
                  </Alert>
                )}
                {/* <footer> */}
                <p style={{ textAlign: "center" }}>
                  Already registered?{" "}
                  <a style={{ fontSize: "medium" }} href="/login">
                    Login here
                  </a>
                </p>
                {/* </footer> */}
              </Container>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

// import React, { useState, useCallback } from "react";
// import { Box, TextField, MenuItem, IconButton, Dialog, DialogContent, Typography, Grid, Container, Button, Step, StepLabel, Stepper, Alert } from "@mui/material";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import CloseIcon from "@mui/icons-material/Close";
// import dayjs from "dayjs";

// const steps = [
//   "Personal Information",
//   "Professional Information",
//   "Upload Documents",
//   "Account Information",
// ];

// const baseURL = process.env.REACT_APP_API_URL;

// export default function RegistrationDoctorForm() {
//   const [activeStep, setActiveStep] = useState(0);
//   const [loading, setLoading] = useState(false);
//   const [isRegister, setIsRegister] = useState(false);
//   const [formData, setFormData] = useState({
//     first_name: "John",
//     last_name: "Doe",
//     date_of_birth: "1990-01-01",
//     gender: "M",
//     blood_type: "O",
//     contact_number: "1234567890",
//     email: "john.doe@example.com",
//     medical_license_number: "ML123456",
//     specialization: "Cardiology",
//     years_of_experience: "10",
//     experience: "10 years in Cardiology",
//     expdetails: "Detailed experience in Cardiology.",
//     department: "Cardiology",
//     charge: "100",
//     hospital_clinic_affiliation: "Heart Clinic",
//     medical_school: "Harvard Medical School",
//     graduation_year: "2010",
//     residency: "Internal Medicine",
//     fellowships: "Cardiology Fellowship",
//     photo_id: null,
//     medical_license: null,
//     certifications: null,
//     street_address: "123 Main St",
//     city: "Anytown",
//     state_province: "State",
//     postal_code: "12345",
//     country: "United States",
//     password: "password123",
//     active: false,
//     description: "testing",
//     location: "test",
//   });
//   const [errors, setErrors] = useState({});

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleFileChange = (event) => {
//     const { name, files: fileList } = event.target;
//     const file = fileList[0];
//     setFormData({ ...formData, [name]: file ? file : null });
//   };

//   const createDoctorRegistration = async () => {
//     try {
//       setLoading(true);
//       const token = localStorage.getItem("accessToken");
//       const formDataToSend = new FormData();

//       // Append file fields
//       if (formData.photo_id) formDataToSend.append("photo_id", formData.photo_id);
//       if (formData.medical_license) formDataToSend.append("medical_license", formData.medical_license);
//       if (formData.certifications) formDataToSend.append("certifications", formData.certifications);
//       if (formData.experience) {
//         formDataToSend.append("experience", JSON.stringify([{ description: formData.experience }]));
//       }

//       // Append other fields
//       for (const key in formData) {
//         if (!["photo_id", "medical_license", "certifications", "experience"].includes(key)) {
//           formDataToSend.append(key, formData[key]);
//         }
//       }

//       const res = await (await fetch(`${baseURL}/register/doctor/`, {
//         method: "POST",
//         body: formDataToSend,
//       })).json();
//       if (res?.user) {
//         setLoading(false);
//         setIsRegister(true);
//         // Optionally close modal or navigate
//       } else {
//         setLoading(false);
//       }
//     } catch (error) {
//       setLoading(false);
//       console.error("Error:", error);
//     }
//   };

//   const handleClose = () => {
//     // Reset form data if needed
//     setActiveStep(0);
//     setErrors({});
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     createDoctorRegistration();
//   };

//   const isStepSkipped = (step) => false;

//   const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

//   const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

//   const handleReset = () => {
//     setActiveStep(0);
//     setFormData({
//       first_name: "",
//       last_name: "",
//       date_of_birth: "",
//       gender: "",
//       blood_type: "",
//       contact_number: "",
//       email: "",
//       medical_license_number: "",
//       specialization: "",
//       years_of_experience: "",
//       experience: "",
//       expdetails: "",
//       department: "",
//       charge: "",
//       hospital_clinic_affiliation: "",
//       medical_school: "",
//       graduation_year: "",
//       residency: "",
//       fellowships: "",
//       photo_id: null,
//       medical_license: null,
//       certifications: null,
//       street_address: "",
//       city: "",
//       state_province: "",
//       postal_code: "",
//       country: "",
//       password: "",
//       active: false,
//       description: "testing",
//       location: "test",
//     });
//     setErrors({});
//   };

//   const renderTextInput = useCallback((type, state, label, disabled = false, required = false, multiline = false) => {
//     const showError = !!errors[state];
//     const handleChange = (event) => {
//       const { name, value } = event.target;
//       setFormData((prevValues) => ({
//         ...prevValues,
//         [name]: value,
//       }));
//     };
//     return (
//       <TextField
//         required={required}
//         size="small"
//         fullWidth
//         margin="dense"
//         variant="outlined"
//         label={label}
//         name={state}
//         id={state}
//         type={type}
//         disabled={disabled}
//         error={showError}
//         helperText={showError ? errors[state] : undefined}
//         multiline={multiline}
//         rows={multiline ? 3 : 1}
//         value={formData[state] || ""}
//         onChange={handleChange}
//       />
//     );
//   }, [formData, errors]);

//   const isFormValid = () => {
//     switch (activeStep) {
//       case 0:
//         return (
//           formData.first_name &&
//           formData.last_name &&
//           formData.date_of_birth &&
//           formData.gender &&
//           formData.contact_number &&
//           formData.email
//         );
//       case 1:
//         return (
//           formData.medical_license_number &&
//           formData.specialization &&
//           formData.years_of_experience &&
//           formData.hospital_clinic_affiliation &&
//           formData.medical_school &&
//           formData.graduation_year &&
//           formData.residency &&
//           formData.fellowships
//         );
//       case 2:
//         return (
//           formData.photo_id &&
//           formData.medical_license &&
//           formData.certifications
//         );
//       case 3:
//         return formData.password;
//       default:
//         return false;
//     }
//   };

//   const country = [
//     { value: "United States", label: "United States" },
//     { value: "Canada", label: "Canada" },
//     { value: "Australia", label: "Australia" },
//   ];

//   const options = [
//     { value: "M", label: "Male" },
//     { value: "F", label: "Female" },
//     { value: "O", label: "Other" },
//   ];

//   const specializationMenu = [
//     { value: "Cardiology", label: "Cardiology" },
//     { value: "Dermatology", label: "Dermatology" },
//     { value: "Neurology", label: "Neurology" },
//     { value: "Pediatrics", label: "Pediatrics" },
//     { value: "Other", label: "Other" },
//   ];

//   return (
//     <Dialog
//       open={true} // Always open for demonstration
//       fullWidth
//       maxWidth="lg"
//     >
//       <IconButton
//         aria-label="close"
//         onClick={handleClose}
//         sx={{
//           position: "absolute",
//           right: 8,
//           top: 8,
//           color: (theme) => theme.palette.grey[500],
//         }}
//       >
//         <CloseIcon />
//       </IconButton>
//       <DialogContent>
//         <Box sx={{ width: "98%" }}>
//           {loading && <div>Loading...</div>}
//           <Stepper activeStep={activeStep}>
//             {steps.map((label, index) => (
//               <Step key={label} completed={isStepSkipped(index)}>
//                 <StepLabel>{label}</StepLabel>
//               </Step>
//             ))}
//           </Stepper>
//           <Container maxWidth="md">
//             <form onSubmit={handleSubmit}>
//               {activeStep === 0 && (
//                 <>
//                   <Typography variant="h6">Personal Information</Typography>
//                   <Grid container spacing={2}>
//                     <Grid item xs={12} sm={6}>
//                       {renderTextInput("text", "first_name", "First Name", false, true)}
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       {renderTextInput("text", "last_name", "Last Name", false, true)}
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       {renderTextInput("date", "date_of_birth", "Date of Birth", false, true)}
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       <TextField
//                         select
//                         fullWidth
//                         label="Gender"
//                         name="gender"
//                         value={formData.gender || ""}
//                         onChange={handleInputChange}
//                         required
//                       >
//                         {options.map((option) => (
//                           <MenuItem key={option.value} value={option.value}>
//                             {option.label}
//                           </MenuItem>
//                         ))}
//                       </TextField>
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       {renderTextInput("text", "contact_number", "Contact Number", false, true)}
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       {renderTextInput("email", "email", "Email", false, true)}
//                     </Grid>
//                   </Grid>
//                 </>
//               )}
//               {activeStep === 1 && (
//                 <>
//                   <Typography variant="h6">Professional Information</Typography>
//                   <Grid container spacing={2}>
//                     <Grid item xs={12} sm={6}>
//                       {renderTextInput("text", "medical_license_number", "Medical License Number", false, true)}
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       <TextField
//                         select
//                         fullWidth
//                         label="Specialization"
//                         name="specialization"
//                         value={formData.specialization || ""}
//                         onChange={handleInputChange}
//                         required
//                       >
//                         {specializationMenu.map((option) => (
//                           <MenuItem key={option.value} value={option.value}>
//                             {option.label}
//                           </MenuItem>
//                         ))}
//                       </TextField>
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       {renderTextInput("text", "years_of_experience", "Years of Experience", false, true)}
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       {renderTextInput("text", "hospital_clinic_affiliation", "Hospital/Clinic Affiliation", false, true)}
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       {renderTextInput("text", "medical_school", "Medical School", false, true)}
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       {renderTextInput("text", "graduation_year", "Graduation Year", false, true)}
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       {renderTextInput("text", "residency", "Residency", false, true)}
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       {renderTextInput("text", "fellowships", "Fellowships", false, true)}
//                     </Grid>
//                   </Grid>
//                 </>
//               )}
//               {activeStep === 2 && (
//                 <>
//                   <Typography variant="h6">Upload Documents</Typography>
//                   <Grid container spacing={2}>
//                     <Grid item xs={12} sm={6}>
//                       <Button variant="outlined" component="label">
//                         Upload Photo ID
//                         <input
//                           type="file"
//                           name="photo_id"
//                           accept="image/*"
//                           hidden
//                           onChange={handleFileChange}
//                         />
//                       </Button>
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       <Button variant="outlined" component="label">
//                         Upload Medical License
//                         <input
//                           type="file"
//                           name="medical_license"
//                           accept="image/*"
//                           hidden
//                           onChange={handleFileChange}
//                         />
//                       </Button>
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       <Button variant="outlined" component="label">
//                         Upload Certifications
//                         <input
//                           type="file"
//                           name="certifications"
//                           accept="image/*"
//                           hidden
//                           onChange={handleFileChange}
//                         />
//                       </Button>
//                     </Grid>
//                   </Grid>
//                 </>
//               )}
//               {activeStep === 3 && (
//                 <>
//                   <Typography variant="h6">Account Information</Typography>
//                   <Grid container spacing={2}>
//                     <Grid item xs={12} sm={6}>
//                       {renderTextInput("password", "password", "Password", false, true)}
//                     </Grid>
//                   </Grid>
//                 </>
//               )}
//               <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
//                 {activeStep > 0 && (
//                   <Button onClick={handleBack} sx={{ mr: 1 }}>
//                     Back
//                   </Button>
//                 )}
//                 {activeStep === steps.length - 1 ? (
//                   <Button
//                     type="submit"
//                     variant="contained"
//                     color="primary"
//                     disabled={!isFormValid()}
//                   >
//                     Submit
//                   </Button>
//                 ) : (
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleNext}
//                     disabled={!isFormValid()}
//                   >
//                     Next
//                   </Button>
//                 )}
//               </Box>
//             </form>
//           </Container>
//         </Box>
//       </DialogContent>
//     </Dialog>
//   );
// }
